
import '@fortawesome/fontawesome-free/css/all.min.css';

import axios from "axios"
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import ListOffersItem from "./ListOffersItem"
import LoadingThreeDots from "./LoadingThreeDots";
import NothingFoundItem from "./NothingFoundItem"
import Pagination from "./Pagination";

const MyOffersContent = (props) => {
  const auth = useSelector(state => state.auth.data)
  
  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [listMyBid, setListMyBid] = useState([])
  const [listFilter, setListFilter] = useState([])
  const [typeSelected, setTypeSelected] = useState('0')
  const [filterSelected, setFilterSelected] = useState('1')
  const [pageMyBid, setPageMyBid] = useState(null)
  const limit = 15

  const getApi = (type, endPoint) => {
    // setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYBID') {
            setListFilter(results.option)
            setListMyBid(results.bids)
            setPageMyBid(metadata)
            setLoading(false)
            setLoadingMore(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    document.title = 'My Auctions/Bids | Paseero';
    getApi('MYBID', '/api/transaction/mybid?filter='+filterSelected+'&ads_type='+typeSelected+'&per_page='+limit+'&page='+1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, typeSelected])  

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageMyBid?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('MYBID', '/api/transaction/mybid?filter='+filterSelected+'&ads_type='+typeSelected+'&per_page='+limit+'&page='+page)
  }
  
  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className="text-xl lg:text-4xl font-semibold">My Auctions/Bids</div>
      </div>
      {isLoading? (
        <div className=" flex justify-center mt-8">
          <LoadingThreeDots color={"#B38C32"} />
        </div>
      ): (
        <div className=" flex-col lg:flex-row space-y-2 lg:space-y-0 flex lg:space-x-4 items-center mt-8 w-full">          
          <div className=" flex flex-col space-y-2 w-full lg:w-auto">
            <div className=" font-medium">Filter by Ad Type</div>
            <select 
              className=" p-2 w-full lg:w-60 bg-gray-50 border rounded focus:outline-none"
              value={typeSelected}
              onChange={(e) => setTypeSelected(e.target.value)}
            >
              {/* <option value={''}>All</option> */}
              <option value={'0'}>My Bid (Purchases)</option>
              <option value={'1'}>My Auction (Sales)</option>
                
            </select>
          </div>
          <div className=" flex flex-col space-y-1 w-full lg:w-auto">
            <div className=" font-medium">Filter by Ad Status</div>
            <select 
              className=" p-2 w-full lg:w-60 bg-gray-50 border rounded focus:outline-none"
              value={filterSelected}
              onChange={(e) => setFilterSelected(e.target.value)}
            >
              {listFilter?.map((row, index) => (
                <option 
                  key={index} 
                  value={row.value}
                >
                  {row.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}      

      <div className=" flex flex-col space-y-4 mt-4 lg:pr-8">
        {!isLoading && listMyBid?.length === 0 ? (
          <NothingFoundItem />
        ): null}
        
        {!isLoading && listMyBid?.map(row => (
          <ListOffersItem
            {...row}
            {...props}
            key={row.id}
            onPayNow={() => props.onPayNow(row)}
          />
        ))}
      </div>

      <Pagination
        isLoading={isLoadingMore}
        listCount={listMyBid?.length}
        page={pageMyBid}
        onClick={handlePagination}
      />

    </div>
  )
}
export default MyOffersContent