import React from 'react'
import {
  LiaTimesCircleSolid,
 } from 'react-icons/lia'

const ModalPopup = (props) => {
  const {data, onShowModal, onLogin} = props
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[400px] md:w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex relative ">
            <LiaTimesCircleSolid 
              className=" absolute text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <img 
              alt="banner" 
              src={data?.image}
              onClick={() => {
                if (data?.action === 'sign_up') {
                  onShowModal(false)
                  onLogin()
                }else{
                  window.location.replace(data?.url)
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalPopup