import React from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import LoadingThreeDots from "./LoadingThreeDots"

const Pagination = (props) => {
  const {
    isLoading,
    listCount,
    page,
    onClick
  } = props

  const ButtonPage = (val) => {
    return (
      <button
        className=" p-1 px-2 border rounded text-xs hover:bg-gray-100 duration-300"
        onClick={() => onClick(val)}
      >
        {val}
      </button>
    )
  }
  
  return (
    <>
      {isLoading ? (
        <div className=" flex justify-center items-center text-center space-x-2 mt-4">
          <LoadingThreeDots
            size={'20'}
            color={"#B38C32"}
          />
        </div>
      ): null}
      {listCount > 0 ? (
        <div className=" flex items-center justify-center mt-4 space-x-1">
          <button 
            className={` flex items-center p-1 pr-2 border rounded hover:bg-gray-100 duration-300 ${parseInt(page?.selectedPage) === 1 ? 'cursor-not-allowed' : 'cursor-pointer'} `}
            onClick={() => onClick('prev')}
            disabled={parseInt(page?.selectedPage) === 1 ? true : false}
          >
            <BiChevronLeft />
            <span className=" text-xs">Prev</span>                
          </button>
          
          {/* <select 
            className=" text-sm border p-1 rounded mx-4 focus:outline-none"
            value={parseInt(page?.selectedPage)}
            onChange={(e) => onClick(e.target.value)}
          >
            {optionsPagePurchase}
          </select> */}   

          {parseInt(page?.selectedPage) < (parseInt(page?.totalPage)-1) ? (
            <>
              {ButtonPage(parseInt(page?.selectedPage)+1)}
              {parseInt(page?.selectedPage) < (parseInt(page?.totalPage)-2) ? (
                <>                                                
                  {ButtonPage(parseInt(page?.selectedPage)+2)}
                  {parseInt(page?.selectedPage) < (parseInt(page?.totalPage)-3) ? ( 
                    ButtonPage(parseInt(page?.selectedPage)+3)
                  ): null}
                </>
              ): null}
            </>
          ): (
            <button 
              className=" p-1 px-2 border rounded text-xs hover:bg-gray-100 duration-300"
              onClick={() => onClick(1)}
            >
              {1}
            </button>
          )}

          {parseInt(page?.selectedPage) !== parseInt(page?.totalPage) ? (
            <span>...</span>
          ): null}

          {parseInt(page?.selectedPage) < parseInt(page?.totalPage) ? (
            <button 
              className={` p-1 px-2 border rounded text-xs hover:bg-gray-100 duration-300 `}
              onClick={() => onClick(parseInt(page?.totalPage))}
            >
              {parseInt(page?.totalPage)}
            </button>
          ): null}

          <button 
            className={` flex items-center p-1 pl-2 border rounded hover:bg-gray-100 duration-300 ${parseInt(page?.selectedPage) === parseInt(page?.totalPage) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => onClick('next')}
            disabled={parseInt(page?.selectedPage) === parseInt(page?.totalPage) ? true : false}
          >
            <span className=" text-xs">Next</span>                
            <BiChevronRight />
          </button>                
        </div>
      ): null}
    </>
  )
}

export default Pagination