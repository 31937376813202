
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import axios from "axios"
import Swal from "sweetalert2"
import Pagination from "./Pagination"
import LoadingThreeDots from "./LoadingThreeDots"


const BalanceInvoiceContent = (props) => {
  const {
    onShowModalBC,
    onShowModalBank
  } = props
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingPageBalance, setLoadingPageBalance] = useState(false)
  const [isLoadingPageCashout, setLoadingPageCashout] = useState(false)
  const [isLoadingReq, setLoadingReq] = useState(false)
  const [myBalance, setMyBalance] = useState(null)
  const [showWithDraw, setShowWithDraw] = useState(false)
  const [msgWithDraw, setMsgWithDraw] = useState('')
  const [tabBalance, setTabBalance] = useState(true)
  const [listCashout, setListCashout] = useState([])
  const [pageBalance, setPageBalance] = useState(null)
  const [pageCashout, setPageCashout] = useState(null)
  const [minCashout, setMinCashout] = useState(null)
  const [tfFee, setTfFee] = useState(0)
  const [fAmount, setFAmount] = useState(0)
  const limit = 15

  const [state, setState] = useState({
    paymentMethod: '1',
    amount: 0,
    paymentDetails: ''
  })
  
  useEffect(() => {
    document.title = 'Balance & Invoices | Paseero';

    const fetchAll = () => {
      Promise.all([
        getApi('MYBALANCE', '/api/user/my_balance?per_page='+limit),
        getApi('CASHOUT', '/api/user/cashout_request?per_page='+limit),
        getApi('SETTING', '/api/master/setting'),
      ])
      .then(() => {
        setTimeout(() => {
          setLoading(false);          
        }, 1000);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYBALANCE') {
            setMyBalance(results)
            setPageBalance(metadata)
            setLoadingPageBalance(false)
          }else if(type === 'CASHOUT'){
            setListCashout(results)
            setPageCashout(metadata)
            setLoadingPageCashout(false)
          }else{
            setMinCashout(results?.minimum_cashout)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setState(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })

    if (name === 'amount') {
      let tf_fee = 0
      if (value < 5000) {
        tf_fee = 10;
      } else if (value < 50000) {
          tf_fee = 25;
      } else {
          tf_fee = 50;
      }
      let final_amount = value - tf_fee;
      setTfFee(tf_fee)
      setFAmount(final_amount)
    }
  }

  const handleCashout = () => {
    if (state.amount === 0) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Amount Required!',
        confirmButtonColor: '#B38C32'
      })
    }
    setLoadingReq(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/user/withdraw';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      amount: state?.amount,
      payment_type: state?.paymentMethod,
      note: state?.paymentDetails,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        console.log({response});
        if (response.status === 200) {
          const { messages } = response.data.response
          setMsgWithDraw(messages)
          setTimeout(() => {
            setLoadingReq(false)      
          }, 2000);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Cashout request failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setTimeout(() => {
          setLoadingReq(false)      
        }, 2000);
      });
  }

  const handlePagination = (type) => {
    if (tabBalance) {
      setLoadingPageBalance(true)
      let page = parseInt(pageBalance?.selectedPage)
      if (type === 'prev') {
        page = (page-1)
      }else if (type === 'next') {
        page = (page+1)
      }else{
        page = (type)
      }

      getApi('MYBALANCE', '/api/user/my_balance?per_page='+limit+'&page='+page)
    } else {
      setLoadingPageCashout(true)
      let page = parseInt(pageCashout?.selectedPage)
      if (type === 'prev') {
        page = (page-1)
      }else if (type === 'next') {
        page = (page+1)
      }else{
        page = (type)
      }
      getApi('CASHOUT', '/api/user/cashout_request?per_page='+limit+'&page='+page)
    }    
  }
  
  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">Balance & Invoices</div>
        {/* <div className=" mt-2 text-sm lg:text-lg">{myBalance?.message}</div> */}
        <div className=" mt-2 text-sm lg:text-lg">{`Your account balance is ₦${user?.credit}.`}</div>
      </div>

      <div className="flex mt-4 lg:mt-8 flex-row space-x-4">
        <div 
          className=" p-2 px-4 text-xs lg:text-base rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
          onClick={() =>  onShowModalBC(true)}
        >
          Buy Credit
        </div>
      </div>

      <div className=" items-center grid grid-cols-2 mt-4 lg:mt-8">
        <div 
          className={`lg:text-xl font-semibold text-center p-3 border-b-2 cursor-pointer duration-300 transition-all ${tabBalance ? 'border-black ' : ''}`} 
          onClick={() => setTabBalance(true)}
        >
          Balance & Invoices
        </div>
        <div 
          className={`lg:text-xl font-semibold text-center p-3 border-b-2 cursor-pointer duration-300 transition-all ${!tabBalance ? 'border-black ' : ''}`}
          onClick={() => setTabBalance(false)}
        >
          Cashout Request
        </div>
      </div>

      {tabBalance ? (
        <>
          {/* {!isLoading && myBalance?.can_cash_out === 1 ? ( */}
          <div className=" mt-4">
            {isLoading ? 
              <div className=" flex justify-center">
                <LoadingThreeDots
                  size={'50'}
                  color={"#B38C32"}
                />
              </div>
            : null }
            {!isLoading ? (
              <div className=" md:w-full overflow-x-auto">
                <table className=" text-center w-[800px]">
                  <thead>
                    <tr className=" bg-gray-100 py-4 border rounded-t text-gray-500">
                      <th className=" text-left p-2">Order ID</th>
                      <th className=" p-2">Date</th>
                      <th className=" p-2">Amount</th>
                      <th className=" p-2">Status</th>
                      <th className=" p-2">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myBalance?.data?.map(row => (
                      <tr className=" border" key={row.id}>
                        <td className=" text-left p-4 text-blue-500 text-sm font-semibold hover:underline">
                          <a 
                            href={row?.invoice_url} 
                            target="_blank" 
                            rel="noreferrer"
                          >
                            {row?.order_id}
                          </a>
                        </td>
                        <td className=" p-4 text-gray-500 font-light text-sm">{row.created_at_text}</td>
                        <td className=" p-4 font-semibold">{row?.credit_text}</td>
                        <td className=" p-4 flex justify-center">
                          <div className={` ${row?.is_paid === 1 ? 'bg-[#BCEFDA]' : 'bg-gray-300'}  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.status_text}</div>
                        </td>
                        <td className=" p-4 ">
                          <a 
                            href={row?.invoice_url} 
                            target="_blank" 
                            rel="noreferrer"  
                            className=" rounded border text-sm p-1 px-2"
                          >
                            Invoice
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  isLoading={isLoadingPageBalance}
                  listCount={myBalance?.data?.length}
                  page={pageBalance}
                  onClick={handlePagination}
                />
              </div>
            ): null}        
          </div>    
        </>
      ): (
        <>
          <div className="flex mt-4 flex-row space-x-4">
            {!isLoading && myBalance?.can_cash_out === 1 && user?.bank !== null ? (
              <div 
                // className=" p-2 px-4 text-xs lg:text-base border rounded focus:outline-none hover:bg-gray-300 duration-300 cursor-pointer hover:text-white"
                className=" p-2 px-4 text-xs lg:text-base rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                onClick={() => {
                  if (user?.check_bank_information === 1) {
                    onShowModalBank()
                  }else{
                    setShowWithDraw(!showWithDraw)
                  }
                }}
              >
                Withdraw Funds
              </div>
            ): null}
          </div>
          {!isLoading && myBalance?.can_cash_out === 1 ? 
            showWithDraw && (
              <div className=" p-4 rounded border mt-4">            
                <div className=" lg:text-xl font-semibold">Withdraw Funds</div>
                {msgWithDraw ? (
                  <div className=" p-4 bg-[#D4EDD9] rounded mt-2">
                    <div className=" text-[#155724] lg:text-lg font-semibold">Request Sent</div>
                    <div className=" text-[#155724] text-sm lg:text-base font-light">{msgWithDraw}</div>
                  </div>
                ): (
                  <div>
                    <div className=" text-sm lg:text-lg font-light mt-2">
                      Please complete the form below. We will process your request immediately. <br/>{`Minimum cashout is ₦${minCashout} `}
                    </div>
                    <div className=" grid lg:grid-cols-2 gap-8 mt-8">
                      {/* <div className=" flex flex-col space-y-2">
                        <label className=" font-medium">Payment Method</label>
                        <select 
                          className=" w-full p-3 px-4 bg-gray-50 border rounded focus:outline-none" 
                          name="paymentMethod"
                          value={state.paymentMethod}
                          onChange={handleChange}
                        >
                          <option value={0}>Option 1</option>
                          <option value={1}>Option 2</option>
                        </select>
                      </div> */}
                    
                      <div className=" flex flex-col space-y-2">
                        <label className=" font-medium">Amount</label>
                        <div className=" flex items-center">
                          <div className=" p-3 w-12 text-sm lg:text-base text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                          <input 
                            type={'number'} 
                            min={0}
                            className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                            name="amount"
                            value={state.amount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className=" flex flex-col space-y-2 mt-4">
                      <label className=" font-medium">Payment Details</label>
                      <div className=" flex items-center">
                        <textarea 
                          className=" w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                          rows={5}
                          name="paymentDetails"
                          value={state?.paymentDetails}
                          onChange={handleChange} 
                        />
                      </div>
                    </div> */}
                    <div className=" text-sm lg:text-lg font-light mt-8">
                      Yow will be charged a bank transfer fee of <span className=" font-semibold text-red-500">{`₦${tfFee}`}</span>. You will receive <span className=" font-semibold text-green-500">{`₦${fAmount < 0 ? 0 : fAmount}`}</span>.
                    </div>
                    {state?.amount >= parseInt(minCashout) ?
                      <div className=" flex mt-4">
                        <button 
                          className={` text-xs lg:text-base p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 ${isLoadingReq ? 'cursor-progress' : 'cursor-pointer'} `}
                          onClick={() => {
                            // if (user?.bank === null) {
                            //   props.onShowModalBank()
                            // }else{
                              handleCashout()
                            // }
                          }}
                          disabled={isLoadingReq}
                        >
                          Request Cashout
                        </button>  
                      </div>
                    : null }
                  </div>
                )}
              </div>          
          ):null } 
          
          {!isLoading && myBalance?.can_cash_out === 0 ? (
            <div className=" p-4 bg-[#D4EDD9] rounded mt-8">
              <div className=" text-[#155724] text-lg font-semibold">Request Sent</div>
              <div className=" text-[#155724] font-light">{myBalance?.cashout_message}</div>
            </div>
          ): null}

          <div className=" md:w-full overflow-x-auto">            
            <table className=" text-center mt-4 w-[800px]">
              <thead>
                <tr className=" bg-gray-100 py-4 border rounded-t text-gray-500">
                  <th className=" p-2 text-left">Date</th>
                  <th className=" p-2">Amount</th>
                  <th className=" p-2">Cashout</th>
                  <th className=" p-2">Payment</th>
                  <th className=" p-2">Transaction</th>
                </tr>
              </thead>
              <tbody>
                {listCashout?.map(row => (
                  <tr className=" border" key={row.id}>
                    <td className=" p-4 text-gray-500 font-light text-sm text-left">{row.created_at_text}</td>
                    <td className=" p-4 font-semibold">{row?.cashout_amount_text}</td>
                    <td className=" p-4 ">
                      <span className={` ${row?.cashout_status_text !== 'Approved' ? 'bg-gray-400' : 'bg-green-500'} text-white  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>
                        {row?.cashout_status_text}
                      </span>
                    </td>
                    <td className=" p-4 ">
                      <span className={` ${row?.payment_status_text !== 'Paid' ? 'bg-gray-400' : 'bg-green-500'} text-white  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>
                        {row?.payment_status_text}
                      </span>
                    </td>
                    <td className=" p-4 ">
                      {row?.status_text === 'Completed' ? (
                        <a
                          target='_blank' 
                          rel="noreferrer"
                          href={row?.credit?.invoice_url}
                          className={`duration-300 bg-green-500 underline border border-green-500 text-white rounded-full text-xs font-semibold p-1 px-4 uppercase hover:bg-white hover:text-green-500`}
                        >
                          {row?.status_text}
                        </a>
                      ):(
                        <span className={` ${row?.status_text !== 'Completed' ? 'bg-gray-400' : 'bg-green-500'} text-white  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>
                          {row?.status_text}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              isLoading={isLoadingPageCashout}
              listCount={listCashout?.length}
              page={pageCashout}
              onClick={handlePagination}
            />
          </div>
        </>
      )}
          
    </div>
  )
}

export default BalanceInvoiceContent