import React from 'react'
import Select from 'react-select';
import { HiChevronRight, HiCheckCircle } from 'react-icons/hi'
import { LiaTimesCircleSolid } from "react-icons/lia"
// import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
 
const ModalFilter = (props) => {
  const {
    state,
    content,
    onChange,
    onChangePrice,
    onCategorySelect,
    onSubCategorySelect,
    onChangeSearch,
    onInputChange,
    isLoading,
    // onSetRange,
    onCancel,
    onContinue,
    isActive,
  } = props
  return (
    <div
      onClick={onCancel}
      className={` ${isActive ? 'flex' : 'hidden'} top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onCancel(false)}
            />
            <div className=" p-4 text-center lg:text-xl font-semibold border-b capitalize ">{content?.title}</div>
            {content?.isType === 'category' ? (
               <div className=" m-4 border grid grid-cols-2">
                <div className=" h-80 overflow-auto">
                  <div
                    className=" flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                    onClick={() => onCategorySelect('', 'ALL')}
                  >
                    <div className=" text-sm lg:text-base font-medium">{'ALL'}</div>
                    {state?.category === '' ? (
                      <HiCheckCircle className=" text-2xl text-[#366C38]" />
                    ):(
                      <HiChevronRight className=" text-2xl" />
                    )}
                  </div>
                  {content?.list?.map((row) => (
                    <div 
                      key={row.id} 
                      className=" text-sm lg:text-base flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                      onClick={() => onCategorySelect(row?.id, row?.title)}
                    >
                      <div className=" font-medium">{row.title}</div>
                      {state?.category === row.id ? (
                        <HiCheckCircle className=" text-2xl text-[#366C38]" />
                      ):(
                        <HiChevronRight className=" text-2xl" />
                      )}
                    </div>
                  ))}
                </div>
                <div className=" h-80 overflow-auto">
                  <div
                    className=" flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                    onClick={() => onSubCategorySelect('', 'ALL')}
                  >
                    <div className=" text-sm lg:text-base font-medium">{'ALL'}</div>
                    {state?.subCategory === '' ? (
                      <HiCheckCircle className=" text-2xl text-[#366C38]" />
                    ):(
                      <HiChevronRight className=" text-2xl" />
                    )}
                  </div>
                  {state?.listSubCategory?.map((row) => (
                    <div 
                      key={row.id} 
                      className=" text-sm lg:text-base flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                      onClick={() => onSubCategorySelect(row?.id, row?.title)}
                    >
                      <div className=" font-medium">{row.title}</div>
                      {state?.subCategory === row.id ? (
                        <HiCheckCircle className=" text-2xl text-[#366C38]" />
                      ):(
                        <HiChevronRight className=" text-2xl" />
                      )}
                    </div>
                  ))}
                </div>
               </div>
            ): content?.isType === 'price' ? (
              <div className=" m-4 lg:m-8 space-y-8">
                <div className=" flex items-center lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-8">
                  <div className=" flex flex-col space-y-2 w-full lg:w-1/2">
                    <label className=" font-medium">Min Price</label>
                    <div className=" flex items-center">
                      <div className=" p-2 text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                      <input 
                        type={'number'} 
                        min={0}
                        className=" w-full p-2 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                        name="minPrice"
                        value={state.range[0]}
                        onChange={onChangePrice}
                      />
                    </div>
                  </div>
                  <div className=" flex flex-col space-y-2 w-full lg:w-1/2">
                    <label className=" font-medium">Max Price</label>
                    <div className=" flex items-center">
                      <div className=" p-2 text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                      <input 
                        type={'number'} 
                        min={0}
                        className=" w-full p-2 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                        name="maxPrice"
                        value={state.range[1]}
                        onChange={onChangePrice}
                      />
                    </div>
                  </div>
                </div>
                {/* <RangeSlider 
                  min={0}
                  max={10000}
                  value={state.range} 
                  onInput={onSetRange} 
                /> */}
              </div>
            ): content?.isType === 'search' ? (
              <div className=" p-4">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={state?.brand}
                  isDisabled={false}
                  isLoading={isLoading}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="brand"
                  // options={content?.list}
                  options={state?.listBrand}
                  placeholder="Search.."
                  onChange={(e) => onChangeSearch(e)}
                  onInputChange={onInputChange}
                />   
              </div>         
            ): (
              <div className=" flex space-y-3 flex-col m-4 mx-8 max-h-80 overflow-y-auto">
                <div className=" rounded flex flex-row items-start justify-between" >
                  <div className=" flex items-start">
                    <div className=" flex items-start">
                      <input 
                        type={'radio'}
                        className="  mt-2 cursor-pointer" 
                        id={`radio-filter-all`}
                        name={content?.title?.replace(/ /g, "")}
                        value={''}
                        checked={state[content?.title?.replace(/ /g, "")] === '' ? true : false}
                        // onChange={onChange}
                        onChange={(e) => onChange(e, '')}
                      />
                      <div className=" ml-4">
                        <label 
                          className=" lg:text-lg font-medium cursor-pointer" 
                          htmlFor={`radio-filter-all`}
                        >
                          {'ALL'}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {content?.list.map((row) => (
                  <div
                    key={row?.id}
                    className=" rounded flex flex-row items-start justify-between"
                  >
                    <div className=" flex items-start">
                      <div className=" flex items-start">
                        <input 
                          type={'radio'}
                          className="  mt-2 cursor-pointer" 
                          id={`radio-filter-${row?.id}`}
                          name={content?.title?.replace(/ /g, "")}
                          value={row?.id}
                          checked={parseInt(state[content?.title?.replace(/ /g, "")]) === row?.id ? true : false}
                          // onChange={onChange}
                          onChange={(e) => onChange(e, row?.title)}
                        />
                        <div className=" ml-4">
                          <label 
                            className=" lg:text-lg font-medium cursor-pointer" 
                            htmlFor={`radio-filter-${row.id}`}
                          >
                            {row?.title}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> 
            )}
                     
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={onCancel} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none"
              >
                Cancel
              </button>
              <button 
                onClick={onContinue} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                Continue
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalFilter