import axios from "axios";
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListNotifItem from "./ListNotifItem";
import LoadingThreeDots from "./LoadingThreeDots";
 
const ModalNotif = (props) => {
  const {
    onShowModal
  } = props

  const navigate = useNavigate()

  const auth = useSelector(state => state.auth.data)
  const [listNotif, setListNotif] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (listNotif.length === 0) {
      getApi('NOTIF', '/api/auth/notif')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results } = response.data.response
          if (type === 'NOTIF') {
            setListNotif(results)
            setLoading(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleDeleteNotif = (id) => {
    let arr = [...listNotif]
    let index = arr.map(x => {return x.id; }).indexOf(id);
    arr.splice(index, 1);
    setListNotif(arr)
    getApi('DELNOTIF', '/api/auth/notif-delete?id='+id)
  }

  const handleViewNotif = (id, flag) => {
    getApi('VIEWNOTIF', '/api/auth/notif-view?id='+id)

    setTimeout(() => {
      if (flag === 'ads') {
        navigate('/my-account?tabs=listings')
      }else if (flag === 'balance') {
        navigate('/my-account?tabs=orders')
      }else if (flag === 'membership') {
        navigate('/my-account?tabs=membership')
      } 
    }, 100);
  }
  
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <div className=" p-4 text-center text-xl font-semibold border-b ">My Notification List</div>
            <div className=" m-4 h-96 space-y-3 overflow-y-auto">
              {isLoading ? (          
                <div className=" flex justify-center">
                  <LoadingThreeDots color={"#B38C32"} />
                </div>
              ): null}
              {!isLoading && listNotif?.map((row) => (
                <ListNotifItem
                  {...row}
                  key={row.id}
                  onView={() => handleViewNotif(row?.id, row?.flag)}
                  onDelete={() => handleDeleteNotif(row.id)}
                />
              ))} 

            </div>            
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={() => onShowModal(false)} 
                className=" text-sm p-2 px-4 w-24 flex justify-center border rounded focus:outline-none hover:bg-gray-300 duration-300 cursor-pointer hover:text-white"
              >
                Close
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalNotif