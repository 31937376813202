import axios from "axios";
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { 
  BiSolidDownArrow,
} from 'react-icons/bi' 
import { Tooltip as ReactTooltip } from "react-tooltip";
import Pagination from "./Pagination";
import LoadingThreeDots from "./LoadingThreeDots";

const TransactionsContent = (props) => {
  const { 
    handleDelivered, 
    handleReview, 
    handleDispute, 
    setShowModalAccSales, 
    setShowModalReview,
    setShowModalDispute,
    setMsgUser,
    setShowModalMsg,
    flagDispute,
    onShowModalBank,
    mySalesUpdate,
    setMySalesUpdate,
  } = props
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingPagePurchase, setLoadinPagePurchase] = useState(false)
  const [isLoadingPageSales, setLoadinPageSales] = useState(false)
  const [tabPurchase, setTabPurchase] = useState(true)
  const [listPurchase, setListPurchase] = useState([])
  const [listSales, setListSales] = useState([])
  const [showAction, setShowAction] = useState(null)
  const [pagePurchase, setPagePurchase] = useState(null)
  const [pageSales, setPageSales] = useState(null)
  const limit = 15

  useEffect(() => {
    document.title = 'Transactions | Paseero';

    const fetchAll = () => {
      Promise.all([
        getApi('MYPURCHASE', '/api/transaction/my_purchase?per_page='+limit),
        getApi('MYSALES', '/api/transaction/my_sales?per_page='+limit),
      ])
      .then(() => {
        setTimeout(() => {
          setLoading(false);          
        }, 1000);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    
    fetchAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYPURCHASE') {
            setListPurchase(results)
            setPagePurchase(metadata)
            setLoadinPagePurchase(false)
          }else{
            setListSales(results)
            setPageSales(metadata)
            setLoadinPageSales(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleAccept = (id, index) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/transaction/my_purchase';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      id: id,
      item_receipt_status: 1, // Received
      // buyer_note: ''
    };
    if (index !== -1) {   
      Swal.fire({
        title: "I Confirm Item Receipt?",
        text: 'Confirmation means you have authorized Paseero to pay the seller.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I Confirm',
        confirmButtonColor: '#B38C32',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(apiUrl, bodyParams, { headers })
            .then(response => {
              console.log({response});
              if (response.status === 200) {
                const { results } = response.data.response
                Swal.fire({
                  icon: 'success',
                  title: 'Accepted!',
                  text: 'Your transaction has been updated.',
                  confirmButtonColor: '#B38C32'
                }).then((result) => {
                  if (result.isConfirmed) {
                    // window.location.reload()

                    let list = [...listPurchase]
                    list[index] = {
                      ...list[index] = {
                        ...list[index],
                        // item_receipt_status_text: 'Received',
                        ...results,
                      }
                    }
                    setListPurchase(list)
                    setShowAction(null)
                  }
                })
              }else{
                // Do something else
              } 
            })
            .catch(error => {
              console.error('Error making POST request:', error);
              const { messages } = error.response.data.response
              Swal.fire({
                icon: 'error',
                title: 'Confirmation Failed',
                text: messages,
                confirmButtonColor: '#DC2625'
              })
            });          
        }
      })
    }
  }

  const handleMessagePurchase = (item) => {
    setMsgUser({
      ...item,
      targeted_user_id: item?.seller?.id,
      targeted_user_photo: item?.seller?.photo,
      targeted_user_full_name: !!item?.seller?.business_name ? item?.seller?.business_name : item?.seller?.first_name+' '+item?.seller?.last_name,
    })

    setTimeout(() => {
      setShowModalMsg(true)
    }, 100);
  }

  const handleMessageSales = (item) => {
    setMsgUser({
      ...item,
      targeted_user_id: item?.buyer?.id,
      targeted_user_photo: item?.buyer?.photo,
      targeted_user_full_name: !!item?.buyer?.business_name ? item?.buyer?.business_name : item?.buyer?.first_name+' '+item?.buyer?.last_name,
    })

    setTimeout(() => {
      setShowModalMsg(true)
    }, 100);
  }

  useEffect(() => {
    if (flagDispute !== null) {
      let foundIndex = listPurchase.findIndex(x => x.id === flagDispute);

      let list = [...listPurchase]
      list[foundIndex] = {
        ...list[foundIndex] = {
          ...list[foundIndex],
          transaction_status_text: 'Dispute',
        }
      }
      setListPurchase(list)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagDispute])

  useEffect(() => {
    if (!!mySalesUpdate.data) {
      let list = [...listSales]
      list[mySalesUpdate.index] = {
        ...list[mySalesUpdate.index] = {
          ...list[mySalesUpdate.index],
          ...mySalesUpdate.data,
        }
      }
      setListSales(list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySalesUpdate])
  
  
  const handlePagination = (type) => {
    if (tabPurchase) {
      setLoadinPagePurchase(true)
      let page = parseInt(pagePurchase?.selectedPage) 
      if (type === 'prev') {
        page = (page-1)
      }else if (type === 'next') {
        page = (page+1)
      }else{
        page = (type)
      }

      getApi('MYPURCHASE', '/api/transaction/my_purchase?per_page='+limit+'&page='+page)
    } else {
      setLoadinPageSales(true)
      let page = parseInt(pageSales?.selectedPage) 
      if (type === 'prev') {
        page = (page-1)
      }else if (type === 'next') {
        page = (page+1)
      }else{
        page = (type)
      }

      getApi('MYSALES', '/api/transaction/my_sales?per_page='+limit+'&page='+page)
    }    
  }
  
  let optionsPagePurchase = []
  for (let i = 0; i < pagePurchase?.totalPage; i++) {
    optionsPagePurchase.push(<option key={i} value={i+1}>{i+1}</option>);
  }

  let optionsPageSales = []
  for (let i = 0; i < pageSales?.totalPage; i++) {
    optionsPageSales.push(<option key={i} value={i+1}>{i+1}</option>);
  }

  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">Manage Orders</div>
        <div className=" mt-2 text-sm lg:text-lg">Please keep your orders up-to-date.</div>
      </div>

      <div className=" mt-4 lg:mt-8">
        <div className=" items-center grid grid-cols-2">
          <div 
            className={`lg:text-xl font-semibold text-center p-3 border-b-2 cursor-pointer duration-300 transition-all ${tabPurchase ? 'border-black ' : ''}`} 
            onClick={() => setTabPurchase(true)}
          >
            My Purchases
          </div>
          <div 
            className={`lg:text-xl font-semibold text-center p-3 border-b-2 cursor-pointer duration-300 transition-all ${!tabPurchase ? 'border-black ' : ''}`}
            onClick={() => setTabPurchase(false)}
          >
            My Sales
          </div>
        </div>
        {isLoading ? 
          <div className=" flex justify-center mt-8">
            <LoadingThreeDots
              size={'50'}
              color={"#B38C32"}
            />
          </div>
        : null }
        {!isLoading && tabPurchase ? (
          <>
            <div className=" mt-4 lg:mt-8 text-sm lg:text-lg">Please keep the item delivery status up-to-date for fraud protection, Paseero will hold on to your payment, and will only pay the seller after you have confirmend item delivery. Please see our <Link to={'/terms-conditions'} className=" text-[#B38C32] underline-offset-2 hover:underline">Terms & Conditions</Link></div>
            <div className=" md:w-full overflow-x-auto">
              <table className={` text-center mt-4 ${listPurchase.length > 0 ? 'w-[1024px]' : 'w-full'}`}>
                <thead>
                  <tr className=" bg-gray-100 py-4 border rounded-t text-gray-500">
                    <th className=" text-left p-2">Title</th>
                    <th className=" p-2">Date</th>
                    <th className=" p-2">Amount</th>
                    <th className=" p-2">Receipt</th>
                    <th className=" p-2">Delivery</th>
                    <th className=" p-2">Transaction</th>
                    <th className=" p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listPurchase?.map((row, index) => (
                    <tr className=" border" key={row.id}>
                      <td className=" text-left p-4 px-2 text-blue-500 text-sm font-semibold hover:underline w-60">
                        <a 
                          href={`${process.env.PUBLIC_URL}/listing/${row?.ad?.id}/${row?.ad?.title.replace(/ /g, "-").replace("/", "-")}` } 
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row?.ad?.title}
                        </a>
                      </td>
                      <td className=" p-4 px-2 text-gray-500 font-light text-sm w-40">{row.created_text}</td>
                      <td className=" p-4 px-2 font-semibold">{row?.credit_text}</td>
                      <td className=" p-4 px-2 w-36">
                        <span className={` ${row?.item_receipt_status_text === 'Pending' ? 'bg-gray-400' : 'bg-green-500'} text-white  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.item_receipt_status_text}</span>
                      </td>
                      <td className=" p-4 px-2 w-36">
                        <span className={` ${row?.item_delivery_status_text === 'In Transit' ? 'bg-yellow-500' : row?.item_delivery_status_text === 'Delivered' ? 'bg-green-500' : 'bg-gray-400'} text-white rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.item_delivery_status_text}</span>
                      </td>
                      <td className=" p-4 px-2">
                        {/* <span className={` ${row?.transaction_status_text === 'On Going' ? 'bg-gray-400' : 'bg-green-500'} text-white rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.transaction_status_text}</span> */}
                        {row?.transaction_status_text === 'Completed' || row?.transaction_status_text === 'Refunded' ? (
                          <>
                            <a
                              target='_blank'
                              rel="noreferrer"
                              href={row?.invoice_url}
                              data-tooltip-id="tooltip-purchase"
                              data-tooltip-content="View Invoice"
                              className={`duration-300 ${row.transaction_status_text === 'Completed' ? 'bg-green-500 border border-green-500 hover:text-green-500' : 'bg-red-600 border border-red-600 hover:text-red-600'} underline  text-white rounded-full text-xs font-semibold p-1 px-4 uppercase hover:bg-white `}
                            >
                              {row?.transaction_status_text}
                            </a>
                            <ReactTooltip
                              id="tooltip-purchase"
                              place="top"
                            />
                          </>
                          ): (
                            <span className={` ${row?.transaction_status_text === 'Pending' ? 'bg-gray-400' : row?.transaction_status_text === 'Dispute' || row?.transaction_status_text === 'Refunded' ? 'bg-red-600' : 'bg-yellow-500'} text-white rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.transaction_status_text}</span>
                          )}
                      </td>
                      <td className=" p-4 px-2 flex space-x-1">
                        {/* {row?.item_receipt_status_text === 'Pending' ? (
                          <div
                            className=" rounded border-[#B38C32] border text-white text-sm p-1 px-2 bg-[#B38C32] duration-300 cursor-pointer hover:bg-white hover:text-[#B38C32]"
                            onClick={() => handleAccept(row?.id, index)}
                          >
                            Accept
                          </div>
                        ): null}
                        {row?.transaction_status_text === 'On Going' ? (
                          <div
                            className=" rounded border-red-500 border text-white text-sm p-1 px-2 bg-red-500 duration-300 cursor-pointer hover:bg-white hover:text-red-500"
                            onClick={() => {
                              let e = { target: {
                                name: 'id',
                                value: row?.id
                              }}
                              handleDispute(e)
                              setTimeout(() => {
                                setShowModalDispute(true)
                              }, 100);
                            }}
                          >
                            Dispute
                          </div>
                        ): null}
                        {row?.transaction_status_text === 'Completed' ? (                       
                          <div
                            className=" rounded border-blue-500 border text-white text-sm p-1 px-2 bg-blue-500 duration-300 cursor-pointer hover:bg-white hover:text-blue-500"
                            onClick={() => {
                              handleReview(row)
                              setTimeout(() => {
                                setShowModalReview(true)
                              }, 100);
                            }}
                          >
                            Review
                          </div>
                        ): null} */}

                        <div className=" relative">
                          <div
                            className=" flex items-center p-2 border w-24 cursor-pointer "
                            onClick={() => setShowAction(showAction === row?.id? null : row?.id)}
                          >
                            <div className=" text-sm mr-4">Action</div>
                            <BiSolidDownArrow className=" text-xs" />
                          </div>
                          {showAction === row.id ? (                        
                            <div className=" absolute flex-col bg-white w-24 z-10">
                              {row?.item_receipt_status_text === 'Pending' && row?.transaction_status_text !== 'Dispute' ? (
                                <div
                                  className="  p-2 bg-[#B38C32] text-white text-sm  cursor-pointer"
                                  onClick={() => handleAccept(row?.id, index)}
                                >
                                  Accept
                                </div>
                              ):null }
                              {row?.transaction_status_text === 'Completed' ? (  
                                <div
                                    className="  p-2  bg-blue-500 text-white text-sm cursor-pointer"
                                    onClick={() => {
                                      handleReview(row)
                                      setTimeout(() => {
                                        setShowModalReview(true)
                                      }, 100);
                                    }}
                                  >
                                    Review
                                </div>
                              ): null}
                              {row?.transaction_status_text === 'On Going' && row?.item_receipt_status_text === 'Pending' ? (
                                <div 
                                  className="  p-2  bg-red-500 text-white text-sm cursor-pointer"
                                  onClick={() => {
                                    if (user?.check_bank_information === 1) {
                                      onShowModalBank()
                                    }else{
                                      let e = { target: {
                                        name: 'id',
                                        value: row?.id
                                      }}
                                      handleDispute(e)
                                      setTimeout(() => {
                                        setShowModalDispute(true)
                                        setShowAction(null)
                                      }, 100);
                                    }
                                  }}
                                >
                                  Dispute
                                </div>
                              ): null}
                              <div 
                                className="  p-2  bg-yellow-500 text-white text-sm cursor-pointer"
                                onClick={() => handleMessagePurchase(row)}
                              >
                                Message
                              </div>
                            </div>
                          ): null}
                        </div>
                      </td>
                    </tr>
                  ))}                  
                  {listPurchase.length === 0 ? (
                    <tr className=" border">
                      <td 
                        colSpan={7}
                        className=" p-2"
                      >
                        No Data
                      </td>
                    </tr>
                  ): null}
                  
                  {/* <div
                    className=" rounded border-blue-500 border text-white text-sm p-1 px-2 bg-blue-500 duration-300 cursor-pointer hover:bg-white hover:text-[#B38C32]"
                    // onClick={() => handleAccept(row?.id, index)}
                    >
                    Review
                  </div> */}
                </tbody>
              </table>            
            </div>
            <Pagination
              isLoading={isLoadingPagePurchase}
              listCount={listPurchase.length}
              page={pagePurchase}
              onClick={handlePagination}
            />
          </>
        ): null}

        {!isLoading && !tabPurchase ? (
          <>
            <div className=" mt-4 lg:mt-8 text-sm lg:text-lg">For fraud protection, Paseero will hold your sales money until the buyer confirm item delivery. We guarantee your payment in under 24hours after item delivery confirmation. Please see our <Link to={'/terms-conditions'} className=" text-[#B38C32] underline-offset-2 hover:underline">Terms & Conditions</Link></div>
            <div className=" md:w-full overflow-x-auto">
              <table className={` text-center mt-4 ${listSales.length > 0 ? 'w-[800px]' : 'w-full'} `}>
                <thead>
                  <tr className=" bg-gray-100 py-4 border rounded-t text-gray-500">
                    <th className=" text-left p-2">Title</th>
                    <th className=" p-2">Date</th>
                    <th className=" p-2">Amount</th>
                    <th className=" p-2">Receipt</th>
                    <th className=" p-2">Delivery</th>
                    <th className=" p-2">Transaction</th>
                    <th className=" p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listSales?.map((row, index) => (
                    <tr className=" border" key={row.id}>
                      <td className=" text-left p-4 px-2 text-blue-500 text-sm font-semibold hover:underline">
                        <a 
                          href={`${process.env.PUBLIC_URL}/listing/${row?.ad?.id}/${row?.ad?.title.replace(/ /g, "-").replace("/", "-")}` } 
                          target="_blank" 
                          rel="noreferrer"
                        >
                          {row?.ad?.title}
                        </a>
                      </td>
                      <td className=" p-4 px-2 text-gray-500 font-light text-sm">{row.created_text}</td>
                      <td className=" p-4 px-2 font-semibold">{row?.credit_text}</td>
                      <td className=" p-4 px-2 w-36">
                        <span className={` ${row?.item_receipt_status_text === 'Pending' ? 'bg-gray-400' : 'bg-green-500'} text-white  rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.item_receipt_status_text}</span>
                      </td>
                      <td className=" p-4 px-2 w-36">
                        <span className={` ${row?.item_delivery_status_text === 'In Transit' ? 'bg-yellow-500' : row?.item_delivery_status_text === 'Delivered' ? 'bg-green-500' : 'bg-gray-400'} text-white rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.item_delivery_status_text}</span>
                      </td>
                      <td className=" p-4 px-2">
                        {row?.transaction_status_text === 'Completed' ? (
                          <>
                            <a
                              target='_blank' 
                              rel="noreferrer"
                              href={row?.invoice_url}
                              data-tooltip-id="tooltip-sales"
                              data-tooltip-content="View Invoice"
                              className={`duration-300 bg-green-500 border border-green-500 hover:text-green-500 underline  text-white rounded-full text-xs font-semibold p-1 px-4 uppercase hover:bg-white `}
                            >
                              {row?.transaction_status_text}
                            </a>                            
                            <ReactTooltip
                              id="tooltip-sales"
                              place="top"
                            />
                          </>
                        ): (
                          <span className={` ${row?.transaction_status_text === 'Pending' ? 'bg-gray-400' : row?.transaction_status_text === 'Refunded' ? 'bg-red-600' : 'bg-yellow-500'} text-white rounded-full text-xs font-semibold p-1 px-4 uppercase`}>{row?.transaction_status_text}</span>
                        )}                      
                      </td>
                      <td className=" p-4 px-2">
                        {/* {row?.item_delivery_status_text !== 'Delivered' ? (                    
                          <div
                            className=" rounded border-[#B38C32] border text-white text-sm p-1 px-2 bg-[#B38C32] duration-300 cursor-pointer hover:bg-white hover:text-[#B38C32]"
                            onClick={() => {
                              let e = { target: {
                                name: 'id',
                                value: row?.id
                              }}
                              handleDelivered(e)
                              setTimeout(() => {
                                setShowModalAccSales(true)
                              }, 100);
                            }}
                          >
                            Update
                          </div>
                        ): null} */}
                        <div className=" relative">
                        <div 
                          className=" flex items-center p-2 border w-24 cursor-pointer "
                          onClick={() => setShowAction(showAction === row?.id? null : row?.id)}
                        >
                          <div className=" text-sm mr-4">Action</div>
                          <BiSolidDownArrow className=" text-xs" />
                        </div>
                        {showAction === row.id ? (                        
                          <div className=" absolute flex-col bg-white w-24 z-10">
                            {row?.item_delivery_status_text !== 'Delivered' && (row?.transaction_status_text === 'Pending' || row?.transaction_status_text === 'On Going') ? (
                              <div 
                                className="  p-2 bg-[#B38C32] text-white text-sm  cursor-pointer"
                                // onClick={() => handleAccept(row?.id, index)}
                                onClick={() => {
                                  let e = { target: {
                                    name: 'id',
                                    value: row?.id
                                  }}
                                  handleDelivered(e)
                                  setTimeout(() => {
                                    setMySalesUpdate(prevState => {
                                      return {
                                        ...prevState,
                                        index: index
                                      }
                                    })
                                    setShowModalAccSales(true)
                                  }, 100);
                                }}
                              >
                                Update
                              </div>
                            ):null }
                            <div 
                              className="  p-2  bg-yellow-500 text-white text-sm cursor-pointer"
                              onClick={() => handleMessageSales(row)}
                            >
                              Message
                            </div>
                          </div>
                        ): null}
                      </div>
                      </td>
                    </tr>
                  ))}
                  {listSales.length === 0 ? (
                    <tr className=" border">
                      <td 
                        colSpan={7}
                        className=" p-2"
                      >
                        No Data
                      </td>
                    </tr>
                  ): null}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoadingPageSales}
              listCount={listSales.length}
              page={pageSales}
              onClick={handlePagination}
            />
          </>
        ): null}        
      </div>    
    </div>
  )
}

export default TransactionsContent