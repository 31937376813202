import React from 'react'
import { LiaTimesCircleSolid } from "react-icons/lia"
import LoadingThreeDots from "./LoadingThreeDots"
 
const ModalAcceptSales = (props) => {
  const {
    isLoading,
    notes,
    // upload,
    onChange,
    onSubmit,
    onShowModal,
  } = props
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <div className=" p-4 text-center lg:text-xl font-semibold border-b ">I Confirm Item Delivery?</div>
            <div className=" m-4 ">
              <div className=" space-y-2">
                <label className=" font-medium">Note to Paseero</label>
                <textarea
                  className=" w-full text-xs lg:text-sm p-3 border rounded focus:outline-none"
                  placeholder="Optional"
                  name="notes"
                  value={notes}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className=" space-y-2">
                <label className=" font-medium">Upload Delivery Proof (Optional)</label>
                <input 
                  type={'file'}
                  accept=".jpg,.jpeg,.png"
                  className=" w-full p-3 text-xs lg:text-sm border rounded focus:outline-none"
                  name="upload"
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>            
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={() => onShowModal(false)}
                className=" p-2 px-4 w-28 text-sm lg:text-base flex justify-center rounded border focus:outline-none border-gray-400 hover:bg-gray-400 hover:text-white duration-300"
              >
                Cancel
              </button>
              <button 
                onClick={onSubmit} 
                className=" p-2 px-4 w-36 text-sm lg:text-base flex justify-center rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                {isLoading ? (
                  <LoadingThreeDots size={'20'} />
                ): <span>Yes, I Confirm</span>}
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalAcceptSales