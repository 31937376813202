import React from 'react'
import { HiOutlineBell } from 'react-icons/hi2'

const ListNotifItem = (props) => {
  return (
    <div 
      key={props?.id} 
      className=" flex flex-col lg:flex-row space-y-2 lg:space-y-0 justify-between items-center border rounded p-2 px-4 lg:space-x-4"
    >
      <div className=" flex flex-row items-start">
        <HiOutlineBell className=" text-xl lg:text-3xl text-[#F8BA09]" />
        <div className=" flex flex-col ml-4 w-11/12">
          <div className=" text-sm lg:text-lg font-medium line-clamp-1">{props?.title}</div>
          <div className=" font-light text-xs lg:text-sm line-clamp-1">{props?.description}</div>
          <div className=" text-xs text-gray-400">{props?.created_at_text}</div>
        </div>
      </div>
      <div className=" flex flex-row lg:flex-col items-center space-x-2 lg:space-x-0 lg:space-y-2">
        {props?.flag !== '' && props?.is_read === 0 ? (
          <div 
            className=" text-xs lg:text-sm p-2 px-4 w-24 flex justify-center text-white border rounded focus:outline bg-[#F8BA09] border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300 cursor-pointer"
            onClick={props.onView}
          >
            View
          </div>
        ): null}
        
        <div 
          className=" text-xs lg:text-sm p-2 px-4 w-24 flex justify-center border rounded focus:outline-none hover:bg-gray-300 duration-300 cursor-pointer hover:text-white"
          onClick={props.onDelete}
        >
          Delete
        </div>
      </div>
    </div>
  )
}

export default ListNotifItem