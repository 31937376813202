import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import Layout from "./Layout"

const NoPage = () => {
  useEffect(() => {
    document.title = 'Not Found | Paseero';
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>   
      <hr className=" my-12" />
      <div className=" min-h-[300px] flex flex-col justify-center">
        <div className=" px-8 lg:text-3xl font-medium text-center">Oops! The page you were looking for could not be found</div>
        <div className=" text-sm lg:text-xl font-light text-center mt-4 mx-4 lg:mx-0">Head back <Link to={'/'} className=" text-[#B38C32] hover:underline">home.</Link></div>          
        <div className=" flex justify-center mt-12">
          <Link
            to={'/market-place'}
            className=" p-2 px-4 text-sm lg:text-base rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
          >
            Search Ads
          </Link>
        </div>
      </div>
      <hr className=" my-12" />
    </Layout>
  )
}

export default NoPage