import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    clearAuth(state) {
      state.data = null;
      state.user = null;
    },
    clearUser(state) {
      state.user = null;
    },
  },
});

export const { setData, setUser, clearAuth, clearUser } = authSlice.actions;

export default authSlice.reducer;