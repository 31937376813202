import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyDZE0-rqhhQz4AOfaMnLsC0uHstVozXIlc",
  authDomain: "paseero-rn-dev.firebaseapp.com",
  databaseURL: "https://paseero-rn-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "paseero-rn-dev",
  storageBucket: "paseero-rn-dev.appspot.com",
  messagingSenderId: "1091812681274",
  appId: "1:1091812681274:web:f230c641ee7fbc3f0ef703"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Received foreground message ", payload)
      resolve(payload);
    });
  });
