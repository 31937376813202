import axios from "axios"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { setUser } from "../redux/authSlice"
import ListAdItem from "./ListAdItem"
import LoadingThreeDots from "./LoadingThreeDots"
import NothingFoundItem from "./NothingFoundItem"
import Pagination from "./Pagination"

const WishlistContent = (props) => {
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [typeSelected, setTypeSelected] = useState('')
  const [listMyWishlist, setListMyWishlist] = useState([])
  const [pageWishlist, setpageWishlist] = useState(null)
  const limit = 15

  const getApi = (type, endPoint) => {
    // setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'WISHLIST') {
            setListMyWishlist(results)
            setpageWishlist(metadata)
            setLoading(false)
            setLoadingMore(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    document.title = 'My Wishlist | Paseero';
    getApi('WISHLIST', '/api/user/wishlist/index?ads_type='+typeSelected+'&per_page='+limit+'&page='+1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeSelected]) 

  const handleFavorite = (index, id) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/user/wishlist/toogle';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
      
    if (index !== -1) {   
      Swal.fire({
        title: 'Are you sure?',
        text: "Confirm to remove item from wishlist?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        confirmButtonColor: '#B38C32',
        cancelButtonText: 'No, cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(apiUrl, bodyParams, { headers })
            .then(response => {
              // console.log({response});
              if (response.status === 200) {
                Swal.fire(
                  'Removed!',
                  'Your wishlist has been updated.',
                  'success'
                )  

                let arr = [...listMyWishlist]
                arr.splice(index, 1);
                setListMyWishlist(arr)   
                dispatch(setUser({...user, wishlist_count: user?.wishlist_count - 1}))
              }else{
                // Do something else
              } 
            })
            .catch(error => {
              console.error('Error making POST request:', error);
            });          
        }
      })
    }
  }

  const handleMessage = (item) => {
    props.setMsgUser({
      ...item,
      targeted_user_id: item?.id,
      targeted_user_photo: item?.photo,
      targeted_user_full_name: !!item?.business_name ? item?.business_name : item?.first_name+' '+item?.last_name,
    })

    setTimeout(() => {
      props.setShowModalMsg(true)
    }, 100);
  }

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageWishlist?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('WISHLIST', '/api/user/wishlist/index?ads_type='+typeSelected+'&per_page='+limit+'&page='+page)
  }

  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-2xl lg:text-4xl font-semibold">My Wishlist</div>
      </div>  

      {isLoading? (
        <div className=" flex justify-center mt-8">
          <LoadingThreeDots color={"#B38C32"} />
        </div>
      ): (
        <div className=" flex-row flex space-x-4 items-center mt-8 w-full">          
          <div className=" flex flex-col space-y-1">
            <div className=" font-medium">Filter by Ad Type</div>
            <select 
              className=" p-2 w-60 bg-gray-50 border rounded focus:outline-none"
              value={typeSelected}
              onChange={(e) => setTypeSelected(e.target.value)}
            >
              <option value={''}>All</option>
              <option value={'0'}>Classified (Buy Now)</option>
              <option value={'1'}>Auction</option>                
            </select>
          </div>
        </div>
      ) }

      {!isLoading && listMyWishlist?.length === 0 ? (
        <NothingFoundItem />
      ): null}

      <div className=" mt-8 grid grid-cols-1 lg:grid-cols-3 gap-4 gap-y-2 md:gap-y-6 pb-8">     
        {!isLoading && listMyWishlist?.map((row, index) => (
          <ListAdItem
            {...row}
            key={row.id}
            index={index}
            onFavorite={handleFavorite}
            onMessage={handleMessage}
          />
        ))}
      </div>

      <Pagination
        isLoading={isLoadingMore}
        listCount={listMyWishlist?.length}
        page={pageWishlist}
        onClick={handlePagination}
      />
    </div>
  )
}

export default WishlistContent