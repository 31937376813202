import axios from "axios"
import React, { useEffect, useState } from 'react'
import Layout from "./Layout"

const TermsConditions = () => {
  
  const [isLoading, setLoading] = useState(true)
  const [listTerms, setListTerms] = useState([])

  useEffect(() => {
    document.title = 'Terms Conditions | Paseero'; 
    window.scrollTo(0, 0)

    const fetchAll = () => {
      Promise.all([
        getApi('TERMS', '/api/master/terms/index'),
      ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();    
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          if (type === 'TERMS') {
            setListTerms(results)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  return (
    <Layout>
      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">Terms and Conditions</div>
          {/* <div className=" mt-2 text-xl text-[#212529]">Info </div> */}
        </div>
      </div>
      <div className=" flex justify-center w-full py-16">
        <div className=" flex flex-col space-y-12 w-[1300px] px-4 lg:px-0">
          {!isLoading && listTerms.map(row => (
            <div key={row.id} className=" flex flex-col space-y-4">              
              <div className=" text-xl lg:text-3xl tracking-wide text-[#B38C32]">{row.title}</div>
              <div className=" leading-8 text-sm lg:text-lg font-normal mt-3" dangerouslySetInnerHTML={{ __html: row.description}} />
            </div>
          ))}
        </div>
      </div>
      <hr className=" my-12" />
    </Layout>
  )
}

export default TermsConditions