import React, { useEffect, useState } from 'react'
import {
  PiChatText,
  // PiStarFill,
  // PiUserFill,
  PiUser,
  PiYoutubeLogoFill,
  PiVideo,
  PiPhone,
} from 'react-icons/pi'
import {
  FaFacebook,
  FaTwitter,
  // FaInstagram,
  // FaPinterest,
  // FaLinkedinIn,
  // FaTiktok,
  FaWhatsapp,
  FaTelegram,
  FaCopy,
  FaChevronLeft,
  FaChevronRight,
  FaCheck,
  FaCamera,
} from 'react-icons/fa';
// import { BsBox2 } from 'react-icons/bs'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import Layout from "./Layout"
import { useCountdown } from "../utils/useCountdown";
import { AverageRating, ListAdItem, LoadingThreeDots, ModalBid, ModalBuyCredit, ModalBuyNow, ModalCreditPurchase, ModalLoginRegister, ModalMessage, Pagination } from "../components";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
// import {Helmet} from "react-helmet";
import { LiaTimesCircleSolid } from "react-icons/lia";
import CopyToClipboard from "react-copy-to-clipboard";

const AdDetail = () => {
  const params = useParams()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const [isLoading, setLoading] = useState(true)
  const [detail, setDetail] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [imageActive, setImageActive] = useState({
    index: null,
    url: null
  });
  const [videoActive, setVideoActive] = useState(null);
  const [showModalBid, setShowModalBid] = useState(false);
  const [isSuccessBid, setSuccessBid] = useState(false);
  const [bidPrice, setBidPrice] = useState('')
  const [listRecomended, setListRecomended] = useState([])
  const [showModalBuyNow, setShowModalBuyNow] = useState(false)
  const [showModalBC, setShowModalBC] = useState(false)
  const [showModalCP, setShowModalCP] = useState(false)
  const [showModalMsg, setShowModalMsg] = useState(false)
  const [showModalZoom, setShowModalZoom] = useState(false)
  const [isLoadingBuyNow, setLoadingBuyNow] = useState(false)
  const [isLoadingPay, setLoadingPay] = useState(false)
  const [isLoadingBids, setLoadingBids] = useState(false)
  const [isLoadingOnBid, setLoadingOnBid] = useState(false)
  const [isShowNumber, setShowNumber] = useState(false)
  const [isShowRating, setShowRating] = useState(false)
  const [payPrice, setPayPrice] = useState('0')
  const [feePrice, setFeePrice] = useState('0')
  const [msgUser, setMsgUser] = useState(null)
  const [qty, setQty] = useState(1)
  const [listDiscussion, setListDiscussion] = useState([])
  const [text, setText] = useState('')
  const [textReply, setTextReply] = useState('')
  const [isLoadingSend, setLoadingSend] = useState(false)
  const [isLoadingReply, setLoadingReply] = useState(false)
  const [roomId, setRoomId] = useState(null)
  const [paymentType, setPaymentType] = useState(2) // 1: Paystack, 2: Visa, 3:Credit
  const [bankTf, setBankTf] = useState(0)
  const [pageBids, setPageBids] = useState(null)
  const limit = 10
  // console.log({detail});

  useEffect(() => {
    // document.title = 'Detail | Paseero';
    window.scrollTo(0, 0)
    // getAdDetail(params.id)

    const fetchAll = () => {
      Promise.all([
        getAdDetail(params.id),
        getApi('RECOMENDED', `/api/ad/recommended?ads_id=${params.id}`),
        getApi('DISCUSSION', `/api/ad/discussion?ads_id=${params.id}`),
      ])
        .then(() => {
          // setTimeout(() => {
          //   setLoading(false);
          // }, 500);
        })
        .catch((error) => {
          console.error('Error promise data:', error);
          setLoading(false);
        });
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const getAdDetail = (id) => {
    const apiUrl = process.env.REACT_APP_API_URL + `/api/ad/list/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          if (results.length > 0) {
            // document.title = results[0]?.title+' | Paseero';
            setDetail(results[0])
            setImageActive({
              index: 0,
              url: results[0]?.photos[0]?.url
            })
            setMsgUser({
              ...results[0].user,
              targeted_user_id: results[0].user.id,
              targeted_user_photo: results[0].user.photo,
              targeted_user_full_name: !!results[0].user.business_name ? results[0].user.business_name : results[0].user.first_name + ' ' + results[0].user.last_name,
            })
            getApi('BIDS', `/api/ad/bid_list/${params.id}?per_page=${limit}&page=1`)
            setTimeout(() => {
              setLoading(false)
            }, 500);
            // handleMeta(results[0])
          }else{
            navigate('/404')
          }
        } else if(response.status === 400) {
          navigate('/404')
        } else{
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        navigate('/404')
      });
  }

  // async function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }

  // const handleCopyClick = () => {
  //   // Asynchronously call copyTextToClipboard
  //   copyTextToClipboard(window.location.href)
  //     .then(() => {
  //       // If successful, update the isCopied state value
  //       setIsCopied(true);
  //       setTimeout(() => {
  //         setIsCopied(false);
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  const ExpiredCountdown = ({ date }) => {
    const NOW_IN_MS = new Date(date).getTime();
    const [days, hours, minutes, seconds] = useCountdown(NOW_IN_MS);

    return (
      <div className=" grid grid-cols-4 gap-4">
        <div className=" p-4 rounded-lg border bg-white items-center flex flex-col">
          <div className=" text-3xl font-semibold">{days < 0 ? 0 : days}</div>
          <div className=" text-sm font-light">Days</div>
        </div>
        <div className=" p-4 rounded-lg border bg-white items-center flex flex-col">
          <div className=" text-3xl font-semibold">{hours < 0 ? 0 : hours}</div>
          <div className=" text-sm font-light">Hours</div>
        </div>
        <div className=" p-4 rounded-lg border bg-white items-center flex flex-col">
          <div className=" text-3xl font-semibold">{minutes < 0 ? 0 : minutes}</div>
          <div className=" text-sm font-light">Mins</div>
        </div>
        <div className=" p-4 rounded-lg border bg-white items-center flex flex-col">
          <div className=" text-3xl font-semibold">{seconds < 0 ? 0 : seconds}</div>
          <div className=" text-sm font-light">Secs</div>
        </div>
      </div>
    )
  }

  // const handleShare = (text, url) => {
  //   console.log({text});
  //   console.log({url});
  //   const encodedCaption = encodeURIComponent(text);
  //   const encodedImageUrl = encodeURIComponent(url);
  //   const urlLink = `instagram://library?AssetPath=${encodedImageUrl}&InstagramCaption=${encodedCaption}`;

  //   // const odedCaption = encodeURIComponent(text);
  //   // const encodedVideoUrl = encodeURIComponent(url);
  //   // const urenclLink = `snssdk1128://aweme/detail/${encodedVideoUrl}?desc=${encodedCaption}`;

  //   window.open(urlLink, '_blank');
  // };

  const handleBid = () => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    if (bidPrice === 0) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Bid Price required!',
        confirmButtonColor: '#B38C32'
      })
    }
    
    setLoadingOnBid(true)
    const apiUrl = process.env.REACT_APP_API_URL + '/api/transaction/bid';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: params?.id,
      bid_price: bidPrice,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          setDetail(prevState => {
            return {
              ...prevState,
              bid_winner_text: results[0].bid_winner_text,
              actual_price_text: results[0].bid_price_text,
              actual_price: results[0].bid_price,
              user_has_bid: results[0].user_has_bid,
              // bids: results
            }
          })
          getApi('BIDS', `/api/ad/bid_list/${params.id}?per_page=${limit}&page=1`)
          setBidPrice(results[0].bid_price + 1)
          setSuccessBid(true)
          setTimeout(() => {
            setLoadingOnBid(false)
          }, 1000);
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages, results } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
        setBidPrice(results.sugested_bid_price)
        setTimeout(() => {
          setLoadingOnBid(false)
        }, 1000);
        // setLoading(false)
      });
  }

  useEffect(() => {
    if (isSuccessBid) {
      setTimeout(() => {
        setSuccessBid(false)
      }, 3000);
    }
  }, [isSuccessBid])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL + endPoint;
    const headers = {
      'Content-Type': 'application/json',
      // Authorization: auth !== null ? `Bearer ${auth.access_token}` : '',
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'RECOMENDED') {
            setListRecomended(results)
          }else if(type === 'DISCUSSION'){
            setListDiscussion(results)
          }else{
            setDetail(prevState => {
              return {
                ...prevState,
                bids: results
              }
            })
            setPageBids(metadata)
            setLoadingBids(false)
          }
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleFavorite = (index, id) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    const apiUrl = process.env.REACT_APP_API_URL + '/api/user/wishlist/toogle';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          let list = [...listRecomended]
          list[index] = {
            ...list[index] = {
              ...list[index],
              is_favorite: results.is_favorite,
            }
          }
          setListRecomended(list)
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
      });
  }

  const handleFavoriteDetail = (id) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    const apiUrl = process.env.REACT_APP_API_URL + '/api/user/wishlist/toogle';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          setDetail(prevState => {
            return {
              ...prevState,
              is_favorite: results.is_favorite
            }
          })
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
      });
  }

  const handleBuyNow = (type) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    setLoadingBuyNow(true)

    const apiUrl = process.env.REACT_APP_API_URL + '/api/transaction/buy_now';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: params?.id,
      payment_method: type,
      qty: qty
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          // console.log(results);
          window.location.replace(results.authorization_url.url)
          // setLoadingBuyNow(false)
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        if (error.response.data.code === 401) {
          Swal.fire({
            title: "Insufficient Credit",
            text: messages,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#B38C32",
            confirmButtonText: "Buy credit"
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/my-account?tabs=orders')
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: messages,
            confirmButtonColor: '#DC2625'
          })
        } 
        setLoadingBuyNow(false)
      });
  }

  const handleBuyNowCredit = () => {
    if (parseInt(payPrice) < 100) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Pay Amount minimal ₦100!',
        confirmButtonColor: '#B38C32'
      })
    }
    setShowModalCP(true)
  }

  const handleTransactionPay = () => {
    setLoadingPay(true)
    const apiUrl = process.env.REACT_APP_API_URL + '/api/transaction/pay';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      // amount: payPrice,
      // amount: parseInt(payPrice)-parseInt(feePrice),
      amount: parseInt(payPrice),
      // transfer_fee: parseInt(feePrice),
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            window.location.replace(results.authorization_url.url)
          }, 500);
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages, results } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: results.title,
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingPay(false)
      });
  }

  const handleMessage = (item) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    setMsgUser({
      ...item,
      targeted_user_id: item?.id,
      targeted_user_photo: item?.photo,
      targeted_user_full_name: !!item?.business_name ? item?.business_name : item?.first_name+' '+item?.last_name,
    })

    setTimeout(() => {
      setShowModalMsg(true)
    }, 100);
  }

  const handleSend = () => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    setLoadingSend(true)

    const apiUrl = process.env.REACT_APP_API_URL + '/api/ad/discussion';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: params.id,
      content: text
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          // setTimeout(() => {
            const { results } = response.data.response
            setListDiscussion(results)
            setLoadingSend(false)
            setText('')
          // }, 500);
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingSend(false)
      });
  }

  const handleReply = () => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    setLoadingReply(true)

    const apiUrl = process.env.REACT_APP_API_URL + '/api/ad/discussion-reply';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      discussion_room_id: roomId,
      content: textReply
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          // setTimeout(() => {
            const { results } = response.data.response
            setListDiscussion(results)
            setLoadingReply(false)
            setRoomId(null)
            setTextReply('')
          // }, 500);
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingReply(false)
      });
  }

  useEffect(() => {  
    //Implementing the setInterval method 
    const interval = setInterval(() => { 
        if (!showModalZoom) {
          let idx = imageActive.index+1
          setImageActive({
            index: idx <= detail?.photos.length-1 ? idx : 0,
            url: detail?.photos[idx <= detail?.photos.length-1 ? idx : 0].url
          }) 
        }
    }, 3000); 

    //Clearing the interval 
    return () => clearInterval(interval); 
  }, [showModalZoom, imageActive, detail])

  useEffect(() => {
    if (isCopied) {      
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  }, [isCopied])  

  useEffect(() => {
    if (payPrice > 0) {
      let fee = calculateTransactionFee(payPrice)
      setFeePrice(fee)
    }    
  }, [payPrice])
  
  function calculateTransactionFee(v) {
    let tf = 0;
    v = parseInt(v);
  
    if (v < 2500) {
      tf = 0.015 * v;
      if (tf > 2000) {
        tf = 2000;
      }
    } else {
      tf = 0.015 * v + 100;
      if (tf > 2000) {
        tf = 2000;
      }
    }
  
    if (v <= 5000) {
      tf += 10;
    } else if (v >= 5001 && v <= 50000) {
      tf += 25;
    } else {
      tf += 50;
    }
  
    return parseInt(tf);
  }

  useEffect(() => {
    if (showModalBuyNow) {
      // (qty * price) + shipping => calculate()
      let total = calculateTransactionFee(qty * parseInt(detail?.buynow_price) + parseInt(detail?.shipping_price))
      setBankTf(total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalBuyNow])  

  const DescComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div className=" lg:text-xl font-semibold p-3 lg:p-4 border-b">Description</div>
        {isLoading ? (
          <div className=" flex justify-center p-8">
            <LoadingThreeDots
              size={'20'}
              color={"#B38C32"}
            />
          </div>
        ):( 
          <div className=" p-3 lg:p-4 flex flex-col ">
            <p className=" text-gray-400 text-sm lg:text-base whitespace-pre-line">{detail?.description}</p>
            <div className=" mt-4 grid grid-cols-2 lg:grid-cols-4 gap-2">
              {detail?.features?.map(row => (
                <div key={row?.feature_id} className=" flex items-center">
                  <FaCheck className=" text-[#B38C32] mr-2" />
                  <span className=" line-clamp-1 text-xs lg:text-sm">{row?.feature_title}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }

  const ProfileComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div className=" p-3 lg:p-4 border-b flex items-center space-x-4">
          <div className=" lg:text-xl font-semibold">
            Seller Details
          </div>
          <div className=" flex items-center space-x-1">
            <AverageRating
              rate={detail?.user?.rating}
              size={' lg:text-xl'}
            />
          </div>
        </div>
        {isLoading ? (
          <div className=" flex justify-center p-8">
            <LoadingThreeDots
              size={'20'}
              color={"#B38C32"}
            />
          </div>
        ):(  
          <div className=" p-4 flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 justify-between">
            <div className=" flex items-center space-x-3">
              <img
                alt="img-profile"
                src={detail?.user?.photo !== '' ? detail?.user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                className=" w-12 h-12 lg:w-20 lg:h-20 rounded-full"
              />
              <div>
                <div className=" flex items-center space-x-1">
                  <div className=" text font-semibold">{detail?.user?.full_name}</div>
                  <div className=" text-xs">• {detail?.user?.is_online === 1 ? 'Online' : detail?.user?.last_online}</div>
                </div>
                <div className=" text-gray-400 text-sm">{detail?.user?.address?.states_text + ', ' + detail?.user?.address?.local_government_text + ', ' + detail?.user?.address?.address}</div>
                <p className=" pt-2 text-sm">{detail?.user?.description}</p>
              </div>
            </div>
            <div className=" flex flex-row lg:flex-col space-x-1 lg:space-x-0 lg:space-y-3 justify-end items-end">
              <div className=" flex space-x-1 lg:space-x-3 items-center">
                {auth === null || !isShowNumber ? (
                  <button
                    className="lg:w-40 justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                    onClick={() => {
                      if (auth === null) {
                        setShowModal(true)
                        return false
                      }
                      setShowNumber(!isShowNumber)
                    }}
                  >
                    {!isShowNumber ? (
                      <>
                        <PiPhone className=" text-xl " />
                        <span className=" hidden lg:block">Number</span>
                      </>
                    ) : (
                      <div>{detail?.user?.phone_number}</div>
                    )}
                  </button>
                ) : null}

                {isShowNumber ? (
                  <a
                    className=" lg:w-40 text-sm justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                    href={`tel:${detail?.user?.phone_number}`}
                  >
                    {detail?.user?.phone_number}
                  </a>
                ) : null}

                {user?.id !== detail?.user?.id ? (
                  <button
                    className=" lg:w-40 justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                    onClick={() => {
                      if (auth !== null) {
                        setShowModalMsg(true)
                      } else {
                        setShowModal(true)
                      }
                    }}
                  >
                    <PiChatText className=" text-xl " />
                    <span className=" hidden lg:block">Message</span>
                  </button>
                ) : null}

              </div>

              <Link
                to={`/author/${detail?.user?.id}`}
                className=" lf:w-40 justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
              >
                <PiUser className=" text-xl " />
                <span className=" hidden lg:block">View Profile</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }

  const RatingCommentComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div 
          className=" text-xl font-semibold p-4 border-b justify-between items-center flex cursor-pointer"
          onClick={() => setShowRating(!isShowRating)}
        >
          <div className="text-xl font-semibold">Rating & Comment</div>                
          <FaChevronRight className={`${isShowRating ? 'rotate-90' : ''} transition-all`} />
        </div>
        {isShowRating ? (              
          <div className=" p-4 flex flex-col space-y-4 duration-300">
            {detail?.comment_ratings?.map(row => (                   
              <div 
                key={row?.id}
                className=" flex flex-row space-x-2 items-start w-full">
                <img
                  alt="img-profile"
                  src={row?.user?.photo !== '' ? row?.user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                  className=" w-10 h-10 rounded-full"
                />
                <div className=" flex flex-col w-full">
                  <div className=" text-sm">{row?.user?.full_name} • {row?.created_at_text}</div>
                  <div className=" flex">
                    <AverageRating
                      rate={row?.rating}
                      size={'text-sm'}
                    />
                  </div>
                  {row?.comment !== '' ? (
                    <div className=" p-2 px-4 bg-gray-200 rounded-sm w-full mt-2">
                      <p className=" text-sm">{row?.comment}</p>
                    </div>
                  ): null}
                </div>
              </div>
            ))}
          </div>
        ): null}
      </div>
    )
  }

  const DiscussionComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div className=" lg:text-xl font-semibold p-3 lg:p-4 border-b">Discussion</div>
        <div className=" p-3 lg:p-4 flex flex-col space-y-4 duration-300">
          {/* {listDiscussion.length === 0 ? (                 */}
            <div className=" flex space-x-2">
              <img
                alt="img-profile"
                src={auth === null ? process.env.PUBLIC_URL + '/assets/Default.png' :  user?.photo !== '' ? user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                className=" w-10 h-10 rounded-full"
              />
              <div className=" w-full space-y-1">
                <textarea
                  className=" p-3 rounded w-full bg-gray-100 border focus:outline-none text-xs lg:text-sm"
                  placeholder="Type some text.."
                  rows={2}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <button 
                  className={` p-2 w-24 flex text-sm lg:text-base justify-center rounded bg-[#B38C32] text-white border border-[#B38C32] hover:bg-white hover:text-[#B38C32] duration-300 focus:outline-none ${isLoadingSend ? ' cursor-progress' : ' cursor-pointer'}`}
                  onClick={() => handleSend()}
                  disabled={isLoadingSend}
                >
                  {isLoadingSend ?
                    <LoadingThreeDots size={'20'}/>
                  :  <span>Send</span>
                  }                      
                </button>
              </div>
            </div>
          {/* ): null} */}
          
          {listDiscussion?.map(row => (                   
            <div 
              key={row?.id}
              className=" flex flex-row space-x-2 items-start w-full"
            >
              <img
                alt="img-profile"
                src={row?.user?.photo !== '' ? row?.user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                className=" w-10 h-10 rounded-full"
              />
              <div className=" flex flex-col w-full">
                <div className=" flex flex-col w-full">
                  <div className=" text-sm">{row?.user?.full_name} • {row?.created_at_text}</div>
                  {row?.content !== '' ? (
                    <div className=" p-2 px-4 bg-gray-200 w-full mt-2">
                      <p className=" text-sm">{row?.content}</p>
                    </div>
                  ): null}
                </div>
                <div className="ml-2">
                  {row.replies?.map(list => (                   
                    <div 
                      key={list?.id}
                      className=" flex flex-row space-x-2 items-start w-full mt-3">
                      <img
                        alt="img-profile"
                        src={list?.user?.photo !== '' ? list?.user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                        className=" w-10 h-10 rounded-full"
                      />
                      <div className=" flex flex-col w-full">
                        <div className=" text-sm">{list?.user?.full_name} • {list?.created_at_text}</div>
                        {list?.content !== '' ? (
                          <div className=" p-2 px-4 bg-gray-200 w-full mt-2">
                            <p className=" text-sm">{list?.content}</p>
                          </div>
                        ): null}
                      </div>
                    </div>
                  ))}
                  {roomId !== null && roomId === row?.id ? (
                    <div className=" flex space-x-2 mt-4">
                      <img
                        alt="img-profile"
                        src={auth === null ? process.env.PUBLIC_URL + '/assets/Default.png' :  user?.photo !== '' ? user?.photo : process.env.PUBLIC_URL + '/assets/Default.png'}
                        className=" w-10 h-10 rounded-full"
                      />
                      <div className=" w-full space-y-1">
                        <textarea
                          className=" p-3 rounded w-full bg-gray-100 border focus:outline-none text-sm"
                          placeholder="Type some text.."
                          rows={2}
                          value={textReply}
                          onChange={(e) => setTextReply(e.target.value)}
                        />
                        <div className=" flex space-x-2 items-center">
                          <button 
                            className={` p-2 w-24 flex justify-center rounded bg-[#B38C32] text-white border border-[#B38C32] hover:bg-white hover:text-[#B38C32] duration-300 focus:outline-none ${isLoadingReply ? ' cursor-progress' : ' cursor-pointer'}`}
                            onClick={() => handleReply()}
                            disabled={isLoadingReply}
                          >
                            {isLoadingReply ?
                              <LoadingThreeDots size={'20'} />
                            :  <span>Send</span>
                            }                      
                          </button>
                          <button 
                            className=" p-2 w-24 flex justify-center rounded border hover:bg-gray-50 hover:text-white duration-300 cursor-pointer focus:outline-none"
                            onClick={() => setRoomId(null)}
                          >
                            Cancel                 
                          </button>
                        </div>
                      </div>
                    </div>
                  ):(
                    <div 
                      className=" mt-2 text-gray-500 hover:underline cursor-pointer"
                      onClick={() => setRoomId(row.id)}
                    >
                      Reply
                    </div>
                  )}                        
              </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const ShareComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div className=" lg:text-xl font-semibold p-3 lg:p-4 lg:px-6 border-b">Share this link via</div>
        <div className=" p-3 lg:p-6 grid grid-cols-4 gap-4">
          {/* <div
            className=" w-16 h-16 flex items-center justify-center rounded-full bg-white border border-[#F600BC] text-[#F600BC] hover:bg-[#F600BC] hover:text-white cursor-pointer duration-300"
            onClick={() => handleShare(detail?.title, detail?.photos[0]?.url)}
          >
            <FaInstagram className="  text-2xl" />
          </div> */}
          <WhatsappShareButton
            url={window.location.href}
            title={detail?.title}
            separator=" "
          >
            <div className=" w-10 h-10 lg:w-16 lg:h-16 flex items-center justify-center rounded-full bg-white border border-[#4DC358] text-[#4DC358] hover:bg-[#4DC358] hover:text-white cursor-pointer duration-300" >
              <FaWhatsapp className="lg:text-2xl" />
            </div>
          </WhatsappShareButton>
          <TelegramShareButton
            url={window.location.href}
            title={detail?.title}
          >
            <div className=" w-10 h-10 lg:w-16 lg:h-16 flex items-center justify-center rounded-full bg-white border border-[#37AEE2] text-[#37AEE2] hover:bg-[#37AEE2] hover:text-white cursor-pointer duration-300" >
              <FaTelegram className="lg:text-2xl" />
            </div>
          </TelegramShareButton>
          <FacebookShareButton
            url={window.location.href}
            quote={detail?.title}
            hashtag={'#paseero'}
          >
            <div className=" w-10 h-10 lg:w-16 lg:h-16 flex items-center justify-center rounded-full bg-white border border-[#4C65A8] text-[#4C65A8] hover:bg-[#4C65A8] hover:text-white cursor-pointer duration-300" >
              <FaFacebook className="lg:text-2xl" />
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={window.location.href}
            title={detail?.title}
            hashtags={[detail?.keywords]}
          >
            <div className=" w-10 h-10 lg:w-16 lg:h-16 flex items-center justify-center rounded-full bg-white border border-[#41A1F5] text-[#41A1F5] hover:bg-[#41A1F5] hover:text-white cursor-pointer duration-300" >
              <FaTwitter className=" lg:text-2xl" />
            </div>
          </TwitterShareButton>
          {/* <div
            className=" w-16 h-16 flex items-center justify-center rounded-full bg-white border border-[#F7004C] text-[#F7004C] hover:bg-[#F7004C] hover:text-white cursor-pointer duration-300 "
            onClick={() => alert('Soon!')}
          >
            <FaTiktok className="  text-2xl" />
          </div> */}
          {/* <div className=" w-16 h-16 flex items-center justify-center rounded-full bg-white border border-[#41A1F5] text-[#41A1F5] hover:bg-[#41A1F5] hover:text-white cursor-pointer duration-300 "><FaTwitter className="  text-2xl" /></div>
          <div className=" w-16 h-16 flex items-center justify-center rounded-full bg-white border border-[#F54C5B] text-[#F54C5B] hover:bg-[#F54C5B] hover:text-white cursor-pointer duration-300 "><FaPinterest className="  text-2xl" /></div>
          <div className=" w-16 h-16 flex items-center justify-center rounded-full bg-white border border-[#0088CB] text-[#0088CB] hover:bg-[#0088CB] hover:text-white cursor-pointer duration-300 "><FaLinkedinIn className="  text-2xl" /></div> */}
        </div>
        <div className=" p-4 lg:p-6 flex items-center">
          <input
            className=" p-2 px-4 text-sm lg:text-base w-full border rounded-l focus:outline-none bg-gray-50 border-r-0"
            value={window.location.href}
            readOnly
          />
          {/* <button
            className=" p-2 px-3 text-sm lg:text-base text-white bg-[#B38C32] border border-[#B38C32] rounded-r hover:text-[#B38C32] hover:bg-white duration-300 flex items-center space-x-2"
            onClick={handleCopyClick}
          >
            <FaCopy />
            <span>{isCopied ? 'Copied!' : 'Copy'}</span>
          </button> */}
          <CopyToClipboard 
            text={window.location.href}
            onCopy={() => setIsCopied(true)}
          >
            <button className=" p-2 px-3 text-sm lg:text-base text-white bg-[#B38C32] border border-[#B38C32] rounded-r hover:text-[#B38C32] hover:bg-white duration-300 flex items-center space-x-2" >
              <FaCopy />
              <span>{isCopied ? 'Copied!' : 'Copy'}</span>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  const SecurityComponent = () => {
    return (
      <div className=" bg-white border rounded">
        <div className=" lg:text-xl font-semibold p-3 lg:p-4 lg:px-6 border-b">Security advice for buyers</div>
        <div className=" m-4 text-sm">
          <p>• <b>Pay online:</b> it is more secure, since Paseero holds your payment until you confirm item receipt as expected. It’s easy to ask for a refund, as Paseero monitors the whole transaction process. </p>
          <p>• <b>In person/private payment:</b> meet in a public and safe place during the day. Carefully inspect the item before making payment. Paseero is not liable for a refund for private payment.</p>
        </div>          
      </div>
    )
  }

  const handlePagination = (type) => {
    setLoadingBids(true)
    let page = parseInt(pageBids?.selectedPage) 
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }

    getApi('BIDS', `/api/ad/bid_list/${params.id}?per_page=${limit}&page=${page}`)       
  }

  // const handleMeta = (value) => {
  //   metaAdder('name="description"', value.description)
  //   metaAdder('name="robots"', "index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large")
  //   metaAdder('property="og:title"', value.title+' | Paseero')
  //   metaAdder('property="og:description"', value.description)
  //   metaAdder('property="og:url"', window.location.href)
  //   metaAdder('property="og:site_name"', 'paseero.ng')
  //   metaAdder('property="article:section"', 'Ads Details')
  //   metaAdder('property="og:updated_time"', value.updated_at)
  //   metaAdder('property="og:image"', value.photos[0]?.url)
  //   metaAdder('property="og:image:secure_url"', value.photos[0]?.url)
  //   metaAdder('property="og:image:width"', '250')
  //   metaAdder('property="og:image:height"', '250')
  //   metaAdder('property="og:image:alt"', value.title)
  //   metaAdder('property="og:image:type"', 'image/jpeg')
  //   metaAdder('property="article:published_time"', value.created_at)
  //   metaAdder('property="article:modified_time"', value.updated_at)
    
  //   metaAdder('name="twitter:card"', 'summary_large_image')
  //   metaAdder('name="twitter:title"', value.title+' | Paseero')
  //   metaAdder('name="twitter:description"', value.description)
  //   metaAdder('name="twitter:image"', value.photos[0]?.url)
  // }

  // const metaAdder = (queryProperty, value) => {
  //   // Get an element if it exists already
  //   let element = document.querySelector(`meta[${queryProperty}]`);
  
  //   // Check if the element exists
  //   if (element) {
  //     // If it does just change the content of the element
  //     element.setAttribute("content", value);
  //   } else {
  //     // It doesn't exist so lets make a HTML element string with the info we want
  //     element = `<meta ${queryProperty} content="${value}" />`;
  
  //     // And insert it into the head
  //     document.head.insertAdjacentHTML("beforeend", element);
  //   }
  // };

  return (
    <Layout>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{detail?.title+' | Paseero'}</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/logo192.png`} />
        <meta name="description" content={detail?.description} />
        <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={detail?.title+' | Paseero'} />
        <meta property="og:description" content={detail?.description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="paseero.com" />
        <meta property="article:section" content="Ads Details" />
        <meta property="og:updated_time" content={detail?.updated_at} />
        <meta property="og:image" content={detail?.photos[0]?.url} />
        <meta property="og:image:secure_url" content={detail?.photos[0]?.url} />
        <meta property="og:image:width" content="250" />
        <meta property="og:image:height" content="250" />
        <meta property="og:image:alt" content={detail?.title} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="article:published_time" content={detail?.created_at} />
        <meta property="article:modified_time" content={detail?.updated_at} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={detail?.title+' | Paseero'} />
        <meta name="twitter:description" content={detail?.description} />
        <meta name="twitter:image" content={detail?.photos[0]?.url} />

        <meta charSet="utf-8" />
        <title>My Ad Detail Page Title</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Taufik Hidayah" />
        <meta property="og:title" content="My Ad Detail Page Title" />
        <meta property="og:description" content="This is a description of my page" />
        <meta property="og:image" content="https://staging.paseero.ng/staging/api/uploads/ads/photos/162/356510855_61j99uUfXNL._AC_UF894_1000_QL80_.jpg.webp" />
        <meta property="og:url" content="https://staging.paseero.ng/staging/listing/162/Testing-queried-ad--edited" />

        <meta name="twitter:title" content="My Ad Detail Page Title" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://staging.paseero.ng/staging/api/uploads/ads/photos/162/356510855_61j99uUfXNL._AC_UF894_1000_QL80_.jpg.webp" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      <div className=" mt-4 lg:mt-8" />
      <div className=" flex justify-center w-full mt-4 lg:mt-12 ">
        <div className=" w-[1300px] flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-12 px-2 lg:px-0">
          <div className=" lg:w-2/3 flex flex-col space-y-4">
            <div className=" bg-white border rounded">
              <div className=" lg:text-xl font-semibold p-4 border-b">Photos</div>
              {isLoading ? (
                <div className=" flex justify-center items-center h-[500px]">
                  <LoadingThreeDots color={"#B38C32"} />
                </div>
              ) : null}
              {!isLoading ? (
                <div className=" flex justify-center flex-col relative">
                  {videoActive === 'uploaded' ? (
                    <video controls>
                      {/* <source src="http://154.56.56.56/staging/api/uploads/ads/videos/17/797685018_SampleVideo_1280x720_2mb.mp4" type="video/mp4" />
                      <source ssrc="http://154.56.56.56/staging/api/uploads/ads/videos/17/797685018_SampleVideo_1280x720_2mb.mp4" type="video/ogg"></source> */}
                      <source src={detail?.videos[0].url} type="video/mp4" />
                      <source ssrc={detail?.videos[0].url} type="video/ogg"></source>
                    </video>
                  ) : videoActive === 'youtube' ? (
                    <iframe
                      className="w-full h-64 lg:h-[500px]"
                      width="560"
                      height="315"
                      src={detail?.videos[0]?.url}
                      title="YouTube video player"
                      // frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                  ) : (
                    <>
                      <div className=" relative">
                        <img
                          alt={detail?.title}
                          src={imageActive.url}
                          className=" w-full h-64 lg:h-[500px] object-cover duration-300"
                          onClick={() => setShowModalZoom(true)}
                        />
                        <div className=" absolute flex items-center text-white text-xs bottom-5 left-5 p-1 px-2 rounded bg-black/50">
                          <FaCamera className=" mr-2" /> {imageActive.index+1}/{detail?.photos.length}
                        </div>
                      </div>
                      <div className="  bg-gray-400 top-[45%] p-2 rounded-full absolute left-3 lg:left-8 cursor-pointer hover:bg-opacity-75 duration-300" >
                        <FaChevronLeft 
                          className=" text-xl text-white"
                          onClick={() => {
                            let idx = imageActive.index-1
                            if (idx !== -1) {
                              setImageActive({
                                index: idx,
                                url: detail?.photos[idx].url
                              })                  
                            }
                          }}
                        />
                      </div>
                      <div className="  bg-gray-400 top-[45%] p-2 rounded-full absolute right-3 lg:right-8 cursor-pointer hover:bg-opacity-75 duration-300" >
                        <FaChevronRight 
                          className=" text-xl text-white"
                          onClick={() => {
                            let idx = imageActive.index+1
                            if (idx <= detail?.photos.length-1) {
                              setImageActive({
                                index: idx,
                                url: detail?.photos[idx].url
                              })                  
                            }
                          }}
                        />
                      </div>
                    </>
                  )}

                  <div className=" flex flex-row space-x-2 justify-center pt-2 overflow-x-auto pb-3">
                    {!isLoading && detail?.photos?.map((row, index) => (
                      // <>
                        <img
                          key={index}
                          alt={detail?.title + index}
                          src={row.url}
                          className=" w-20 h-16 object-cover rounded cursor-pointer"
                          onClick={() => {
                            setVideoActive(null)
                            setImageActive({
                              index: index,
                              url: row.url
                            })
                          }}
                        />
                      // </>
                    ))}
                    {detail?.videos.length > 0 ? (
                      <div
                        className=" w-20 h-16 bg-gray-200 rounded flex justify-center items-center cursor-pointer"
                        onClick={() => setVideoActive(detail?.videos[0].is_uploaded === 1 ? 'uploaded' : 'youtube')}
                      >
                        {detail?.videos[0].is_uploaded === 1 ?
                          <PiVideo className="  text-5xl" />
                          : <PiYoutubeLogoFill className=" text-red-600 text-5xl" />
                        }
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="hidden lg:block">
              <DescComponent />
            </div>

            <div className="hidden lg:block">
              <ProfileComponent />
            </div>           

            {detail?.comment_ratings.length > 0 ? (
              <div className=" hidden lg:block">
                <RatingCommentComponent />
              </div>
            ): null}

            <div className="hidden lg:block">
              {DiscussionComponent()}
            </div>  

          </div>
          <div className=" lg:w-1/3 flex flex-col space-y-3">
            <div className=" text-xl lg:text-4xl font-semibold ">{detail?.title}</div>
            <div className=" border-b" />
            {/* {detail?.shipping_status === 1 ?            
              <div className=" flex items-center space-x-2">
                <BsBox2 />
                <div className=" font-light text-sm lg:text-base">{detail?.shipping_status === 1 ? 'Shipping cost: ' + detail?.shipping_price_text : 'Item available for pickup only'}</div>
              </div>
            : null } */}
            {!isLoading && detail?.ads_type === 1 ? <ExpiredCountdown date={detail?.expired_at} /> : null}
            {detail?.ads_type === 0 && (detail?.user?.id !== user?.id) ? (
              <>
                <div className=" flex flex-row text-sm lg:text-base justify-between items-center">
                  <label>Quantity</label>
                  <input
                    type={'number'}
                    className=" p-2 px-4 w-2/3 ml-8 bg-white border rounded focus:outline-none"
                    placeholder="Quantity"
                    // min={1}
                    max={detail?.qty}
                    value={qty}
                    onChange={(e) => {
                      let val = e.target.value
                      // if (val < 1) {
                        // setQty(1)
                      // }else 
                      if(val > detail.qty){
                        setQty(detail.qty)
                        // setQty(val)
                      }else{
                        setQty(val)
                      }
                      // setQty(val)
                    }}
                    // onFocus={(e) => e.target.select()}
                    onClick={(e) => e.target.select()}
                    // onKeyUp={(e) => e.target.select()}
                  />
                </div>
                {detail?.is_active ? (
                  <button
                    className=" p-3 w-full relative text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                    onClick={() => {
                      if (auth == null) {
                        setShowModal(true)
                      } else {
                        if (detail?.qty === 0) {
                          Swal.fire({
                            icon: 'warning',
                            title: 'Warning',
                            text: 'This product is out of stock. Please contact the seller.',
                            confirmButtonColor: '#B38C32'
                          })
                        }else if(qty < 1){
                          Swal.fire({
                            icon: 'warning',
                            title: 'Invalid Stock Quantity',
                            text: 'Minimum stock quantity you can purchase is 1.',
                            confirmButtonColor: '#B38C32'
                          })                        
                        } else {
                          if (detail?.user?.accept_online_purchase === 1) {
                            setShowModalBuyNow(true)
                          }else{
                            Swal.fire({
                              icon: 'warning',
                              title: 'Seller does not accept online orders',
                              text: 'Contact the seller to purchase the item.',
                              confirmButtonColor: '#B38C32'
                            })
                          }
                        }
                      }
                    }}
                  >
                    <span>Buy Now</span>
                    <span className=" absolute right-2 top-2 bg-white text-[#B38C32] p-1 px-2 rounded text-left">{detail?.buynow_price_text}</span>
                  </button>
                ): null}
              </>
            ) : null}
            {detail?.ads_type === 1 && (detail?.user?.id !== user?.id) ? (
              <>
                {detail?.user_has_bid === 0 ?
                  <div className=" text-sm font-light">You will need to deposit <span className=" font-semibold text-xl text-[#B38C32] text-blink">{detail?.deposit_amount_text}</span> Paseero credit to bid on this ad. You will be refunded 100% if you lose the auctions.</div>
                  : null}
                {detail?.is_active ? (
                  <>
                    <button
                      className=" p-3 px-5 w-full relative text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                      onClick={() => {
                        if (auth == null) {
                          setShowModal(true)
                        } else {
                          if (detail?.user_has_bid === 1 || user?.credit > detail?.deposit_amount) {
                            setBidPrice(detail?.actual_price + 1)
                            setShowModalBid(true)
                          } else {
                            Swal.fire({
                              icon: 'warning',
                              title: 'Insufficient Paseero Credit',
                              text: `Your first bid on the auction requires Paseero credit as deposit.`,
                              showCancelButton: true,
                              confirmButtonText: 'Buy Credit',
                              confirmButtonColor: '#B38C32',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                setShowModalBC(true)
                              }
                            })
                          }
                        }
                      }}
                    >
                      <span>Bid Now</span>
                      <span className=" absolute right-2 top-2 bg-white text-[#B38C32] p-1 px-2 rounded">{detail?.actual_price_text}</span>
                    </button>
                    {parseInt(detail?.buynow_price) > parseInt(detail?.starting_price) && (detail?.user?.id !== user?.id) ? (
                      <button
                        className=" p-3 px-5 w-full relative text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                        onClick={() => {
                          if (auth == null) {
                            setShowModal(true)
                          } else {
                            setShowModalBuyNow(true)
                          }
                        }}
                      >
                        <span>Buy Now</span>
                        <span className=" absolute right-2 top-2 bg-white text-[#B38C32] p-1 px-2 rounded">{detail?.buynow_price_text}</span>
                      </button>
                    ) : null}
                  </>
                ): null}
              </>
            ) : null}

            {detail?.bids.length > 0 ? (
              <div className=" bg-white border rounded pb-4">
                <div className=" text-sm p-4 border-b"><span className=" font-semibold">{detail?.bid_winner_text}</span> is the highest bidder.</div>
                <div className=" p-4 flex flex-col space-y-2 ">
                  {detail?.bids.map((row, index) => (
                    <div key={row?.id} className=" flex justify-between">
                      <div className=" flex items-center space-x-2">
                        <span className=" flex justify-center items-center rounded bg-[#B38C32] w-5 h-5 text-white text-sm font-medium">{(pageBids?.selectedPage-1)*pageBids?.totalItemPerPage+index+1}</span>
                        <div className=" font-semibold text-sm">{row?.user?.full_name}</div>
                      </div>
                      {/* <div className=" text-sm">{row?.created_text + ' - ' + row?.bid_price_text}</div> */}
                      <div className=" flex w-48 justify-between text-sm">
                        <div>{row?.created_text}</div>
                        <div>{row?.bid_price_text}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination
                  isLoading={isLoadingBids}
                  listCount={detail?.bids?.length}
                  page={pageBids}
                  onClick={handlePagination}
                />
              </div>
            ) : null}

            {detail?.is_active ? (
              <button
                className=" p-3 w-full text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] duration-300"
                onClick={() => handleFavoriteDetail(detail?.id)}
              >
                {detail?.is_favorite === 1 ? 'Remove' : 'Add'} Wishlist
              </button>
            ): null}

            <div className=" bg-white border rounded">
              <div className=" lg:text-xl font-semibold p-3 lg:p-4 lg:px-6 border-b">Information</div>
              {isLoading ? (
                <div className=" flex justify-center p-8">
                  <LoadingThreeDots
                    size={'20'}
                    color={"#B38C32"}
                  />
                </div>
              ):(          
                <div className=" p-3 lg:p-6 grid grid-cols-2 gap-4 text-sm lg:text-base">
                  {/* <div className=" text-lg font-semibold text-gray-400">Refunds</div>
                  <div></div> */}
                  <div className=" font-semibold">Delivery</div>
                  <div>
                    {detail?.shipping_status === 1 ? 
                      <span className=" bg-green-500 text-white rounded-full text-xs font-semibold p-1 px-4">Delivery Available</span>
                    :
                      <span className=" bg-[#B38C32] text-white rounded-full text-xs font-semibold p-1 px-4">Pickup Only</span>
                    }
                  </div>
                  {detail?.ads_type === 0 ? (
                    <>
                      <div className=" font-semibold">Stock</div>
                      {detail?.qty === 0 ? <div className=" text-red-600 font-medium">{'Out of stock'} </div> : <div>{detail?.qty}</div>}
                    </>
                  ) : null}
                  <div className=" font-semibold">Category</div>
                  <div>{detail?.categories[0]?.subcategory_title}</div>
                  <div className=" font-semibold">Condition</div>
                  <div>{detail?.condition === 1 ? 'Used' : 'New'}</div>
                  <div className=" font-semibold">7 Days Refunds</div>
                  <div>{detail?.refund === 1 ? 'Yes' : 'No'}</div>
                  {/* <div className=" text-lg font-semibold text-gray-400">Brand</div>
                  <div></div> */}
                  <div className=" font-semibold">Brand</div>
                  <div>{detail?.brand?.title}</div>
                  <div className=" font-semibold">Model</div>
                  <div>{detail?.model_number}</div>
                  <div className=" font-semibold">Color</div>
                  <div>{detail?.color?.title}</div>
                  <div className=" font-semibold">Size</div>
                  <div>{detail?.size?.title}</div>
                  <div className=" font-semibold">Year</div>
                  <div>{detail?.year?.title}</div>
                  <div className=" font-semibold">Location</div>
                  <div>{detail?.address?.states_text + ', ' + detail?.address?.local_government_text + ', ' + detail?.address?.address}</div>
                </div>
              )}
            </div>

            <div className="lg:hidden">
              <DescComponent />
            </div>

            <div className="lg:hidden">
              <ShareComponent />
            </div>

            <div className="lg:hidden">
              <SecurityComponent />
            </div>

            <div className="lg:hidden">
              <ProfileComponent />
            </div>           

            {detail?.comment_ratings.length > 0 ? (
              <div className="lg:hidden">
                <RatingCommentComponent />
              </div>
            ): null}

            <div className="lg:hidden">
              {DiscussionComponent()}
            </div> 

            <div className="hidden lg:block">
              <ShareComponent />
            </div>

            <div className="hidden lg:block">
              <SecurityComponent />
            </div>
          </div>
        </div>
      </div>

      <hr className=" my-4 lg:my-12" />

      <div className=" flex w-full justify-center">
        <div className=" w-[1300px] flex-col flex space-y-8">
          {listRecomended.length > 0 && <div className=" text-xl font-semibold px-2 lg:px-0">Recomended For You</div>}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8 px-2 lg:px-0 pb-16">
            {!isLoading && listRecomended.map((row, index) => (
              <ListAdItem
                {...row}
                key={row.id}
                index={index}
                onFavorite={handleFavorite}
                onMessage={handleMessage}
              />
            ))}
          </div>
        </div>
      </div>

      {showModalZoom ? (
        <div
          // onClick={() => setShowModalZoom(false)}
          className={` top-0 fixed bg-black/90 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
        >
          <div 
            // onClick={(e) => e.stopPropagation()}
            className="relative md:w-[65%] mx-auto"
          >            
            {/* <div className=" relative flex flex-col  outline-none focus:outline-none"> */}
              <div className=" flex flex-col justify-center items-center bg-white rounded">
                <img
                  alt={detail?.title}
                  src={imageActive.url}
                  className=" w-auto lg:h-[750px] duration-300"
                />  
                <div className=" absolute flex items-center text-white text-xs bottom-5 left-5 p-1 px-2 rounded bg-black/50">
                  <FaCamera className=" mr-2" /> {imageActive.index+1}/{detail?.photos.length}
                </div>  
              </div>                
            {/* </div> */}
          </div>
          <LiaTimesCircleSolid 
            className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer text-white"
            onClick={() => setShowModalZoom(false)}
          />
          <div className="  bg-gray-400 top-[45%] p-2 rounded-full absolute left-3 lg:left-8 cursor-pointer hover:bg-opacity-75 duration-300" >
            <FaChevronLeft 
              className=" text-xl lg:text-3xl text-white" 
              // className=" text-xl lg:text-3xl text-white absolute left-3 lg:left-8 cursor-pointer hover:text-opacity-75" 
              onClick={() => {
                let idx = imageActive.index-1
                if (idx !== -1) {
                  setImageActive({
                    index: idx,
                    url: detail?.photos[idx].url
                  })                  
                }
              }}
            />
          </div>
          <div className="  bg-gray-400 top-[45%] p-2 rounded-full  absolute right-3 lg:right-8 cursor-pointer hover:bg-opacity-75 duration-300" >          
            <FaChevronRight 
              className=" text-xl lg:text-3xl text-white " 
              onClick={() => {
                let idx = imageActive.index+1
                if (idx <= detail?.photos.length-1) {
                  setImageActive({
                    index: idx,
                    url: detail?.photos[idx].url
                  })                  
                }
              }}
            />
          </div>
          <div className=" flex flex-row items-center space-x-1 lg:space-x-2 absolute bottom-4">
            {!isLoading && detail?.photos?.map((row, index) => (
              <img
                key={index}
                alt={detail?.title + index}
                src={row.url}
                className={`w-12 h-8 lg:w-24 lg:h-16 object-cover rounded cursor-pointer ${imageActive.url === row.url ? 'border border-white' : ''} `} 
                onClick={() => {
                  // setVideoActive(null)
                  setImageActive({
                    index: index,
                    url: row.url
                  })                  
                }}
              />
            ))}                        
          </div>
        </div>
      ):null}

      {showModalBid ? (
        <ModalBid
          {...detail}
          isSuccessBid={isSuccessBid}
          bidPrice={bidPrice}
          onChange={setBidPrice}
          onBid={handleBid}
          isLoading={isLoadingOnBid}
          onShowModal={() => setShowModalBid(false)}
        />
      ) : null}

      {showModalBuyNow ? (
        <ModalBuyNow
          selected={{
            // price_text: detail?.buynow_price_text,
            // price_text: '₦'+((qty * parseInt(detail?.buynow_price)) + parseInt(detail?.shipping_price) + (paymentType === 3 ? 0 : parseInt(bankTf))),
            price_text: '₦'+((qty * parseInt(detail?.buynow_price)) + parseInt(detail?.shipping_price)),
            title: detail?.title,
            qty: qty,
            total: qty * parseInt(detail?.buynow_price),
            shipping: parseInt(detail?.shipping_price),
            shipping_status: parseInt(detail?.shipping_status),
            bank_tf: paymentType === 3 ? 0 : parseInt(bankTf),
            // sub_total: (qty * parseInt(detail?.buynow_price)) + parseInt(detail?.shipping_price) + (paymentType === 3 ? 0 : parseInt(bankTf))
            sub_total: (qty * parseInt(detail?.buynow_price)) + parseInt(detail?.shipping_price)
          }}
          paymentType={paymentType}
          onSelectPayment={setPaymentType}
          isLoading={isLoadingBuyNow}
          onBuyNow={handleBuyNow}
          onShowModal={() => setShowModalBuyNow(false)}
        />
      ) : null}

      {showModalBC ? (
        <ModalBuyCredit
          // listTopUp={listTopUp}
          // onSelectCP={handleSelectCP}
          isType={2} // For detail ads
          depositAmount={detail?.deposit_amount_text}
          currentPrice={user?.credit}
          neededPrice={detail?.deposit_amount - user?.credit}
          payPrice={payPrice}
          feePrice={feePrice}
          onChange={setPayPrice}
          onBuyNow={handleBuyNowCredit}
          onShowModalBC={setShowModalBC}
        />
      ) : null}

      {showModalCP ? (
        <ModalCreditPurchase
          // selectCP={selectCP}
          payPrice={payPrice}
          onTransactionPay={handleTransactionPay}
          onShowModalCP={setShowModalCP}
          isLoading={isLoadingPay}
        />
      ) : null}

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null}

      {showModal ? (
        <ModalLoginRegister
          type={1}
          onShowModal={() => setShowModal(false)}
        />
      ) : null}      

    </Layout>
  )
}

export default AdDetail