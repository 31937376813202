import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { HiCursorClick } from "react-icons/hi";
import { HiOutlineReceiptPercent, HiOutlineArrowsUpDown } from "react-icons/hi2";
// import { LuContact } from "react-icons/lu";
import { BiCommentDetail, BiUserCircle, } from "react-icons/bi";
import { 
  TbBookDownload, 
  TbFileCheck,
  TbMessageDots,
  TbUsersGroup,
  TbHeart,
} from "react-icons/tb";
import Swal from "sweetalert2";

import { clearAuth, setUser } from "../redux/authSlice";
import { FaChevronDown, FaEdit } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { PiSignOutBold } from "react-icons/pi";
import { clearFilter } from "../redux/filterSlice";


const SidebarLayout = ({children, tabActive, setTabActive, onShowModalBank, listMembership}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const user = useSelector(state => state.auth.user)
  const [showMenu, setShowMenu] = useState(false)

  const handleTab = (name) => {
    setTabActive(name)
    navigate(`/my-account?tabs=${name}`)
    setTimeout(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, 500);
  }

  const listMenu = [
    {
      id: 1,
      title: 'Dashboard',
      name: 'dashboard',
      desc: `Here you'll find your account overview, recent account notices and alerts.`,
      badge: '',
      icon: <HiCursorClick className=" text-xl lg:text-4xl"/>
    },
    {
      id: 2,
      title: 'Ad Plans',
      name: 'membership',
      desc: `Ad plans options, account upgrades and user add-ons can be found here.`,
      badge: '',
      icon: <BiUserCircle className=" text-xl lg:text-4xl"/>
    },
    {
      id: 3,
      title: 'My Wishlist',
      name: 'wishlist',
      desc: `Find items you saved for later purchase.`,
      badge: user?.wishlist_count,
      icon: <TbHeart className=" text-xl lg:text-4xl"/>
    },
    {
      id: 4,
      title: 'My Ads',
      name: 'listings',
      desc: `Here you can view, manage and upgrade your existing ads.`,
      badge: user?.ads_count,
      icon: <TbFileCheck className=" text-xl lg:text-4xl"/>
    },
    {
      id: 5,
      title: 'My Auctions & Bids',
      name: 'offers',
      desc: `Any bids you have made or recieved can be found here.`,
      badge: user?.bid_count,
      icon: <TbBookDownload className=" text-xl lg:text-4xl"/>
    },
    {
      id: 6,
      title: 'Manage Orders',
      name: 'transactions',
      desc: `View and update the status of your purchases and sales.`,
      badge: '',
      icon: <HiOutlineArrowsUpDown className=" text-xl lg:text-4xl"/>
    },
    {
      id: 7,
      title: 'Balance & Invoices',
      name: 'orders',
      desc: `View your account balance, invoices and recent withdrawals here.`,
      badge: user?.credit_text,
      icon: <HiOutlineReceiptPercent className=" text-xl lg:text-4xl"/>
    },
    {
      id: 8,
      title: 'Messages',
      name: 'messages',
      desc: `Here you'll find your account overview, recent account notices and alerts.`,
      badge: '',
      icon: <BiCommentDetail className=" text-xl lg:text-4xl"/>
    },
    {
      id: 9,
      title: 'Discussion Room',
      name: 'comments',
      desc: `Build a friends list and manage your website connections here.`,
      badge: '',
      icon: <TbMessageDots className=" text-xl lg:text-4xl"/>
    },
    {
      id: 10,
      title: 'Friends',
      name: 'friends',
      desc: `Build a friends list and manage your website connections here.`,
      badge: user?.friend_count,
      icon: <TbUsersGroup className=" text-xl lg:text-4xl"/>
    },
    // {
    //   id: 10,
    //   title: 'Settings',
    //   name: 'details',
    //   desc: `Contact details, email, password and other account details can be found here.`,
    //   badge: '',
    //   icon: <LuContact className=" text-xl lg:text-4xl"/>
    // },
  ]

  const [windowWidth, setWindowWidth] = useState(0)
  function detectWindowSize () {
    // console.log("detectWindowSize", window.innerWidth);
    setWindowWidth(window.innerWidth)
  }  
  
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.onresize = detectWindowSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.onresize])

  return (
    <>
      <div className=" flex justify-center w-full mt-8  px-4 lg:px-0">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center w-[1300px] border rounded bg-white p-4 lg:px-8 justify-between">
          <div className=" flex items-center">
            <img 
              alt="avatar" 
              src={user?.photo !== '' ? user?.photo : process.env.PUBLIC_URL+'/assets/Default.png'}
              className=" w-12 lg:w-20 h-12 lg:h-20 rounded-full"
            />
            <div className=" ml-4">
              <div className=" lg:text-2xl font-semibold">{user?.full_name}</div>
              <div className=" text-xs lg:text-sm text-gray-400">{user?.email}</div>
            </div>
          </div>
          <div className=" space-x-2 lg:space-x-4 flex items-center">
            {/* <button className=" p-2 px-4 text-white border rounded focus:outline bg-[#B38C32] border-[#B38C32] hover:bg-white hover:text-[#B38C32] duration-300">Boost Me</button> */}
            {/* <Link to={'/add-listing'} className=" p-2 px-4 text-white border rounded focus:outline bg-[#F8BA09] border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300">Create Ads</Link> */}
            <div 
              className=" text-xs lg:text-lg flex items-center cursor-pointer p-2 md:px-4 text-white border rounded focus:outline bg-[#F8BA09] border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              onClick={() => {
                if (user?.check_bank_information === 1) {
                  onShowModalBank()
                }else{
                  // if (user.membership.length > 0) {
                  if (listMembership.length > 0) {
                    dispatch(setUser({...user, adTemp: null}))
                    setTimeout(() => {
                      navigate('/add-listing')
                    }, 100);
                  }else{
                    Swal.fire({
                      icon: 'warning',
                      title: 'You have no active ad plan(s)',
                      text: `Purchase an ad plan to start posting ads.`,
                      showCancelButton: true,
                      confirmButtonText: 'Get ad plan',
                      confirmButtonColor: '#B38C32',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        // navigate('/ad-pricings')
                        handleTab('membership')
                        navigate('/my-account?tabs=membership')
                      }
                    })
                  }
                }
              }}
            >
              <FaEdit />
              <span className=" lg:block ml-2">Post Ad</span>
            </div>
            <button 
              className=" text-xs lg:text-lg flex items-center p-2 md:px-4 text-black border rounded focus:outline bg-white border-black hover:bg-gray-400 hover:border-gray-400 hover:text-white duration-300 focus:outline-none"
              onClick={() => {
                handleTab('details')
                setShowMenu(false)
              }}
            >
              <FaUserGear />
              <span className=" lg:block ml-2">Settings</span>              
            </button>
            <button 
              className=" text-xs lg:text-lg flex items-center p-2 md:px-4 text-black border rounded focus:outline bg-white border-black hover:bg-gray-400 hover:border-gray-400 hover:text-white duration-300 focus:outline-none"
              onClick={() => {
                // dispatch(clearUser())
                dispatch(clearAuth())
                dispatch(clearFilter())
                navigate('/')
              }}
            >
              <PiSignOutBold />
              <span className=" lg:block ml-2">Logout</span>   
            </button>
          </div>
        </div>
      </div>  

      <div 
        className=" px-4 lg:hidden p-4 rounded border bg-white mx-4 mt-4 flex items-center justify-between cursor-pointer"
        onClick={() => setShowMenu(!showMenu)}
      >
        <span className="text-sm lg:text-base font-medium">Account Options</span>
        <FaChevronDown />
      </div>

      <div className=" flex justify-center w-full mt-4 lg:mt-8 lg:px-0">
        {/* <div className={` border min-[320px]:w-[290px] min-[375px]:w-[350px] min-[425px]:w-full lg:w-[1300px] min-[425px]:mx-4 rounded bg-white`}> */}
        <div style={{ width: windowWidth >= 1300 ? '1300px' : windowWidth*0.935+'px'}} className={` border lg:w-[1300px] flex flex-col lg:flex-row rounded space-y-4 lg:space-y-0 mx-4 md:mx-0 bg-white`}>
          {/* <div className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0"> */}
            <div className={` lg:w-[30%] border-r ${showMenu ? 'lg:block' : 'hidden lg:block'}`}>
              {listMenu?.map(row => (             
                <div
                  key={row?.id}
                  onClick={() => {
                    handleTab(row?.name)
                    setShowMenu(false)
                  }} 
                  className={` flex space-x-4 p-4 px-6 border-b items-start cursor-pointer hover:bg-gray-100 ${tabActive === row?.name ? 'bg-gray-100' : 'bg-white'} `}
                >
                  {row?.icon}
                  <div className=" flex flex-col space-y-2 justify-center w-80">
                    <div className=" lg:text-xl font-semibold items-center flex">
                      <span>{row?.title}</span>
                      {row?.badge !== 0 && row?.badge !== '' ? 
                        <span className=" text-sm bg-red-600 px-1 rounded ml-2 text-white">{row?.badge}</span> 
                        : null}
                    </div>
                    <div className=" text-xs lg:text-sm text-gray-500">{row?.desc}</div>
                  </div>
                </div>
              ))}
            </div>
            {children}
          {/* </div> */}
        </div>
      </div>
    </>
  )
}

export default SidebarLayout