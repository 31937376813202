import React from 'react'
import { PiSmileySadBold } from 'react-icons/pi'

const NothingFoundItem = () => {
  return (
    <div className=" mt-20  text-center flex justify-center flex-col space-y-2 items-center">
      <PiSmileySadBold className=" text-8xl text-gray-200" />
      <div className="text-2xl font-medium">
        Nothing Found
      </div>
    </div>
  )
}

export default NothingFoundItem