import React from 'react'
import { PiStarFill } from "react-icons/pi"

const AverageRating = ({rate, size, onClick}) => {
  let starActive = []
  let starInactive = []

  if (rate > 0) {
    starActive = Array.from({ length: rate }, (_, index) => index + 1);  
    starInactive = Array.from({ length: 5 - rate }, (_, index) => index + 1);  
  }
 
  return (
    <>
      {starActive.map((r, i) =>  
        <PiStarFill 
          key={r} 
          className={`${size} text-yellow-500 ${onClick !== undefined && 'cursor-pointer'}`} 
          onClick={() => onClick !== undefined && onClick(i+1)}
        />
      )}
      {starInactive.map((r, i) => 
        <PiStarFill 
          key={r} 
          className={`${size} ${onClick !== undefined && 'cursor-pointer'}`}
          onClick={() => onClick !== undefined && onClick(i+rate+1)}
        /> 
      )}
    </>
  )
}

export default AverageRating