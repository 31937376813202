import React, { useEffect, useState, useRef } from 'react'
import Layout from "./Layout"
import { PiMapPinFill, PiPhoneFill, PiEnvelopeSimpleBold } from 'react-icons/pi'
import { FaWhatsapp } from 'react-icons/fa'
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "axios"
import { LoadingThreeDots } from "../components"
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  useEffect(() => {
    document.title = 'Contact Us | Paseero'; 
    window.scrollTo(0, 0)
    setTimeout(() => {
      setLoad(true)
    }, 1500);
  }, [])

  const reCaptchaRef = useRef();
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [messages, setMessages] = useState('')
  const [isAcc, setAcc] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoad, setLoad] = useState(false)
  const [valueCaptcha, setValueCaptcha] = useState(null)

  const handleSend = () => {
    if (!isAcc) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please check privacy policy! ',
        confirmButtonColor: '#B38C32'
      })
    }

    if (valueCaptcha === null) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please verify the reCAPTCHA!',
        confirmButtonColor: '#B38C32'
      })
    } 
    // else {
    //   // make form submission
    //   alert("Form submission successful!");
    // }

    setLoading(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/master/contact_us';
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      full_name: fullname,
      email: email,
      message: messages,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          // const { messages } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'Success',
            // text: messages,
            confirmButtonColor: '#B38C32'
          })
          setEmail('')
          setFullname('')
          setMessages('')
          setAcc(false)
          setLoading(false)       
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoading(false)
      });
  }

  const handleRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setValueCaptcha(value)
  }

  const asyncScriptOnLoad = async () => {
    console.log("scriptLoad - reCaptcha Ref-", reCaptchaRef);
  }
  
  return (
    <Layout>
      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">Contact Us</div>
          <div className=" mt-2 text-sm lg:text-xl text-[#212529]">Let's Talk</div>
        </div>
      </div>

      <div className=" flex justify-center w-full lg:mt-12 py-8">
        <div className="flex flex-col lg:flex-row w-[1300px] space-y-12 lg:space-y-0 lg:space-x-12 px-4 lg:px-0">
          <div className="lg:w-3/5 space-y-4">
            <div className=" flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-4">
              <input 
                className=" p-2 px-4 text-sm lg:text-base border-2 w-full rounded focus:outline-none" 
                placeholder="Full Name" 
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
              <input 
                className=" p-2 px-4 text-sm lg:text-base border-2 w-full rounded focus:outline-none" 
                placeholder="Email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <textarea 
              className=" p-2 px-4 text-sm lg:text-base border-2 w-full rounded focus:outline-none" 
              rows={7} 
              placeholder="Your messages ..."
              value={messages}
              onChange={(e) => setMessages(e.target.value)}
            />
            <div className=" flex items-center space-x-2">
              <input 
                id="checkbox-accept"  
                type={'checkbox'} 
                checked={isAcc}
                onChange={() => setAcc(!isAcc)}
              />
              <label htmlFor="checkbox-accept" className=" text-sm lg:text-base cursor-pointer">I accept the </label>
              <Link to={'/privacy-policy'} className=" font-medium text-[#B38C32] hover:underline cursor-pointer">privacy policy</Link>
            </div>
            {isLoad && (
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={handleRecaptchaChange}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
            )}
            <button 
              className=" p-3 w-full justify-center flex text-sm lg:text-base bg-[#B38C32] text-white rounded border border-[#B38C32] uppercase tracking-wide hover:bg-white hover:text-[#B38C32] duration-300"
              onClick={() => !isLoading && handleSend()}
            >
              {isLoading ?
                <LoadingThreeDots size={'20'} />
                : 'Send'
              }             
            </button>
          </div>
          <div className=" lg:w-2/5 space-y-7">
            <div className=" font-bold text-xl lg:text-3xl">Do you have questions?</div>
            <div className=" lg:text-xl">We are always happy to receive your feedback</div>
            <div className=" flex-row flex space-x-4">
              <PiMapPinFill className=" text-2xl text-[#B38C32] mt-1"/>
              <div className=" space-y-1">
                <div className=" font-bold lg:text-xl ">Address</div>
                <div className=" lg:text-xl">Nigeria</div>
              </div>
            </div>
            <div className=" flex-row flex space-x-4">
              <PiPhoneFill className=" text-2xl text-[#B38C32] mt-1"/>
              <div className=" space-y-1">
                <div  className=" font-bold lg:text-xl ">Phone/Whatsapp</div>
                <div className=" lg:text-xl">+2348144966384</div>
                <a 
                  className=" bg-[#36B421] rounded text-sm lg:text-base text-white p-2 px-4 flex items-center space-x-2"
                  href="https://wa.me/2348144966384?text=Hello"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp className=" lg:text-xl text-white mr-2" />
                  Contact via WhatsApp
                </a>
              </div>
            </div>
            <div className=" flex-row flex space-x-4">
              <PiEnvelopeSimpleBold className=" text-2xl text-[#B38C32] mt-1"/>
              <div className=" space-y-1">
                <div className=" font-bold lg:text-xl ">Email</div>
                <div className=" lg:text-xl">contact@paseero.ng</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      

      <hr className=" my-12" />
    </Layout>
  )
}

export default ContactUs