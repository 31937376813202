import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: [],
  sizes: [],
  years: [],
  colors: [],
  brands: [],
  features: [],
  city: [],
  code: [],
  showNav: false
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setCategory(state, action) {
      state.category = action.payload;
    },
    setSizes(state, action) {
      state.sizes = action.payload;
    },
    setYears(state, action) {
      state.years = action.payload;
    },
    setColors(state, action) {
      state.colors = action.payload;
    },
    setBrands(state, action) {
      state.brands = action.payload;
    },
    setFeatures(state, action) {
      state.features = action.payload;
    },
    setCity(state, action) {
      state.city = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    clearCategory(state) {
      state.category = [];
    },
    clearFilter(state) {
      state.category = [];
      state.sizes = [];
      state.years = [];
      state.colors = [];
      state.brands = [];
      state.features = [];
      state.city = [];
      state.code = [];
    },
    setShowNav(state, action) {
      state.showNav = action.payload
    }
  },
});

export const { 
  setCategory,
  setSizes,
  setYears,
  setColors,
  setBrands,
  setFeatures,
  setCity,
  setCode,
  clearCategory,
  clearFilter,
  setShowNav,
} = filterSlice.actions;

export default filterSlice.reducer;