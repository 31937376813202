import React, { useEffect } from 'react'
import { IoMailOpenOutline } from "react-icons/io5";
import Layout from "./Layout"

const CreditSystem = () => {
  useEffect(() => {
    document.title = 'Credit System | Paseero'
    window.scrollTo(0, 0)
  }, [])
  
  const auctions = [1,2,3,4,5]

  const discounted = [
    {
      id: 1,
      pays: 'N90',
      receives: `N100`
    },
    {
      id: 2,
      pays: 'N350',
      receives: `N400`
    },
    {
      id: 3,
      pays: 'N900',
      receives: `N1000`
    },
    {
      id: 4,
      pays: 'N2300',
      receives: `N2500`
    },
    {
      id: 5,
      pays: 'N4500',
      receives: `N5000`
    },
  ]

  return (
    <Layout>
      <div className=" flex justify-center w-full border mt-12 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lgtext-3xl text-[#212529]">Credit System</div>
          {/* <div className=" mt-2 text-xl text-[#212529]">Let's Talk</div> */}
        </div>
      </div>

      <div className=" flex justify-center w-full mt-12">
        <div className="flex flex-row w-[1300px] lg:space-x-12 px-4 lg:px-0">
          <div className=" hidden lg:block w-1/4 space-y-8">
            <div className=" bg-white border border-gray-300 rounded flex items-center text-center flex-col space-y-6 p-4">
              <div>
                <IoMailOpenOutline className=" text-9xl" />
              </div>
              <div className=" text-black text-xl font-semibold">
                Join Our Newsletter
              </div>
              <div className=" text-[#7F7F7F] font-light tracking-wide text-sm">
                Join our subscribers list to get the latest <br />news, updates and special offers <br />delivered directly in your inbox.
              </div>
              <div className=" items-center flex">
                <input className=" h-12 px-4 w-60 bg-white placeholder-grey border border-gray-300 rounded-l focus:outline-none" placeholder="Email address here.."/>
                <button className=" h-12 w-20 font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-l-[#B38C32] hover:border-white duration-300">Join</button>
              </div>
            </div>

            <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  New Auctions
                </div>
              </div>                
              <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                {auctions.map((row) => (                   
                  <div key={row} className=" flex flex-row items-start">
                    <img
                      alt="auctions" 
                      src="https://paseero.com/wp-content/uploads/2023/06/pexels-mcbarth™-obeya-3172830-300x200.jpg" 
                      className=" rounded w-20" 
                    />
                    <div className=" flex flex-col ml-4">
                      <div className=" text-black font-medium truncate w-56">Lorem ipsum dolor sit amet lorem ipsum</div>
                      <div className=" text-sm text-[#7F7F7F]">No category</div>
                    </div>
                  </div>
                ))}
              </div>                
            </div>              

            <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  Popular Auctions
                </div>
              </div>                
              <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                {auctions.map((row) => (                   
                  <div key={row} className=" flex flex-row items-start">
                    <img 
                      alt="auctions"
                      src="https://paseero.com/wp-content/uploads/2023/06/pexels-mcbarth™-obeya-3172830-300x200.jpg" 
                      className=" rounded w-20" 
                    />
                    <div className=" flex flex-col ml-4">
                      <div className=" text-black font-medium truncate w-56">Lorem ipsum dolor sit amet lorem ipsum</div>
                      <div className=" text-sm text-[#7F7F7F]">No category</div>
                    </div>
                  </div>
                ))}
              </div> 
            </div>

            <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  Latest News
                </div>
              </div>                
              <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                {auctions.map((row) => (                   
                  <div key={row} className=" flex flex-row items-start">
                    <img
                      alt="auctions" 
                      src="https://paseero.com/wp-content/uploads/2023/06/pexels-mcbarth™-obeya-3172830-300x200.jpg" 
                      className=" rounded w-20" 
                    />
                    <div className=" flex flex-col ml-4">
                      <div className=" text-black font-medium truncate w-56">Lorem ipsum dolor sit amet lorem ipsum</div>
                      <div className=" text-sm text-[#7F7F7F]">No category</div>
                    </div>
                  </div>
                ))}
              </div>                
            </div>

          </div>
          <div className=" lg:w-3/4">
            <div className=" flex flex-col lg:flex-row lg:justify-between items-start lg:space-x-12 space-y-4 lg:space-y-0">            
              <img 
                alt="blog-img"
                src="https://paseero.com/wp-content/uploads/2023/03/pexels-monstera-6998462-683x1024.jpg"
                className=" lg:w-1/2"
              />
              <div className=" lg:w-1/2 flex flex-col space-y-4">
                <div className=" text-black lg:text-2xl font-bold">Save money - buy discounted credits</div>
                <p className=" text-black text-sm lg:text-base leading-7 tracking-wide text-justify">
                  The credit system on paseero.ng allows you to buy credits at discounted prices to pay for any service offered on the platform. In order to buy credits, please login into your account after completing the registration process. In your account dashboard, click the ‘balance and invoices’ tab. Subsequently, you will find the ‘buy credit’ button located at the top left of the page. 
                </p>
                <div className=" text-black font-medium pb-8">The discounted prices are shown below.</div>
                <table className=" table-fixed">
                  <thead>
                    <tr className=" text-center border">
                      <th className=" text-lg p-3 text-[#B38C32] font-medium">User Pays</th>
                      <th className=" text-lg p-3 text-[#B38C32] font-medium">User Receives</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discounted.map(row => (
                      <tr key={row.id} className=" text-center">
                        <td className=" py-2 border">{row.pays}</td>
                        <td className=" py-2 border">{row.receives}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      

      <hr className=" my-12" />
    </Layout>
  )
}

export default CreditSystem