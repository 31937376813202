import axios from "axios";
import React, { useEffect, useState } from 'react'
import { 
  IoSearch,
  IoFolderOpenOutline,
  IoTimeOutline,
  IoLogoWhatsapp,
  IoLogoFacebook,
  IoLogoTwitter,
  IoCopy
  // IoLogoPinterest,
  // IoLogoLinkedin,
  // IoChatbubblesOutline,
} from "react-icons/io5";
import { FaTelegram } from 'react-icons/fa'
import { useNavigate, useParams } from "react-router-dom";
import { 
  FacebookShareButton, 
  TelegramShareButton, 
  TwitterShareButton, 
  WhatsappShareButton 
} from "react-share";

import { Dangerously, LoadingThreeDots, NothingFoundItem } from "../components";
import Layout from "./Layout"
import CopyToClipboard from "react-copy-to-clipboard";

const Blog = () => {
  const navigate = useNavigate()
  const params = useParams();

  const [isLoading, setLoading] = useState(true)
  const [listBlog, setListBlog] = useState([])
  const [listCategory, setListCategory] = useState([])
  const [textSearch, setTextSearch] = useState('')
  // const [indexBlog, setIndexBlog] = useState(null)
  const [detailBlog, setDetailBlog] = useState(null)
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    document.title = 'Blog | Paseero'; 
    window.scrollTo(0, 0)

    // let urlSearchString = window.location.search;
    // let params = new URLSearchParams(urlSearchString);

    const fetchAll = () => {
      Promise.all([
        getApi('BLOG', '/api/master/blog'),
        params.id !== undefined && getApi('DETAIL', '/api/master/blog/'+params.id)
      ])
      .then(() => {
        // setTimeout(() => {
          // setLoading(false)            
        // }, 500);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          if (type === 'BLOG') {
            setListBlog(results.blog)
            setListCategory(results.category)
            setLoading(false)
          }else{
            setDetailBlog(results)
            setLoading(false)
          }
          setTimeout(() => {
            setLoading(false)            
          }, 500);
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  // async function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }

  // const handleCopyClick = () => {
  //   // Asynchronously call copyTextToClipboard
  //   copyTextToClipboard(window.location.href)
  //     .then(() => {
  //       // If successful, update the isCopied state value
  //       setIsCopied(true);
  //       setTimeout(() => {
  //         setIsCopied(false);
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  useEffect(() => {
    if (isCopied) {      
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  }, [isCopied])  

  const categoryStr = (data) => data?.map(r => ' '+r?.category?.title).toString()

  return (
    <Layout>
      <div className=" flex justify-center w-full border-b mt-2 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">{params?.id !== undefined ? 'Blog Detail' : 'Blog'}</div>
          {/* <div className=" mt-2 text-xl text-[#212529]">Let's Talk</div> */}
        </div>
      </div>

      <div className=" flex justify-center w-full mt-6 lg:mt-12">
        <div className="flex flex-row w-[1300px] lg:space-x-12 px-4 lg:px-0">
          {isLoading ? (
            <div className=" lg:w-3/4 flex justify-center">
              <LoadingThreeDots
                size={'50'}
                color={"#B38C32"}
              />
            </div>
          ): null}

          {!isLoading && detailBlog !== null ? (
            <div className=" lg:w-3/4 border bg-white">
              <img 
                alt={`img-${detailBlog?.title}`} 
                src={detailBlog?.main_image}
                className=" w-full rounded-t"
              />
              <div className=" p-4 lg:p-8 lg:py-12 space-y-8">
                <div className=" text-lg lg:text-4xl font-medium">
                  {detailBlog?.title}
                </div>
                <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 w-full border-t border-b border-gray-300 lg:py-4 justify-between pl-4 items-center">
                  <div className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center py-3 lg:py-0">
                    <div className=" text-center lg:border-r pr-4 border-gray-300">
                      <div className=" font-bold text-2xl tracking-wider">{detailBlog?.created_at.substring(0, 2)}</div>
                      <div className=" text-sm uppercase">{detailBlog?.created_at.substring(2)}</div>
                    </div>
                    {/* <div className="lg:ml-8 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-3">
                      <WhatsappShareButton
                        url={window.location.href}
                        title={detailBlog?.title}
                        separator=" "
                      >
                        <div className=" flex items-center p-1 px-3 rounded-md bg-[#4DC358] text-white space-x-2 border border-[#4DC358] hover:bg-white hover:text-[#4DC358] duration-300 cursor-pointer">
                          <IoLogoWhatsapp className=" lg:text-base" />
                          <span className=" text-sm ">Share</span>
                        </div>
                      </WhatsappShareButton>
                      <TelegramShareButton              
                        url={window.location.href}
                        title={detailBlog?.title}
                      >
                        <div className=" flex items-center p-1 px-3 rounded-md bg-[#37AEE2] text-white space-x-2 border border-[#37AEE2] hover:bg-white hover:text-[#37AEE2] duration-300 cursor-pointer">
                          <FaTelegram className=" lg:text-base" />
                          <span className=" text-sm ">Share</span>
                        </div>
                      </TelegramShareButton>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={detailBlog?.title}
                        hashtag={'#paseero'}
                      >                        
                        <div className=" flex items-center p-1 px-3 rounded-md bg-[#4C65A8] text-white space-x-2 border border-[#4C65A8] hover:bg-white hover:text-[#4C65A8] duration-300 cursor-pointer">
                          <IoLogoFacebook className=" lg:text-base" />
                          <span className=" text-sm ">Share</span>
                        </div>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={window.location.href}
                        title={detailBlog?.title}
                        hashtags={['paseero']}
                      >          
                        <div className=" flex items-center p-1 px-3 rounded-md bg-[#41A1F5] text-white space-x-2 border border-[#41A1F5] hover:bg-white hover:text-[#41A1F5] duration-300 cursor-pointer">
                          <IoLogoTwitter className=" lg:text-base" />
                          <span className=" text-sm ">Share</span>
                        </div>
                      </TwitterShareButton>
                      <div 
                        className=" flex items-center p-1 px-3 rounded-md bg-[#B38C32] text-white space-x-2 border border-[#B38C32] hover:bg-white hover:text-[#B38C32] duration-300 cursor-pointer"
                        onClick={handleCopyClick}
                      >
                        <IoCopy className=" lg:text-base" />
                        <span className=" text-sm ">{isCopied ? 'Copied!' : 'Copy'}</span>
                      </div>
                    </div> */}
                    <div className=" lg:ml-4 space-y-2">
                      <div className=" lg:text-lg text-center lg:text-left font-medium"> Share this link via</div>
                      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 md:space-x-3">
                        <div className=" flex flex-row lg:space-x-2 justify-between items-center">                        
                          <WhatsappShareButton
                            url={window.location.href}
                            title={detailBlog?.title}
                            separator=" "
                          >
                            <div className=" flex items-center p-3 rounded-full text-[#4DC358] bg-white space-x-2 border border-[#4DC358] hover:text-white hover:bg-[#4DC358] duration-300 cursor-pointer">
                              <IoLogoWhatsapp className=" lg:text-xl" />
                            </div>
                          </WhatsappShareButton>
                          <TelegramShareButton              
                            url={window.location.href}
                            title={detailBlog?.title}
                          >
                            <div className=" flex items-center p-3 rounded-full text-[#37AEE2] bg-white space-x-2 border border-[#37AEE2] hover:text-white hover:bg-[#37AEE2] duration-300 cursor-pointer">
                              <FaTelegram className=" lg:text-xl" />
                            </div>
                          </TelegramShareButton>
                          <FacebookShareButton
                            url={window.location.href}
                            quote={detailBlog?.title}
                            hashtag={'#paseero'}
                          >                        
                            <div className=" flex items-center p-3 rounded-full text-[#4C65A8] bg-white space-x-2 border border-[#4C65A8] hover:text-white hover:bg-[#4C65A8] duration-300 cursor-pointer">
                              <IoLogoFacebook className=" lg:text-xl" />
                            </div>
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={window.location.href}
                            title={detailBlog?.title}
                            hashtags={['paseero']}
                          >          
                            <div className=" flex items-center p-3 rounded-full text-[#41A1F5] bg-white space-x-2 border border-[#41A1F5] hover:text-white hover:bg-[#41A1F5] duration-300 cursor-pointer">
                              <IoLogoTwitter className=" lg:text-xl" />
                            </div>
                          </TwitterShareButton>
                        </div>
                        <div className=" flex items-center">
                          <input
                            className=" p-2 px-4 text-xs lg:text-sm w-full border rounded-l focus:outline-none bg-gray-50 border-r-0"
                            value={window.location.href}
                            readOnly
                          />
                          {/* <button
                            className=" p-2 px-3 text-xs lg:text-sm text-white bg-[#B38C32] border border-[#B38C32] rounded-r hover:text-[#B38C32] hover:bg-white duration-300 flex items-center space-x-2"
                            onClick={handleCopyClick}
                          >
                            <IoCopy />
                            <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                          </button> */}
                          <CopyToClipboard 
                            text={window.location.href}
                            onCopy={() => setIsCopied(true)}
                          >
                            <button className=" p-2 px-3 text-xs lg:text-sm text-white bg-[#B38C32] border border-[#B38C32] rounded-r hover:text-[#B38C32] hover:bg-white duration-300 flex items-center space-x-2" >
                              <IoCopy />
                              <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-gray-400 text-sm lg:text-base ml-3 lg:ml-6 text-center lg:text-left pb-4 lg:pb-0">{categoryStr(detailBlog?.category)}</div>
                </div>
                {/* <img
                  alt={`detail-${detailBlog?.title}`} 
                  src={detailBlog?.main_image}
                  className=" w-full"
                /> */}
                {/* <p className=" text-sm lg:text-xl font-light">
                  {detailBlog?.description}
                </p> */}
                <Dangerously content={detailBlog?.description} />
                {/* <div className=" rounded-md justify-center p-4 w-full bg-[#FAFAFB] flex items-center space-x-2">
                  <IoChatbubblesOutline className=" text-xl" />
                  <span>Please login to post a comment.</span>
                </div> */}
              </div>
            </div>
          ):null}           

          {!isLoading && detailBlog === null && listBlog.length > 0 ? (
            <div className=" lg:w-3/4 space-y-4">
              {/* <div className=" flex flex-col-reverse lg:flex-row lg:space-x-16 items-center p-8 border rounded bg-white border-gray-300 ">
                <div className=" flex flex-col space-y-4 pt-8 lg:pt-0">
                  <div className=" text-xl lg:text-3xl font-medium lg:font-normal cursor-pointer hover:text-gray-400 duration-200">ssFast and Easy Guide for Company Registration in Nigeria</div>
                  <div className=" flex items-center">
                    <IoFolderOpenOutline />
                    <div className=" ml-2 mr-6 text-sm text-gray-400">Business • Corporate</div>
                    <IoTimeOutline />
                    <div className=" ml-2 text-sm text-gray-400">05 May 2023</div>
                  </div>
                  <div className=" text-lg">
                    This post walks you through the process of business registration in Nigeria. We do not ass... &nbsp;
                    <Link to={`/blog/${1}/This-post-walks-you`} className=" text-[#B38C32] underline underline-offset-2 cursor-pointer hover:text-gray-400">read more</Link>
                  </div>
                </div>
                
                <img 
                  alt="blog" 
                  src="https://paseero.com/wp-content/uploads/2023/02/The-National-Arts-Theatre.png" 
                  className=" lg:w-96 rounded"
                />
              </div> */}
              {listBlog?.map((row) => (
                <div 
                  key={row?.id}
                  className=" flex flex-col-reverse lg:flex-row lg:space-x-16 lg:items-center lg:justify-between p-4 lg:p-8 border rounded bg-white border-gray-300"
                >
                  <div className=" flex flex-col space-y-4 pt-4 lg:pt-0">
                    <div className=" text-lg lg:text-3xl font-medium lg:font-normal cursor-pointer hover:text-gray-400 duration-200">
                      {row?.title}
                    </div>
                    <div className=" flex flex-col lg:flex-row space-y-2 lg:space-y-0">
                      <div className=" flex items-center">
                        <IoFolderOpenOutline />
                        {/* <div className=" ml-2 mr-6 text-sm text-gray-400">Business • Corporate</div> */}
                        <div className=" ml-2 mr-6 text-sm text-gray-400">{categoryStr(row?.category)}</div>
                      </div>
                      <div className=" flex items-center">
                        <IoTimeOutline />
                        <div className=" ml-2 text-sm text-gray-400">{row?.created_at}</div>
                      </div>
                    </div>
                    <div className=" text-sm lg:text-lg">
                      {row?.short_desc.slice(0, 100).replace(/&#39;/g, "'")}... &nbsp;
                      <span 
                        // to={`/blog/${row?.id}/${row?.title.replace(/ /g, "-")}`} 
                        className=" text-[#B38C32] underline underline-offset-2 cursor-pointer hover:text-gray-400"
                        onClick={() => {
                          document.title = row?.title+' | Paseero'; 
                          navigate(`/blog/${row?.id}/${row?.title.replace(/ /g, "-")}`)
                          // setIndexBlog(index)
                          setDetailBlog(row)
                        }}
                      >
                        read more
                      </span>
                    </div>
                  </div>
                  
                  <img 
                    alt={`img-${row.title}`} 
                    src={row?.main_image}
                    className=" lg:w-96 rounded"
                  />
                </div>
              ))}
            </div>
          ): null}

          {!isLoading && listBlog.length === 0 ? (
             <div className=" lg:w-3/4">
              <NothingFoundItem />
            </div>
          ): null}
          
          <div className=" hidden lg:block w-1/4 space-y-8">
            <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  Search
                </div>
              </div>                
              <div className=" flex p-4 py-6 border border-t-0 border-gray-300">
                <div className=" w-full relative">
                  <input 
                    className=" h-12 px-4 w-full bg-white placeholder-grey border border-gray-300 rounded focus:outline-none" 
                    placeholder="Keyword.."
                    value={textSearch}
                    onChange={(e) => setTextSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setLoading(true)
                        getApi('BLOG', '/api/master/blog?keyword='+textSearch)
                      }
                    }}
                  />
                  <IoSearch 
                    className=" text-2xl text-[#B38C32] absolute right-3 top-3 cursor-pointer" 
                    onClick={() => {
                      setLoading(true)
                      getApi('BLOG', '/api/master/blog?keyword='+textSearch)
                    }}
                  />
                </div>
              </div>                
            </div>              

            <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  Categories
                </div>
              </div>                
              <div className=" flex flex-col space-y-4 p-4 py-6 border border-t-0 border-gray-300">
                {listCategory?.map(row => (
                   <div 
                    className=" flex items-center justify-between"
                    key={row.id}
                  >
                    <div 
                      className=" text-lg text-gray-400 cursor-pointer hover:underline"
                      onClick={() => {
                        setDetailBlog(null)
                        setLoading(true)
                        getApi('BLOG', '/api/master/blog?category_id='+row?.id)
                      }}
                    >
                      {row?.title}
                    </div>
                    <div className=" w-6 h-6 bg-[#B38C32] text-white rounded-full justify-center items-center text-center">{row?.blog_total}</div>
                  </div>
                ))}
              </div> 
            </div>

            {/* <div>
              <div className=" bg-[#B38C32] rounded-t">
                <div className=" text-white text-2xl font-medium  p-4">
                  Latest News
                </div>
              </div>                
              <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                {auctions.map((row) => (                   
                  <div key={row} className=" flex flex-row items-start">
                    <img
                      alt="auctions" 
                      src="https://paseero.com/wp-content/uploads/2023/06/pexels-mcbarth™-obeya-3172830-300x200.jpg" 
                      className=" rounded w-20" 
                    />
                    <div className=" flex flex-col ml-4">
                      <div className=" text-black font-medium truncate w-56">Lorem ipsum dolor sit amet lorem ipsum</div>
                      <div className=" text-sm text-[#7F7F7F]">No category</div>
                    </div>
                  </div>
                ))}
              </div>                
            </div> */}

          </div>
        </div>
      </div>
      
      

      <hr className=" my-12" />
    </Layout>
  )
}

export default Blog