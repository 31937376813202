import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { 
  PiChatText,
  // PiStarFill,
  PiUserPlusBold,
  PiUserMinusBold,
  PiSealCheckFill
} from 'react-icons/pi'
import { useSelector } from "react-redux";
import axios from "axios";
import { BiBlock, BiCircle } from 'react-icons/bi'
import { PiPhone } from 'react-icons/pi'

import Layout from "./Layout"
import { AverageRating, ListAdItem, LoadingThreeDots, ModalLoginRegister, ModalMessage } from "../components";

const AuthorDetail = () => {
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const params = useParams();

  const [isLoading, setLoading] = useState(true)
  const [author, setAuthor] = useState(null)
  const [msgUser, setMsgUser] = useState(null)
  const [showModalMsg, setShowModalMsg] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isShowNumber, setShowNumber] = useState(false)

  useEffect(() => {
    document.title = `Author Detail | Paseero`;
    window.scrollTo(0, 0)
    // getAuthor(params.userid)
    getApi('AUTHOR', `/api/ad/author?user_id=${params.userid}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userid]) 

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            if(type === 'AUTHOR'){
              setAuthor(results)
              setMsgUser({
                ...results,
                targeted_user_id: results.id,
                targeted_user_photo: results.photo,
                targeted_user_full_name: !!results?.business_name ? results?.business_name : results.first_name+' '+results.last_name,
              })
              
              setLoading(false) 
            }else if(type === 'FOLLOW'){
              setAuthor(prevAuthor => { return { ...prevAuthor, is_following: results.is_following ? 1 : 0}})
              setMsgUser(prevMsgUser => { return { ...prevMsgUser, is_following: results.is_following ? 1 : 0}})
            }else{
              setAuthor(prevAuthor => { return { ...prevAuthor, is_blocked: results.is_blocked ? 1 : 0}})
              setMsgUser(prevMsgUser => { return { ...prevMsgUser, is_blocked: results.is_blocked ? 1 : 0}})
            }
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        setLoading(false)
      });
  }

  // const getAuthor = (userid) => {
  //   const apiUrl = process.env.REACT_APP_API_URL+`/api/ad/author?user_id=${userid}`;
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${auth?.access_token}`,
  //   };
  //   axios
  //     .get(apiUrl, { headers })
  //     .then(response => {
  //       // console.log({response});
  //       if (response.status === 200) {
  //         const { results } = response.data.response
  //         setAuthor(results)
  //         setMsgUser({
  //           ...results,
  //           targeted_user_id: results.id,
  //           targeted_user_photo: results.photo,
  //           targeted_user_full_name: results.first_name+' '+results.last_name,
  //         })
          
  //         setLoading(false)            
  //       }else{
  //         // Do something else
  //       }        
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }

  const handleFavorite = (index, id) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }
    
    const apiUrl = process.env.REACT_APP_API_URL+'/api/user/wishlist/toogle';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {          
          const { results } = response.data.response
          let list = [...author?.ad]
          list[index] = {
            ...list[index] = {
              ...list[index],
              is_favorite: results.is_favorite,
            }
          }
          setAuthor(prevState => {
            return {
              ...prevState,
              ad: list
            }
          })          
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
      });    
  }

  const handleFollow = (id) => {
    getApi('FOLLOW', '/api/auth/user/following?user_id='+id)
  }

  const handleBlocked = (id) => {
    getApi('BLOCKED', '/api/auth/user/block?user_id='+id)
  }

  const handleMessage = (item) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    // setMsgUser({
    //   ...item,
    //   targeted_user_id: item?.id,
    //   targeted_user_photo: item?.photo,
    //   targeted_user_full_name: item?.first_name+' '+item?.last_name,
    // })

    setTimeout(() => {
      setShowModalMsg(true)
    }, 100);
  }

  // const imageOnError = (event) => {
  //   console.log({event});
  //   // event.currentTarget.src = BrokenImage;
  //   // event.currentTarget.className = "error";
  // };

  return (
    <Layout>
      <div className=" flex justify-center w-full mt-2 lg:border-b py-8">
        <div className=" w-[1300px] flex flex-col space-y-8">
          {!isLoading ? (
            <div className=" flex flex-col relative bg-white">          
              <div className=" bg-[#00000060] w-full h-56 absolute rounded" />
              <img 
                alt="banner"
                src={author?.background_image !== '' ? author?.background_image : process.env.PUBLIC_URL+'/assets/Background.png'}
                // onError={`this.onError=null; this.src=${process.env.PUBLIC_URL+'/assets/Background.png'};`}
                // onError={imageOnError}
                className=" rounded w-full h-56 object-cover"
              />
              <div className=" p-4 px-2 flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-center justify-center lg:justify-between lg:mx-32 -mt-8 z-10 pb-8">
                <div className=" flex flex-col lg:flex-row items-center lg:space-x-8 space-y-4 lg:space-y-0">
                  <img 
                    alt="img-profile"
                    src={author?.photo !== '' ? author?.photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                    className=" w-24 h-24 lg:w-44 lg:h-44 bg-white p-2 object-cover rounded-full shadow-lg"
                  />
                  <div className="">
                    <div className=" flex flex-col md:flex-row items-start md:items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
                      <div className=" text-xl lg:text-4xl font-semibold line-clamp-1">{author?.full_name}</div>
                      <div className=" flex items-center space-x-1">
                        <AverageRating rate={author?.rating} />
                      </div>
                    </div>
                    <div className=" mt-2 text-gray-500 text-sm lg:text-base">
                      {author?.address?.states_text+', '+author?.address?.local_government_text+', '+author?.address?.address}
                    </div>
                    <div className=" text-gray-500 text-left lg:text-lg font-light text-sm">
                      <span className="  font-semibold">Product: &nbsp;&nbsp;</span>{`${author?.product_sold} sold | ${author?.subscriber_count} subscribe`}
                    </div>
                    <div className=" text-gray-400 text-sm text-left">
                      {author?.description}
                    </div>
                  </div>
                </div>
                {user?.id !== author?.id ? (
                  <div className=" flex space-x-3 items-center">
                    <button 
                      className=" p-2 px-3 text-sm lg:text-base text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                      onClick={() => {
                        if (auth !== null) {
                          setShowModalMsg(true)
                        }else{
                          setShowModal(true)
                        }
                      }}
                    >
                      <PiChatText className=" lg:text-xl " />
                      <span>Message</span>
                    </button>
                    {auth === null || !isShowNumber ? (
                      <button
                        className=" justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                        onClick={() => {
                          if (auth === null) {
                            setShowModal(true)
                            return false
                          }
                          setShowNumber(!isShowNumber)
                        }}
                      >
                        {!isShowNumber ? (
                          <>
                            <PiPhone className=" text-xl " />
                            <span>Number</span>
                          </>
                        ) : (
                          <div>{author?.phone_number}</div>
                        )}
                      </button>
                    ) : null}

                    {isShowNumber ? (
                      <a
                        className="  text-sm justify-center p-2 px-3 text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-1 rounded border focus:outline-none"
                        href={`tel:${author?.phone_number}`}
                      >
                        {author?.phone_number}
                      </a>
                    ) : null}
                  </div>
                ): null}
              </div>
            </div>                
          ): null }
          
          {isLoading? (
            <div className=" flex justify-center items-center mt-8">
              <LoadingThreeDots color={"#B38C32"}/>
            </div>
          ): null }

          <div className=" flex flex-col-reverse lg:flex-row items-start lg:space-x-8">         
            {!isLoading && author?.ad?.length === 0 ? (
              <div className=" lg:w-3/4 border bg-white flex items-center">
                <div className=" flex-1 flex-col text-center space-y-4">
                  <div className=" text-2xl font-medium">Nothing Found</div>
                  <div>This user has not published anything yet.</div>
                </div>
              </div>
            ): null}
            
            {!isLoading && author?.ad?.length > 0 ? (
              <div className=" lg:w-3/4 flex items-center mt-8 lg:mt-0">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-8 px-2 lg:px-0 w-full">
                  {author?.ad?.map((row, index) => (
                    <ListAdItem
                      {...row}
                      key={row.id}
                      index={index}
                      onFavorite={handleFavorite}
                      onMessage={handleMessage}
                    />
                  )) }
                </div>
              </div>
            ): null}

            {!isLoading ? (
              <div className=" w-full lg:w-1/4 px-2 lg:px-0">
                <div className=" flex flex-col border bg-white">
                  <div className=" border-b p-4 text-center text-xl font-semibold">About</div>
                  <div className=" p-4 lg:p-8 space-y-6 border-b">
                    <div className=" flex items-center justify-between">
                      <div className=" uppercase font-semibold">Joined</div>
                      <div className=" text-gray-500">{author?.created_at_text}</div>
                    </div>
                    <div className=" flex items-center justify-between">
                      <div className=" uppercase font-semibold">Last Online</div>
                      <div className=" text-gray-500">{author?.is_online === 1 ? 'Online' : author?.last_online}</div>
                    </div>
                    <div className=" flex items-center justify-between">
                      <div className=" uppercase font-semibold">Location</div>
                      <div className=" text-gray-500">Nigeria 🇳🇬</div>
                    </div>
                    <div className=" flex items-center justify-between">
                      <div className=" uppercase font-semibold">User Verified</div>
                      <div className=" text-gray-500 flex items-center space-x-1">
                        {author !== '' ? (
                            <>
                              <PiSealCheckFill className=" text-green-500 text-xl" /> 
                              <span>Email Verified</span>
                            </>
                          ): <span>Not Yet</span>
                        }
                      </div>
                    </div>
                  </div>

                  <div className=" flex flex-col space-y-4 items-center p-4 justify-center">
                    <button 
                      className=" p-2 px-3 w-full text-center justify-center text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-2 rounded border focus:outline-none"
                      onClick={() => {
                        if (auth !== null) {
                          handleFollow(author?.id)
                        }else{
                          setShowModal(true)
                        }
                      }}
                    >
                      {author?.is_following === 0 ? (
                        <>
                          <PiUserPlusBold className=" text-xl " />
                          <span>Follow</span>
                        </>
                      ): (
                        <>
                          <PiUserMinusBold className=" text-xl " />
                          <span>Unfollow</span>
                        </>
                      )}                      
                    </button>
                    <button 
                      className=" p-2 px-3 w-full text-center justify-center text-[#B38C32] border-[#B38C32] hover:bg-[#B38C32] hover:text-white duration-300 flex items-center space-x-2 rounded border focus:outline-none"
                      onClick={() => {
                        if (auth !== null) {
                          handleBlocked(author?.id)
                        }else{
                          setShowModal(true)
                        }
                      }}
                    >
                      {author?.is_blocked === 0 ? (
                        <>
                          <BiBlock className=" text-xl " />
                          <span>Block User</span>                          
                        </>
                      ): (
                        <>
                          <BiCircle className=" text-xl " />
                          <span>Unblock User</span>
                        </>
                      )}
                      
                    </button>
                  </div>
                </div>
              </div>
            ): null}            
          </div>
        </div>
      </div>

      <hr className=" my-4 lg:my-12" />

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null} 

      {showModal ? (
        <ModalLoginRegister
          type={1}
          onShowModal={() => setShowModal(false)}
        />
      ) : null}  
    </Layout>
  )
}

export default AuthorDetail