import React from 'react'
import { LiaTimesCircleSolid } from "react-icons/lia"
 
const ModalMembership = (props) => {
  const {
    listMembership,
    stockOld,
    stockNew,
    setStockNew,
    membershipPlanId,
    setMembershipPlanId,
    isRenewal,
    onModalBC,
    onConfirm,
    onShowModal
  } = props  

  return (
    <div
      onClick={onShowModal}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <div className=" p-4 text-center border-b ">
              <div className="  lg:text-xl font-semibold">List of ad plans</div>
              <div className=" text-sm lg:text-base text-gray-400">Choose an ad plan for your renewal.</div>
            </div>
            <div className=" flex space-y-3 flex-col max-h-80 md:max-h-full overflow-y-auto m-4">
              <div className=" flex items-center w-full">
                <div className=" w-1/2"><span className=" font-medium">Old stock:</span> {stockOld}</div>
                <div className=" flex flex-row space-x-1 md:space-x-4 items-center justify-end w-1/2">
                  <div>New stock: </div>
                  <input 
                    type={'number'}
                    className=" border p-2 w-[40%] px-4 rounded focus:outline-none" 
                    min={1}
                    value={stockNew}
                    onChange={(e) => setStockNew(e.target.value)}
                    onFocus={(e) => e.target.select()}
                    onClick={(e) => e.target.select()}
                  />
                </div>
              </div>
              {listMembership.length > 0 ? (        
                <div className=" flex space-y-3 flex-col">   
                  {listMembership.map((row) => (
                    <div
                      key={row.membership_plan}
                      className=" p-2 lg:p-4 rounded flex space-y-1 lg:space-y-0 lg:flex-row flex-col items-start justify-between border cursor-pointer"
                      onClick={() => setMembershipPlanId(row.membership_plan)}
                    >
                      <div className=" flex items-start">
                        <div className=" flex items-start">
                          <input 
                            type={'radio'}
                            className="  mt-2 cursor-pointer" 
                            id={`radio-membership-${row.membership_plan}`}
                            name="membershipPlanId"
                            value={row?.membership_plan}
                            checked={membershipPlanId === row.membership_plan ? true : false}
                            readOnly
                            // onChange={() => setState(prevState => { return {...prevState, membershipPlanId: row.membership_plan}})}
                          />
                          <div className=" ml-4">
                            <label 
                              className=" lg:text-xl font-medium cursor-pointer" 
                              htmlFor={`radio-membership-${row.membership_plan}`}
                            >
                              {row.title}
                            </label>
                            <div className=" text-gray-500 text-xs lg:text-sm">{row.expired_at_text}</div>
                          </div>
                        </div>
                      </div>
                      <div className=" rounded-full p-2 px-3 ml-6 lg:ml-0 text-white font-medium bg-[#B38C32] text-xs lg:text-sm">
                        {row?.quota_left+' ads left'}
                      </div>
                    </div>
                  ))}
                </div>
              ): (
                <div className=" flex flex-col justify-center items-center space-y-2">
                  <div className=" text-xl text-gray-500">No active ad plan(s) on your account</div>
                  <div className=" flex">
                    <div 
                      className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                      onClick={onModalBC}
                    >
                      Buy Ad Plan
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className=" p-3 border-t flex items-center justify-end space-x-3">              
              <button 
                onClick={onShowModal}
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none  hover:text-white hover:bg-gray-300 duration-300"
              >
                Cancel
              </button>
              <button 
                onClick={onConfirm} 
                className={` p-2 px-4 text-sm lg:text-base rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300 ${isRenewal ? ' cursor-not-allowed' : ' cursor-pointer'}`}
              >
                Confirm
              </button>
            </div>                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalMembership