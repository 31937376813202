import axios from "axios"
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import LoadingThreeDots from "./LoadingThreeDots"

const MembershipContent = (props) => {
  const auth = useSelector(state => state.auth.data)

  const [isLoading, setLoading] = useState(true)
  const [listMembership, setListMembership] = useState([])

  useEffect(() => {
    document.title = 'Ad Plans | Paseero';
    getApi('MEMBERSHIP', '/api/membership/index')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results } = response.data.response
          if (type === 'MEMBERSHIP') {
            setListMembership(results)
            setLoading(false)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  return (
    <div className=" lg:w-[75%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">Active Ad Plan(s)</div>
        <div className=" mt-2 text-sm lg:text-lg">Upgrade your account now to access more features.</div>
      </div>
      
      {isLoading? (
        <div className=" flex justify-center mt-8">
          <LoadingThreeDots color={"#B38C32"} />
        </div>
      ): null }

      {/* <div className=" grid grid-cols-3 gap-8 mt-8">
        {!isLoading && listMembership?.map(row => row?.is_active === 1 && (
          <div key={row.id}>
            <div className=" relative p-8 rounded-lg border justify-center items-center flex ">
              <div className=" text-3xl font-semibold">{row?.price_text}</div>
              <div className=" rounded-full p-1 px-3 text-white font-medium bg-[#B38C32] text-sm absolute -top-3 right-3">{row?.duration_text}</div>
            </div>
            <div className=" mt-3 flex justify-between items-center flex-row">
              <div className=" font-light">{row?.title}</div>
              {row?.is_active === 1 ? (<div className=" text-xs rounded p-1 px-2 text-black bg-[#F8BA09]">Active</div>) : null}              
            </div>
          </div>
        ))}        
      </div> */}

      <div className=" mt-4 lg:mt-12 flex flex-col ">
        {!isLoading && listMembership?.map(row => (
          <div 
            key={row.id}
            className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 justify-between py-3 mt-3 border-b border-gray-400"
          >
            <div className=" flex flex-col space-y-1">
              <div className=" lg:text-xl font-semibold">
                {row?.title}&nbsp;
                {row?.membership !== null ? (
                  <span className=" rounded-full p-1 px-3 text-white font-medium bg-[#B38C32] text-xs">
                    {row?.membership?.quota_left+' ads left'}
                  </span>
                ): null}
              </div>
              <div className=" text-xs lg:text-base font-light mt-1">{row?.description}</div>
            </div>
            <div>
            {row?.is_active === 1 ? (
              <div className=" p-2 w-60 flex text-sm lg:text-base justify-center items-center rounded border border-[#B38C32] text-[#B38C32] bg-white focus:outline-none duration-300">
                Current Plan
              </div>
            ): (
              <div 
                className=" p-2 w-60 flex text-sm lg:text-base justify-center items-center rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                onClick={() => props.onSelect(row)}  
              >
                Select
              </div>
            )}            
            </div>
          </div>
        ))}
        
      </div>

      {/* <div className=" mt-12 flex flex-col space-y-4">
        <div className=" p-4 border rounded">
        🕛 Active until: <b>September 14, 2023 9:31 am</b>
        </div>
        <div className=" p-4 border rounded">
        🤠 This plan includes 40 free listings. You have 49 left.
        </div>
        <div className=" text-lg font-medium text-center border rounded p-2 bg-gray-100 hover:bg-gray-200 duration-300 cursor-pointer">
          Compare Features
        </div>
      </div> */}
    </div>
  )
}

export default MembershipContent