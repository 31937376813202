import React from 'react'
import { BiCheckShield, BiEdit, BiTrash, BiRefresh } from 'react-icons/bi'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { GoGraph } from 'react-icons/go'
import { Link } from "react-router-dom"
import { Tooltip as ReactTooltip } from "react-tooltip";

const ListMyAdsItem = (props) => {
  return (
    <div 
      key={props.id}
      className=" border flex flex-row rounded justify-between items-center"
    >
      <div className=" flex items-start flex-col lg:flex-col w-full">
        <div className=" w-full relative">
          {props.is_featured === 1 ? (
            <div className=" absolute right-0 top-4 flex flex-col space-y-3 z-10">
              <div className=" text-white bg-red-600 p-1 px-3 font-medium rounded-l">
                Featured
              </div>
            </div>
          ): null}
          {props.is_new === 1 ? (
            <div className={` ${props.is_featured === 1 ? 'top-14' : 'top-4'} absolute right-0 flex flex-col space-y-3 z-10`}>
              <div className=" text-white bg-[#F9C301] p-1 px-3 font-medium rounded-l">
                New
              </div>
            </div>
          ): null}
          <Link to={`/listing/${props?.id}/${props?.title.replace(/ /g, "-").replace("/", "-")}`} className="relative">
            <img 
              alt={props?.title}
              // src={props?.photos[0]?.url}
              src={props?.thumbnail}
              className=" h-40 lg:h-60 w-full rounded-t object-cover"
            />
            {props?.qty === 0 ? (
              <div className=" absolute left-0 bottom-0 flex flex-col space-y-3">
                <div className=" text-white bg-gray-500 text-sm p-1 px-3 font-medium ">
                  Out Of Stock
                </div>
              </div>
            ): null}
          </Link>
        </div>
        <div className=" flex flex-col space-y-4 justify-between p-2 lg:p-4 ">
          <div>
            <Link 
              to={`/listing/${props?.id}/${props?.title.replace(/ /g, "-").replace("/", "-")}`}
              className=" text-lg lg:text-2xl font-semibold line-clamp-1 hover:underline"
            >
              {props?.title}
            </Link>
            <p className="text-sm lg:text-base font-light line-clamp-2">
              {props?.description}
            </p> 
            {props?.superadmin_note !== null ? (
              <p className=" text-red-500">Paseero comment: {props?.superadmin_note}</p>
            ): null}
          </div>
          <div className=" flex justify-between lg:justify-around ">
            {props?.filterSelected !== '0' ? (          
              <div className=" flex items-center space-x-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input 
                    type="checkbox" 
                    className="sr-only peer" 
                    id={'checkbox-ads'}
                    name={'checkbox-ads'}
                    checked={props.is_active ? true : false}
                    onChange={props.onSwitch}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B38C32]"></div>
                </label>
                {/* <div htmlFor={'checkbox-ads'} className="">Actived</div> */}
              </div>
            ): null}
            {props?.filterSelected === '1' ? (
              <div className="p-2 px-3 flex items-center rounded border text-white bg-green-600 border-green-600">
                <BiCheckShield className=" text-xl lg:mr-1" />
                <span className=" hidden lg:block text-sm">Live</span>
              </div>
            ): props?.filterSelected === '2' ? (
              <div className="p-2 px-3 flex items-center rounded border text-white bg-yellow-500 border-yellow-500">
                <BiCheckShield className=" text-xl lg:mr-1" />
                <span className=" hidden lg:block text-sm">Pending</span>
              </div>
            
            ): props?.filterSelected === '3' ? (
                <div className="p-2 px-3 flex items-center rounded border text-white bg-gray-200 border-gray-200">
                  <BiCheckShield className=" text-xl lg:mr-1" />
                  <span className=" hidden lg:block text-sm">Queried</span>
                </div>
            ):(
              <div className="p-2 px-3 flex items-center rounded border text-white bg-red-600 border-red-600">
                <BiCheckShield className=" text-xl lg:mr-1" />
                <span className=" hidden lg:block text-sm">Ended/Sold</span>
              </div>
            )}
            {/* {props?.filterSelected !== '0' ? ( */}
            {props?.ads_type === 0 && props?.filterSelected !== '0' ? (
              <div 
                className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                onClick={props.onEdit}
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Edit Ad"
              >
                <BiEdit className=" lg:text-xl"/>
                {/* <span className=" text-sm">Edit</span> */}
              </div>
            ): null}
            
            <div 
              className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
              onClick={props.onStatistic}
              data-tooltip-id="tooltip-icon"
              data-tooltip-content="Statistics"
            >
              <GoGraph className=" lg:text-xl"/>  
              {/* <span className=" text-sm">Statistics</span> */}
            </div>
            {props?.ads_type === 0 && props?.filterSelected === '0' ? (
              <div 
                className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                onClick={props.onRenewal}
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Renewal"
              >
                <BiRefresh className=" lg:text-xl"/>
                {/* <span className=" text-sm">Renewal</span> */}
              </div>
            ): null}
            {props?.filterSelected !== '0' ? (
              <div 
                className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                onClick={props.onUpgrades}
                data-tooltip-id="tooltip-icon"
                data-tooltip-content="Upgrades"
              >
                <HiOutlineLightBulb className=" lg:text-xl"/>
                {/* <span className=" text-sm">Upgrades</span> */}
              </div>
            ): null}
            
            <div 
              className="p-2 px-3 flex items-center bg-red-600 text-white rounded border border-red-600 focus:outline-none hover:bg-white hover:text-red-600 cursor-pointer duration-300"
              onClick={props.onDelete}
              data-tooltip-id="tooltip-icon"
              data-tooltip-content="Delete Ad"
            >
              <BiTrash className=" lg:text-xl"/>
              {/* <span className=" text-sm">Delete</span> */}
            </div>

            <ReactTooltip
              id="tooltip-icon"
              place="top"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListMyAdsItem