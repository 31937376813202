import React from 'react'
import { FaChevronRight, FaLock } from 'react-icons/fa'
import { LiaTimesCircleSolid } from 'react-icons/lia'
import LoadingThreeDots from "./LoadingThreeDots"
 
const ModalBuyNow = (props) => {
  const {
    selected,
    isLoading,
    paymentType,
    onSelectPayment,
    onBuyNow,
    onShowModal,
  } = props
  
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[500px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded-t-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-3xl -right-2 -top-2 cursor-pointer text-white"
              onClick={() => onShowModal(false)}
            />
            <div className="border-b space-y-2 py-3 lg:py-6 bg-[#B38C32] justify-center rounded-t-xl flex flex-col items-center">
              <div className=" text-center text-xl lg:text-4xl font-medium text-white">{selected?.price_text}</div>
              <div className=" text-center lg:text-xl text-white">{selected?.title}</div>
            </div>
            <div className=" m-4 lg:m-12 mt-4 flex flex-col">
              {isLoading ? (
                <div className=" flex justify-center">
                  <LoadingThreeDots color={"#B38C32"} />
                </div>
              ): (
                <div className=" flex space-y-3 flex-col">
                  <div className=" text-sm ">*Note that bank charges apply to online payments.</div>
                  <div className=" flex flex-col space-y-1">
                    <div className=" font-semibold text-sm lg:text-base">Payment Details</div>
                    <div className=" flex items-center justify-between text-sm lg:text-base">
                      <div>Total ({selected?.qty} qty)</div>
                      <div>₦{selected?.total}</div>
                    </div>
                    {/* {selected?.shipping_status === 1 ?
                      <div className=" flex items-center justify-between text-sm lg:text-base">
                        <div>Shipping cost</div>
                        <div>₦{selected?.shipping}</div>
                      </div>
                    : null} */}
                    {/* <div className=" flex items-center justify-between text-sm lg:text-base">
                      <div>Bank transfer fee</div>
                      <div>₦{selected?.bank_tf}</div>
                    </div> */}
                    <hr />
                    <div className=" flex items-center justify-between pb-4 text-sm lg:text-base">
                      <div className=" font-semibold">Sub Total</div>
                      <div className=" font-semibold">₦{selected?.sub_total}</div>
                    </div>
                  </div>
                  {/* <div 
                    className={` ${paymentType === 1 ? 'bg-gray-200' : 'bg-white'} border rounded-lg items-center flex flex-row justify-between p-4 lg:px-6 cursor-pointer hover:shadow`}
                    // onClick={() => onBuyNow(1)}
                    onClick={() => onSelectPayment(1)}
                  >
                    <img
                      src={process.env.PUBLIC_URL+'/assets/Paystack.png'} 
                      className=" h-6 lg:h-10 object-cover"
                      alt="logo-paystack"
                    />
                    <div className=" flex items-center space-x-4">
                      <div className=" font-semibold text-sm lg:text-xl">PAY NOW</div>
                      <FaChevronRight className=" text-xl" />
                    </div>
                  </div> */}
                  <div 
                    className={` ${paymentType === 2 ? 'bg-gray-200' : 'bg-white'} border rounded-lg items-center flex flex-row justify-between p-4 lg:px-6 cursor-pointer hover:shadow`}
                    // onClick={() => onBuyNow(1)}
                    onClick={() => onSelectPayment(2)}
                  >
                    <div className=" flex items-center">
                      <img
                        src={process.env.PUBLIC_URL+'/assets/Mastercard.png'} 
                        className=" h-6 lg:h-9 object-cover"
                        alt="logo-mastercard"
                      />
                      <img
                        src={process.env.PUBLIC_URL+'/assets/Visa.png'} 
                        className=" h-3 lg:h-5 object-cover"
                        alt="logo-visa"
                      />
                    </div>
                    <div className=" flex items-center space-x-4">
                      <div className=" font-semibold text-sm lg:text-xl">ONLINE PAYMENT</div>
                      <FaChevronRight className=" text-xl" />
                    </div>
                  </div>
                  <div 
                    className={` ${paymentType === 3 ? 'bg-gray-200' : 'bg-white'} border rounded-lg items-center flex flex-row justify-between p-4 lg:px-6 cursor-pointer hover:shadow`}
                    // onClick={() => onBuyNow(0)}
                    onClick={() => onSelectPayment(3)}
                  >
                    <img
                      src={process.env.PUBLIC_URL+'/assets/Logo.png'} 
                      className=" w-auto h-6 lg:h-10 object-cover"
                      alt="logo-paseero"
                    />
                    <div className=" flex items-center space-x-4">
                      <div className=" font-semibold text-sm lg:text-xl">USE PASEERO CREDITS</div>
                      <FaChevronRight className=" text-xl" />
                    </div>
                  </div>
                  <div 
                    className=" rounded-lg p-2 border text-xl font-medium uppercase text-center border-green-600 text-green-600 hover:bg-green-600 hover:text-white cursor-pointer duration-300"
                    onClick={() => onBuyNow(paymentType === 3 ? 0 : 1)}
                  >
                    Proceed
                  </div>
                </div>                
              )}

              <div className=" flex items-center justify-center mt-8">
                <FaLock className=" text-gray-500 mr-2" />
                <div className=" text-xs lg:text-sm text-gray-500 font-light">All payment pages are secured using SSL.</div>
              </div>
            </div>           
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalBuyNow