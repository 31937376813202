import React from 'react'
import { LiaTimesCircleSolid } from 'react-icons/lia'
 
const ModalUpgrades = (props) => {
  const {
    data,
    sumTotal,
    onSelect,
    onConfirm,
    onShowModal
  } = props  

  return (
    <div
      onClick={onShowModal}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 md:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-3xl -right-2 -top-2 cursor-pointer text-white"
              onClick={() => onShowModal(false)}
            />
            <div className=" p-4 text-center border-b ">
              <div className="  text-xl font-semibold">List of Upgrades</div>
              <div className=" text-gray-400">Select ad upgrade(s) for more visibility.</div>
            </div>
            {/* <div className=" m-4 ">              
              <p>lorem ipsum dolor sit amet</p>
            </div>             */}
            <div className=" flex space-y-3 flex-col m-4 max-h-60 md:max-h-full overflow-y-auto">   
              {data?.map((row, index) => (
                <div
                  key={row.id}
                  className={` p-3 md:p-8 rounded flex flex-row items-start justify-between border  ${row.is_active ? 'bg-gray-200' : 'cursor-pointer'}`}
                  onClick={() => !row.is_active && onSelect(index, !row.isActive)}
                >
                  <div className=" flex items-start">
                    <div className=" flex items-start">
                      <input 
                        type={'checkbox'} 
                        className="  mt-2 cursor-pointer" 
                        id={`checkbox-upgrade-${row.id}`}
                        checked={row.is_active ? row.is_active : row.isActive}
                        readOnly
                        // onClick={() => onSelect(index, !row.isActive)}
                      />
                      <div className=" ml-4">
                        <label 
                          className=" text-base md:text-xl font-medium cursor-pointer" 
                          htmlFor={`checkbox-upgrade-${row.id}`}
                        >
                          {row.title}
                        </label>
                        <div className="text-xs md:text-base text-gray-500">{row.description}</div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-base md:text-xl font-bold">{row.price_text}</div>
                </div>
              ))}
              {sumTotal > 0 ? (
                <div className=" p-4 rounded bg-[#343A3F] flex items-center justify-between">
                  <div className=" text-sm md:text-lg font-semibold text-white">Total</div>
                  <div className=" text-sm md:text-lg font-semibold text-white">₦ {sumTotal}</div>
                </div>
              ): null}
            </div>
            <div className=" p-3 border-t flex items-center justify-end space-x-3">              
              <button 
                onClick={onShowModal} 
                className=" text-sm md:text-base p-2 px-4 rounded border focus:outline-none  hover:text-white hover:bg-gray-300 duration-300"
              >
                Cancel
              </button>
              <button 
                onClick={onConfirm} 
                className=" text-sm md:text-base p-2 px-4 rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                Confirm
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalUpgrades