import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Layout from "./Layout"
import { FaFile } from 'react-icons/fa'

const Callback = () => {
  const [orderId, setOrderId] = useState('')
  const [invoiceId, setInvoiceId] = useState('')

  useEffect(() => {
    document.title = 'Callback Payment | Paseero';
    window.scrollTo(0, 0)
    let urlSearchString = window.location.search;
    let params = new URLSearchParams(urlSearchString);
    setOrderId(params.get('order_id'))
    setInvoiceId(params.get('invoiceId'))
  }, [])
  
  return (
    <Layout>
      <div className=" flex justify-center w-full border mt-12 py-8">
        <div className=" w-[1300px] px-4 lg:px-0">
          <div className=" text-xl lg:text-3xl text-[#212529]">Callback</div>
          <div className=" mt-2 text-sm lg:text-xl text-[#212529]">Info for transaction success</div>
        </div>
      </div>     

      <div className=" flex justify-center items-center my-8 px-4 lg:px-0">
        <div className=" border rounded flex flex-col space-y-4 text-center pb-8 bg-white">
          <div className=" bg-[#B38C32] p-8 rounded-t">
            <div className=" text-white text-2xl font-medium text-center">Order Complete 😃</div>
          </div>
          <div className=" text-3xl font-medium">#{invoiceId}</div>
          <div className=" px-8 lg:text-lg font-medium">Thank you, your order has been received and is being processed</div>
          <div className=" text-sm lg:text-base font-light">If you have any questions please <Link to={'/contact-us'} className=" text-[#B38C32] hover:underline">Contact us</Link> </div>
          
          <div className=" flex justify-center">
            <Link
              to={'/my-account?tabs=dashboard'}
              className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
            >
              Return To My Account
            </Link>
          </div>

          <div className=" flex justify-center">
            <a
              href={process.env.REACT_APP_API_URL+'/invoice?order_id='+orderId}
              target="_blank"
              rel="noreferrer"
              className=" p-2 px-4 flex items-center rounded text-white bg-[#F8BA09] border border-[#F8BA09] hover:text-[#F8BA09] hover:bg-white focus:outline-none duration-300 cursor-pointer"
            >
              <FaFile className=" mr-2" />
              <span>View Invoice</span>
            </a>
          </div>
        </div>
      </div>

      <hr className=" my-12" />
    </Layout>
  )
}

export default Callback