import React, { useEffect, useState, } from 'react'
import { FacebookEmbed } from 'react-social-media-embed';
import { IoMailOpenOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Layout from "./Layout";
import { 
  ListAdItem, 
  ListSlider, 
  ModalLoginRegister, 
  ModalMessage,
  LoadingThreeDots,
  ModalBankAccount,
  ModalPopup
} from "../components";
import { setUser } from "../redux/authSlice";
import { Helmet } from "react-helmet";
import { messaging } from "../utils/useFirebaseConfig";
import { getToken } from "firebase/messaging";

const Home = () => {
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const [isFilter, setFilter] = useState({
    homepage: true,
    recent: false,
    popular: false,
  })
  const [filterActive, setFilterActive] = useState(1)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [isLoadingBank, setLoadingBank] = useState(false)
  const [listAd, setListAd] = useState([])
  const [pageAd, setPageAd] = useState(null)
  const [listAdvertising, setListAdvertising] = useState([])
  const [listBank, setListBank] = useState([])
  // const [listSpecial, setListSpecial] = useState([])
  const [topBackground, setTopBackground] = useState(null)
  // const [mobileBackground, setMobileBackground] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [showModal, setShowModal] = useState('')
  const [showModalMsg, setShowModalMsg] = useState('')
  const [showModalBank, setShowModalBank] = useState(false)
  const [showModalPopup, setShowModalPopup] = useState(false)
  const [msgUser, setMsgUser] = useState(null)
  const [isMobile, setIsMobile] = useState(false);
  const [resetPass, setResetPass] = useState(null);
  const [isType, setType] = useState(1);
  const [email, setEmail] = useState('')
  const [listSide, setListSide] = useState([])
  const [listBlog, setListBlog] = useState([])
  const [popup, setPopup] = useState([])
  // const [subscriberId, setSubscriberId] = useState(null)
  const [firebaseToken, setFirebaseToken] = useState(null)
  const [limit, setLimit] = useState(100)
  const [stateBank, setStateBank] = useState({
    accountName: '',
    businessName: '',
    iban: '',
    swiftCode: '',
    country: 1,
    phoneNumber: ''
  }) 
  // const auctions = [1,2,3,4,5]

  useEffect(() => {
    // console.log("hellees");
    // document.title = 'Home | Paseero';
    // window.scrollTo(0, 0)
    setTimeout(() => {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, 500);

    let urlSearchString = window.location.search;
    let params = new URLSearchParams(urlSearchString);
    
    if (params.get('token') !== null) {
      setResetPass({
        token: params.get('token'),
        id: params.get('id')
      })
      setType(4)
      setTimeout(() => {
        setShowModal(true)
      }, 100);
    }

    if (user?.check_bank_information === 1) {
      getApi('BANK', '/api/master/banks')
    }

    // const Webpushr = window.webpushr;
    // console.log('Webpushr',Webpushr);
    // Webpushr('fetch_id',function (sid) { 
    //   //save id to database
    //   if (sid) {
    //     setSubscriberId(sid)
    //   }
    //   console.log('webpushr subscriber id: ' + sid)
    // });

    const fetchAll = () => {
      Promise.all([
        // getApi('AD', `/api/category/list?sort_by=${1}&per_page=${limit}&webpusher_token=${subscriberId}`),
        getApi('ADVERTISING', '/api/master/advertising_slot/index'),
        getApi('BACKGROUND', '/api/master/header_background/index'),
        // getApi('SPECIAL', '/api/ad/recommended'),
        getApi('SIDE', '/api/master/side-image'),
        getApi('BLOG', '/api/master/blog/homepage'),
        getApi('POPUP', '/api/auth/pop_up_banner'),
      ])
      .then(() => {
        // setLoading(false);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        setLoading(false);
      });
    }
    fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  function detectWindowSize () {
    // window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setLimit(20)
    }else{
      setIsMobile(false)
      setLimit(30)
    }
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    // window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setLimit(20)
    }else{
      setIsMobile(false)
      setLimit(30)
    }
    window.onresize = detectWindowSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.onresize])

  useEffect(() => {
    // console.log({subscriberId});
    // console.log({limit});
    // console.log("useEffect sid");
    // if (subscriberId !== null) {
    //   console.log("useEffect subscriberId", subscriberId);
      // getApi('AD', `/api/category/list?sort_by=${1}&per_page=${limit}&webpusher_token=${subscriberId}`)
      getApi('AD', `/api/category/list?sort_by=${1}&per_page=${limit}&webpusher_token=${firebaseToken}`)
    // }
    // if (!isNaN(parseInt(subscriberId))) {
    //   console.log("if");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseToken, limit])

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    console.log({permission});
    if (permission === "granted") {
      getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
        .then((currentToken) => {
          if (currentToken) {
            console.log("Token generated: ", currentToken);
            setFirebaseToken(currentToken)
            // show on the UI that permission is secured
          } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required 
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // catch error while creating client token
        });
    } else if (permission === "denied") {
      //notifications are blocked
      // alert("You denied for the notification, please enable notification.");
      console.log("You denied for the notification, please enable notification.");
    }
  }

  useEffect(() => {
    requestPermission()
  }, []);
  
  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: auth !== null ? `Bearer ${auth?.access_token}` : '',
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'AD') {
            setListAd(results)
            setPageAd(metadata)
            setLoading(false)
            setLoadingMore(false)
          }else if (type === 'ADMORE') {
            let arr = [...listAd, ...results]
            // console.log({arr});
            setListAd(arr)
            setPageAd(metadata)
            setLoadingMore(false)
          }else if(type === 'ADVERTISING') {
            setListAdvertising(results)
          } else if(type === 'BACKGROUND') {
            setTopBackground(results)
          }else if(type === 'SIDE') {
            setListSide(results)
          }else if(type === 'BLOG') {
            setListBlog(results.blog)
          }else if(type === 'POPUP') {
            if (results.length) { 
              setPopup(results[0])
              setTimeout(() => {
                setShowModalPopup(true)
              }, 1000);
            }
          }else{
            setListBank(results)
            // setStateBank(prevState => {
            //   return {
            //     ...prevState,
            //     swiftCode: results[0].code
            //   }
            // })
            // setListSpecial(results)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  
  const handleChangeFilter = (name, value) => {
    setFilter({      
      homepage: name === 'homepage' ? value : false,
      recent: name === 'recent' ? value : false,
      popular: name === 'popular' ? value : false,      
    })
    let sort = name === 'homepage' ? 1 : name === 'recent' ? 2 : 3
    setLoading(true)
    setFilterActive(sort)
    getApi('AD', `/api/category/list?sort_by=${sort}&per_page=${limit}&page=${1}`)
  }

  const handleLink = () => {
    if (auth === null ) {
      setShowModal(true)
    } else {
      // if (user?.bank?.user_id === null) {
      if (user?.check_bank_information === 1) {
        setShowModalBank(true)
      }else{
        if (user?.membership.length > 0) {
          dispatch(setUser({...user, adTemp: null}))
          setTimeout(() => {
            navigate('/add-listing')
          }, 100);
        }else{
          Swal.fire({
            icon: 'warning',
            title: 'You have no active ad plan(s)',
            text: `Purchase an ad plan to start posting ads.`,
            showCancelButton: true,
            confirmButtonText: 'Get ad plan',
            confirmButtonColor: '#B38C32',
          }).then((result) => {
            if (result.isConfirmed) {
              // navigate('/ad-pricings')
              navigate('/my-account?tabs=membership')
            }
          })
        }
      }
    }
  }

  const ButtonFilter = ({name, value, title}) => (
    <button 
      className={` flex justify-center p-3 w-full text-xs md:text-xl font-medium  rounded border border-[#B38C32] cursor-pointer hover:text-[#B38C32] hover:bg-white duration-300 ${!value ? 'text-[#B38C32] bg-white' : ' bg-[#B38C32] text-white'}`}
      onClick={() => handleChangeFilter(name, value)}  
    >
      {title}
    </button>
  )

  const handleFavorite = (index, id) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }
    
    const apiUrl = process.env.REACT_APP_API_URL+'/api/user/wishlist/toogle';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: id,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {          
          const { results } = response.data.response
          let list = [...listAd]
          list[index] = {
            ...list[index] = {
              ...list[index],
              is_favorite: results.is_favorite,
            }
          }
          setListAd(list)          
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
      });    
  }

  const handleMessage = (item) => {
    if (auth === null) {
      setShowModal(true)
      return false
    }

    setMsgUser({
      ...item,
      targeted_user_id: item?.id,
      targeted_user_photo: item?.photo,
      targeted_user_full_name: !!item?.business_name ? item?.business_name : item?.first_name+' '+item?.last_name,
    })

    setTimeout(() => {
      setShowModalMsg(true)
    }, 100);
  }

  const handleSubscribe = () => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/subscribe';
    const headers = {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      email: email,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {          
          const { messages } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'Newsletter subscription successful',
            text: messages,
            confirmButtonColor: '#B38C32'
          })
          setEmail('')        
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
      }); 
  }
  // const [page, setPage] = useState(1)
  const handlePagination = (type) => {
    if (parseInt(pageAd?.selectedPage) < pageAd?.totalPage) { 
      setLoadingMore(true)
      getApi('ADMORE', `/api/category/list?sort_by=${filterActive}&per_page=${limit}&page=${parseInt(pageAd?.selectedPage)+1}`)
    }
    // setLoadingMore(true)
    // let page = parseInt(pageAd?.selectedPage)
    // if (type === 'prev') {
    //   page = (page-1)
    // }else if (type === 'next') {
    //   page = (page+1)
    // }else{
    //   page = (type)
    // }
    // setPage(page)
  }

  // useEffect(() => {
  //   if (!!pageAd) {
  //     getApi('AD', `/api/category/list?sort_by=${filterActive}&per_page=${limit}&page=${page}&webpusher_token=${subscriberId}`)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page])

  const handleChangeBank = (e) => {
    const { name, value } = e.target
    setStateBank(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleSubmitBank = () => {
    setLoadingBank(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/bank-create';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      account_name: stateBank?.accountName,
      business_name: stateBank?.businessName,
      iban: stateBank?.iban,
      swift_code_bic: stateBank?.swiftCode,
      country: stateBank?.country,
      // phone_number: stateBank?.phoneNumber,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          }, 500);
          let userNew = {
            ...user,
            bank: results.bank
          }
          // dispatch(clearUser())
          dispatch(setUser(userNew))
          setLoadingBank(false)
          setShowModalBank(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingBank(false)
      });
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    variableWidth: false,
  };

  const [windowWidth, setWindowWidth] = useState(0)
  // function detectWindowSize () {
  //   // console.log("detectWindowSize", window.innerWidth);
  //   setWindowWidth(window.innerWidth)
  // }  
  
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.onresize = detectWindowSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.onresize])

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Discover a world of affordable shopping on Paseero.ng! Nigeria's premier e-commerce platform where you can buy and sell items at unbeatable prices. Explore a wide range of products and connect with sellers across the country. Join the Paseero.ng community today and start shopping smart!" />
      </Helmet>
      <div className=" mt-4 lg:mt-8" />

      <div className=" min-h-screen">
        <div className=" flex flex-wrap justify-center w-full ">
          <div className=" space-x-2 justify-center flex w-full px-2 pb-4 lg:hidden">
            <button 
              className=" p-3 w-full text-xs md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
              onClick={() => handleLink()}
            >
              Post Ad
            </button>
            <Link
              to={'/market-place'} 
              className=" p-3 w-full text-xs md:text-xl text-center font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
            >
              Market Place
            </Link>
          </div>
          {isMobile ? 
            <div className=" px-2">
              <img 
                src={topBackground?.mobile_image}
                className=" w-full h-auto rounded"
                alt="Loading..."
              />
            </div>
          : 
            <div 
              className=" bg-none px-8 py-8 w-full h-[230px] md:h-[450px] mx-4 md:w-[1300px] bg-[#366C38] rounded flex flex-col justify-end items-center lg:items-start bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${isMobile ? topBackground?.mobile_image : topBackground?.image})`}}
              // style={{ backgroundImage: `url(${topBackground?.image})`}}
            >
              {/* <div className=" space-y-4 text-center lg:text-left">
                <div className=" text-3xl md:text-5xl text-white font-bold">Freedom - Sell or Auction</div>
                <div className=" text-3xl md:text-5xl text-white font-bold">Find Deals - Buy or Bid</div>
                <div className=" text-white text-base md:text-xl">Nigeria-based item selling, auctioning, buying and bidding platform</div>
              </div> */}
              <div className=" hidden lg:flex space-x-4 justify-center lg:justify-start w-full md:w-1/2 lg:w-1/3">
                <button 
                  className=" p-3 w-full text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
                  onClick={() => handleLink()}
                >
                  Post Ad
                </button>
                <Link
                  to={'/market-place'} 
                  className=" p-3 w-full text-sm md:text-xl text-center font-medium bg-[#B38C32] text-white rounded border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
                >
                  Market Place
                </Link>
              </div>
              <div className=" hidden lg:flex justify-center pt-8 lg:justify-start w-full md:w-1/2 lg:w-1/3">
                <input 
                  className=" p-3 px-4 w-full bg-white text-sm md:text-xl placeholder-grey border border-white border-r-[#B38C32] rounded-l focus:outline-none" 
                  placeholder="Start your search here.."
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && keyword !== '') {
                      navigate('/market-place?keyword='+keyword)
                    }
                  }}
                />
                <button 
                  className=" p-3 w-32 text-sm md:text-xl font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
                  onClick={() => {
                    if (keyword !== '') {
                      navigate('/market-place?keyword='+keyword)
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          }
          <div className=" flex lg:hidden justify-center pt-4 w-full px-2">
            <input 
              className=" p-3 px-4 w-full bg-white text-sm md:text-xl placeholder-grey border  border-r-[#B38C32] rounded-l focus:outline-none" 
              placeholder="Start your search here.."
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && keyword !== '') {
                  navigate('/market-place?keyword='+keyword)
                }
              }}
            />
            <button 
              className=" p-3 w-32 text-xs md:text-xl font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-white duration-300"
              onClick={() => {
                if (keyword !== '') {
                  navigate('/market-place?keyword='+keyword)
                }
              }}
            >
              Search
            </button>
          </div>
        </div>

        <ListSlider />

        <div className=" flex justify-center w-full pt-4 lg:pt-8">
          <div className=" flex w-[1300px] lg:space-x-8">
            <div className=" hidden lg:flex flex-col lg:w-1/4 space-y-8">
              <div className=" bg-white border border-gray-300 rounded flex items-center text-center flex-col space-y-6 p-4">
                <div>
                  <IoMailOpenOutline className=" text-9xl" />
                </div>
                <div className=" text-black text-xl font-semibold">
                  Join Our Newsletter
                </div>
                <div className=" text-[#7F7F7F] font-light tracking-wide text-sm">
                  Join our subscribers list to get the latest <br />news, updates and special offers <br />delivered directly in your inbox.
                </div>
                <div className=" items-center flex">
                  <input 
                    className=" h-12 px-4 w-60 bg-white placeholder-grey border border-gray-300 rounded-l focus:outline-none" 
                    placeholder="Email address here.."
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                  />
                  <button 
                    className=" h-12 w-20 font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-l-[#B38C32] duration-300"
                    onClick={handleSubscribe}
                  >
                    Join
                  </button>
                </div>
              </div>

              <div>
                <div className=" bg-[#B38C32] rounded-t">
                  <div className=" text-white text-2xl font-medium  p-4">
                    New Post
                  </div>
                </div>                
                <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <FacebookEmbed
                      height={{}}
                      url="https://www.facebook.com/photo?fbid=122130152966069019&set=a.122123938922069019"
                      width={295}
                    />
                  </div>
                </div>                
              </div>              

              <div>
                {/* <div className=" bg-[#B38C32] rounded-t">
                  <div className=" text-white text-2xl font-medium  p-4">
                    Popular Auctions
                  </div>
                </div>                 */}
                <div className=" flex flex-col space-y-4 p-4 border border-gray-300">
                  {/* {listSpecial.map((row) => (                                        */}
                  {listSide.map((row) => (                                       
                    <div
                      key={row.id} 
                      // to={`/listing/${row?.id}/${row?.title.replace(/ /g, "-")}`}
                      className=" flex flex-row items-start shadow hover:scale-105 duration-300"
                    >
                      <img 
                        alt={row?.title}
                        src={row?.image}
                        className=" rounded w-full h-[175px] object-cover" 
                      />
                    </div>
                  ))}
                </div> 
              </div>

              <div>
                <div className=" bg-[#B38C32] rounded-t">
                  <div className=" text-white text-2xl font-medium  p-4">
                    Latest News
                  </div>
                </div>                
                <div className=" flex flex-col space-y-4 p-4 border border-t-0 border-gray-300">
                  {listBlog.map((row) => (                   
                    <Link 
                      key={row.id}
                      to={`/blog/${row?.id}/${row?.title.replace(/ /g, "-").replace("/", "-")}`}
                      className=" flex flex-row items-start"
                    >
                      <img
                        alt={row?.title}
                        src={row?.main_image}
                        className=" rounded w-20" 
                      />
                      <div className=" flex flex-col ml-4">
                        <div className=" text-black font-medium line-clamp-1 ">{row?.title}</div>
                        <div className=" text-sm text-[#7F7F7F] line-clamp-1">{row?.short_desc}</div>
                      </div>
                    </Link>
                  ))}
                </div>                
              </div>

            </div>
            <div className="w-full lg:w-3/4">
              {!isLoading ? (              
                <div className="grid grid-cols-3 gap-2 md:gap-8 px-2 lg:px-0">
                  <ButtonFilter 
                    name="homepage"
                    value={!isFilter.homepage}
                    title='Homepage'
                  />
                  <ButtonFilter 
                    name="recent"
                    value={!isFilter.recent}
                    title='Recent'
                  />
                  <ButtonFilter 
                    name="popular"
                    value={!isFilter.popular}
                    title='Popular'
                  />
                </div>
              ): null}

              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-8 px-2 lg:px-0 mt-4">
                {!isLoading && listAd.map((row, index) => (
                  <ListAdItem
                    {...row}
                    key={row.id}
                    index={index}
                    onFavorite={handleFavorite}
                    onMessage={handleMessage}
                  />
                ))}
              </div>

              {isLoading ? 
                <div className=" flex justify-center self-center mt-40">
                  <LoadingThreeDots
                    size={'50'}
                    color={"#B38C32"}
                  />
                </div>
              : null }

              <div className=" flex flex-col items-center space-y-6 justify-center mt-12">
                {/* <Pagination
                  isLoading={isLoadingMore}
                  listCount={listAd?.length}
                  page={pageAd}
                  onClick={handlePagination}
                /> */}
                {isLoadingMore ? (
                  <LoadingThreeDots color={"#B38C32"} />
                ): null}
                {!isLoading && listAd.length > 0 && (parseInt(pageAd?.selectedPage) !== pageAd?.totalPage) ? (
                  <div 
                    className=" p-2 px-6 border border-[#B38C32] text-[#B38C32] rounded bg-white hover:bg-[#B38C32] hover:text-white focus:outline-none duration-300 cursor-pointer"
                    onClick={() => handlePagination()}
                    >
                    Load More
                  </div>
                ): null}
              </div>
            </div>
          </div>
        </div>

        <hr className=" hidden lg:flex my-12" />

        <div className=" hidden lg:flex justify-center w-full py-8">
          <div className=" w-[1300px] grid grid-cols-4 gap-8">
            {!isLoading && listAdvertising.map(row => (
              row.redirect_url === '#' ? (
                <div
                  key={row.id}
                  className=" border rounded bg-white text-center h-[30rem] flex justify-center items-center relative cursor-pointer"
                >
                  <img 
                    alt={row.alt_image}
                    src={row.image}
                    className=" rounded h-[30rem] object-cover w-full"
                  />
                  <div 
                    className=" absolute text-white hover:bg-white hover:text-black w-full duration-300"
                  >
                    {row.contact_person}
                  </div>
                </div>
              ): (
                <a
                  key={row.id}
                  className=" border rounded bg-white text-center h-[30rem] flex justify-center items-center relative cursor-pointer"
                  href={row.redirect_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    alt={row.alt_image}
                    src={row.image}
                    className=" rounded h-[30rem] object-cover w-full"
                  />
                  <div 
                    className=" absolute text-white hover:bg-white hover:text-black w-full duration-300"
                  >
                    {row.contact_person}
                  </div>
                </a>
              )              
            ))}
          </div>
        </div>

        <div className=" md:hidden flex justify-center w-full pt-12 ">
          <div style={{ width: windowWidth >= 1300 ? '300px' : windowWidth*0.935+'px'}} className=" w-[300px]">
            <Slider {...settings}>
              {!isLoading && listAdvertising.map(row => (
                row.redirect_url === '#' ? (
                  <div
                    key={row.id}
                    style={{ height: windowWidth*0.75}}
                    className=" border rounded bg-white text-center flex justify-center items-center relative cursor-pointer"
                  >
                    <img 
                      alt={row.alt_image}
                      src={row.mobile_image}
                      style={{ height: windowWidth*0.75}}
                      className=" rounded object-cover w-full"
                    />
                    <div 
                      className=" absolute text-white hover:bg-white hover:text-black w-full duration-300 top-[50%]"
                    >
                      {row.contact_person}
                    </div>
                  </div>
                ): (
                  <a
                    key={row.id}
                    style={{ height: windowWidth*0.75}}
                    className=" border rounded bg-white text-center flex justify-center items-center relative cursor-pointer"
                    href={row.redirect_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img 
                      alt={row.alt_image}
                      src={row.mobile_image}
                      style={{ height: windowWidth*0.75}}
                      className=" rounded object-cover w-full"
                    />
                    <div 
                      className=" absolute text-white hover:bg-white hover:text-black w-full duration-300 top-[50%]"
                    >
                      {row.contact_person}
                    </div>
                  </a>
                )       
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <hr className=" my-8 lg:my-12" />

      {showModalBank ? (
        <ModalBankAccount
          {...stateBank}
          listBank={listBank}
          onChange={handleChangeBank}
          onShowModal={setShowModalBank}
          onSubmit={handleSubmitBank}
          isLoading={isLoadingBank}
          onHandleLink={() => {
            if (user?.membership.length > 0) {
              dispatch(setUser({...user, adTemp: null}))
              setTimeout(() => {
                navigate('/add-listing')
              }, 100);
            }else{
              Swal.fire({
                icon: 'warning',
                title: 'You have no active ad plan(s)',
                text: `Purchase an ad plan to start posting ads.`,
                showCancelButton: true,
                confirmButtonText: 'Get ad plan',
                confirmButtonColor: '#B38C32',
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate('/ad-pricings')
                  navigate('/my-account?tabs=membership')
                }
              })
            }
          }}
        />
      ): null}

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null} 

      {showModalPopup ? (
        <ModalPopup
          data={popup}
          onShowModal={() => setShowModalPopup(false)}
          onLogin={() => {
            setType(2)
            setShowModal(true)
          }}
        />
      ) : null} 
      
      {showModal ? (
        <ModalLoginRegister
          type={isType}
          resetPass={resetPass}
          onShowModal={() => {
            setShowModal(false)
            setType(1)
          }}
        />
      ) : null}  
    </Layout>
  )
}

export default Home