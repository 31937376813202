import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { 
  HiOutlineEnvelope, 
  HiOutlineMapPin, 
  HiOutlineUserCircle,
} from "react-icons/hi2";
import { useSelector } from "react-redux";
import axios from "axios";
import ListNotifItem from "./ListNotifItem";
import LoadingThreeDots from "./LoadingThreeDots";

const DashboardContent = ({setTabActive}) => {
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const [isLoading, setLoading] = useState(true)
  const [listNotif, setListNotif] = useState([])
  const [background, setBackground] = useState(null)
  // const [idNotif, setIdNotif] = useState(0)

  useEffect(() => {
    document.title = 'Dashboard | Paseero';
    getApi('NOTIF', '/api/auth/notif')
    getApi('BACKGROUND', '/api/master/header_background/index')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results } = response.data.response
          if (type === 'NOTIF') {
            setListNotif(results)
            setLoading(false)
          }else{
            setBackground(results)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleDeleteNotif = (id) => {
    let arr = [...listNotif]
    let index = arr.map(x => {return x.id; }).indexOf(id);
    arr.splice(index, 1);
    setListNotif(arr)
    getApi('DELNOTIF', '/api/auth/notif-delete?id='+id)
  }

  const handleViewNotif = (id, flag) => {
    getApi('VIEWNOTIF', '/api/auth/notif-view?id='+id)

    setTimeout(() => {
      if (flag === 'ads') {
        setTabActive('listings')
        navigate('/my-account?tabs=listings')
      }else if (flag === 'balance') {
        setTabActive('orders')
        navigate('/my-account?tabs=orders')
      }else if (flag === 'membership') {
        setTabActive('membership')
        navigate('/my-account?tabs=membership')
      } 
    }, 100);
  }
  
  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div className=" hidden lg:block">
        <div className=" text-4xl font-semibold">Welcome Back</div>
        <div className=" mt-2 text-lg">Here's an overview of your account.</div>
      </div>
      <div className=" hidden lg:grid border p-6 divide-x-2 gap-4 rounded border-gray-300 grid-cols-3 mt-4">
        <div className=" flex items-start space-x-2">
          <HiOutlineUserCircle className=" text-4xl" />
          <div >
            <div className=" text-xl font-semibold">My Username</div>
            <div className=" text-gray-500 text-sm font-light">{user?.full_name}</div>
          </div>
        </div>
        <div className=" flex items-start space-x-2 pl-4">
          <HiOutlineMapPin className=" text-4xl" />
          <div>
            <div className=" text-xl font-semibold">My Location</div>
            <div className=" text-gray-500 text-sm font-light">{user?.address?.states_text +', '+user?.address?.local_government_text}</div>
          </div>
        </div>
        <div className=" flex items-start space-x-2 pl-4">
          <HiOutlineEnvelope className=" text-4xl" />
          <div>
            <div className=" text-xl font-semibold">My Email</div>
            <div className=" text-gray-500 text-sm font-light">{user?.email}</div>
          </div>
        </div>
      </div>

      <div className=" lg:mt-4 rounded flex-col lg:flex-row bg-[#FAFAFB] p-4 lg:p-8 flex space-y-4 lg:space-y-0 items-start">
        <div className=" lg:w-1/2">
          <div className=" lg:text-xl font-semibold">Need help? Use the links</div>
          <div className=" flex space-x-2 mt-4">                    
            <Link to={'/how-it-works'} className=" text-sm lg:text-base p-2 px-4 text-white bg-gray-400 rounded border focus:outline-none hover:bg-white hover:text-gray-400 border-gray-400 cursor-pointer duration-300">How Its Works</Link>
            <Link to={'/contact-us'} className=" text-sm lg:text-base p-2 px-4 text-white bg-gray-400 rounded border focus:outline-none hover:bg-white hover:text-gray-400 border-gray-400 cursor-pointer duration-300">Contact Us</Link>
          </div>
        </div>
        <div className=" lg:w-1/2">
          <img 
            alt="img-dashboard"
            // src="https://premiummod.com/demoimages/img.php?accountimg=1&t=at"
            src={background?.account_dashboard_image}
            className=" rounded"
          />
        </div>
      </div>

      <div className=" text-lg font-semibold mt-8">Recent Notices</div>
      <div className=" flex flex-col space-y-3 mt-4 h-[30rem] overflow-y-auto">
        {isLoading ? (          
          <div className=" flex justify-center">
            <LoadingThreeDots color={"#B38C32"} />
          </div>
        ): null}

        {!isLoading && listNotif?.map((row) => (
          <ListNotifItem
            {...row}
            key={row.id}
            onView={() => handleViewNotif(row?.id, row?.flag)}
            onDelete={() => handleDeleteNotif(row?.id)}
          />
        ))}  
    
      </div>
    </div>
  )
}

export default DashboardContent