import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingThreeDots from "./LoadingThreeDots";

const ListSlider = () => {
  const [isLoading, setLoading] = useState(true)
  const [listAd, setListAd] = useState([])

  useEffect(() => {
    getAd(0)
    // return () => {
      
    // }
  }, [])

  const getAd = (carousel) => {
    const apiUrl = process.env.REACT_APP_API_URL+`/api/category/list?carousel=${carousel}`;
    const headers = {
      'Content-Type': 'application/json',
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          setListAd(results)
          setTimeout(() => {
            setLoading(false)            
          }, 500);
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    // slidesToShow: listAd.length > 12 ? 8 : listAd.length,
    // slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // variableWidth: listAd.length < 8 ? true : false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: listAd.length > 5 ? 5 : listAd.length,
          slidesToScroll: 1,
          arrows: false,
          // infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: listAd.length > 5 ? 5 : listAd.length,
          slidesToScroll: 1,
          arrows: false,
          // variableWidth: false,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          // slidesToShow: listAd.length > 4 ? 4 : listAd.length,
          slidesToScroll: 1,
          arrows:false,
          // variableWidth: false,
        }
      },
      {
        breakpoint: 325,
        settings: {
          // slidesToShow: listAd.length > 3 ? 3 : listAd.length,
          slidesToScroll: 1,
          arrows:false,
          // variableWidth: false,
        }
      }
    ]
  };

  return (
    <div className=" flex justify-center w-full pt-4 lg:pt-12">
      {/* <div className=" w-[300px] min-[425px]:w-[400px] min-[375px]:w-[350px] md:w-[768px] lg:w-[1300px]"> */}
      <div className=" w-full px-2 md:px-0 md:w-[768px] lg:w-[1300px]">
        {isLoading ? 
          <div className=" flex justify-center pb-8">
            <LoadingThreeDots
              size={'50'}
              color={"#B38C32"}
            />
          </div>
        : null }
        
        {!isLoading ? (        
          <Slider {...settings} className="mx-0 md:mx-4 lg:mx-0 ">
            {listAd.map((row) => (
              <Link
                to={`/listing/${row?.id}/${row?.title.replace(/ /g, "-").replace("/", "-")}`} 
                key={row.id} 
                className=" h-28 w-28 lg:h-40 lg:w-40 rounded px-1"
              >
                <img 
                  // src={row.photos[0]?.url} 
                  src={row?.thumbnail} 
                  alt={row?.title} 
                  className=" h-28 w-28 lg:h-40 lg:w-40 rounded object-cover border" 
                />
              </Link>                  
            ))}            
          </Slider>
        ): null}
      </div>
    </div>
  )
}

export default ListSlider