import axios from "axios";
import React, { useEffect, useState } from 'react'
import { 
  FaWhatsapp,
  FaFacebook,
  // FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTasks,
  FaHome,
  FaSearch,
  FaUsers,
  FaPlus,
  FaCircle,
  FaBell
 } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { setUser } from "../redux/authSlice";
import { setShowNav } from "../redux/filterSlice";
import ModalBankAccount from "./ModalBankAccount";
import ModalLoginRegister from "./ModalLoginRegister";
import ModalMessage from "./ModalMessage";
import ModalNotif from "./ModalNotif";

const Footer = () => {
  const usefulLinks = [
    {
      id: 1,
      name: 'About Us',
      to: '/about-us',
    },
    {
      id: 2,
      name: 'How It Works',
      to: '/how-it-works',
    },
    {
      id: 3,
      name: 'Contact Us',
      to: '/contact-us',
    },
    {
      id: 4,
      name: 'FAQ',
      to: '/faq',
    },
    {
      id: 5,
      name: 'Privacy Policy',
      to: '/privacy-policy',
    },
    {
      id: 6,
      name: 'Terms & Conditions',
      to: '/terms-conditions',
    },
  ];

  const quickSearch = [
    {
      id: 1,
      name: 'Home',
      to: '/',
    },
    {
      id: 2,
      name: 'My Account',
      to: '/my-account?tabs=dashboard',
    },
    // {
    //   id: 3,
    //   name: 'Credit System',
    //   to: '/credit-system',
    // },
    {
      id: 4,
      name: 'Blog',
      to: '/blog',
    },
    {
      id: 5,
      name: 'Ad Pricings',
      to: '/ad-pricings',
    },
    // {
    //   id: 6,
    //   name: 'Create Ad',
    //   to: '/add-listing',
    // },
  ]
  
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const showNav = useSelector(state => state.filter.showNav)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)  
  const [showModalMsg, setShowModalMsg] = useState(false)  
  const [showModalNotif, setShowModalNotif] = useState(false)  
  const [showChatList, setShowChatList] = useState(false)
  const [showModalBank, setShowModalBank] = useState(false)
  const [roomList, setRoomList] = useState([])
  const [msgUser, setMsgUser] = useState(null)
  const [email, setEmail] = useState('')
  const [isLoadingBank, setLoadingBank] = useState(false)
  const [listBank, setListBank] = useState([])
  const [stateBank, setStateBank] = useState({
    accountName: '',
    businessName: '',
    iban: '',
    swiftCode: '',
    country: 1,
    phoneNumber: ''
  }) 
  
  useEffect(() => {
    if (auth !== null) {
      getApi('ROOMLIST', '/api/chat/room_list')
    }

    if (user?.check_bank_information === 1) {
      getApi('BANK', '/api/master/banks')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;    
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth?.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            if(type === 'ROOMLIST') {
              setRoomList(results)
            }else{
              setListBank(results)
              // setStateBank(prevState => {
              //   return {
              //     ...prevState,
              //     swiftCode: results[0].code
              //   }
              // })
            }
            // else if(type === 'MSGLIST'){
            //   setMsgUser({
            //     ...results[0],
            //     messages: results[0].messages.reverse()
            //   })
            //   setLoadingMsg(false)
            //   setShowRoom(true)
            // }else{
            //   setMsgUser(prevMsgUser => { return { ...prevMsgUser, is_following: results.is_following ? 1 : 0}})
            // }
            // setLoading(false)
          }, 100);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making GET request:', error);
        // setLoading(false)
      });
  }

  const handlePrivateRoute = (route) => {
    if (auth === null) {
      setShowModal(true)
    } else {
      if (route === '/add-listing') {
        dispatch(setUser({...user, adTemp: null}))
      }
      setTimeout(() => {
        navigate(route)
      }, 100);
    }
  }

  const handleSubscribe = () => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/subscribe';
    const headers = {
      'Content-Type': 'application/json',
    };
    const bodyParams = {
      email: email,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {          
          const { messages } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'Newsletter subscription successful',
            text: messages,
            confirmButtonColor: '#B38C32'
          })
          setEmail('')     
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
      }); 
  }

  const handleChangeBank = (e) => {
    const { name, value } = e.target
    setStateBank(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleSubmitBank = () => {
    setLoadingBank(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/bank-create';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth?.access_token}`,
    };
    const bodyParams = {
      account_name: stateBank?.accountName,
      business_name: stateBank?.businessName,
      iban: stateBank?.iban,
      swift_code_bic: stateBank?.swiftCode,
      country: stateBank?.country,
      // phone_number: stateBank?.phoneNumber,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          }, 500);
          let userNew = {
            ...user,
            bank: results.bank
          }
          // dispatch(clearUser())
          dispatch(setUser(userNew))
          setLoadingBank(false)
          setShowModalBank(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingBank(false)
      });
  }

  return (
    <footer className=" relative">
      <div className=" flex justify-center bg-[#366C38]">
        <div className=" flex flex-col lg:flex-row lg:space-x-8 lg:py-12 w-[1300px]">
          <div className=" lg:w-1/3 flex justify-center flex-col items-center lg:items-start">
            <div className=" py-6 flex">
              <img 
                // src={'https://paseero.com/wp-content/uploads/2023/03/paseero-high-resolution-logo-color-on-transparent-background.png'} 
                src={process.env.PUBLIC_URL+'/assets/Logo.png'} 
                className=" w-[100px] lg:w-auto lg:max-w-full max-h-[150px]"
                alt="logo"
              />
            </div>
            <div className=" text-left text-white lg:text-lg font-light tracking-wide px-14 lg:px-0 justify-center flex">
              Nigeria-based item selling, auctioning, buying, and bidding platform.
            </div>
            <div className=" lg:hidden text-white lg:text-xl font-medium tracking-wide mt-8">Soon Available</div>            
            <div className=" flex lg:hidden flex-row items-center justify-center space-x-1 mt-4 px-12 lg:px-0">
              <img  
                alt="img-googleplay"
                src={process.env.PUBLIC_URL+'/assets/GooglePlay.png'} 
                className=" w-1/2 md:w-1/4 lg:w-1/2"
              />
              <img  
                alt="img-appstore"
                src={process.env.PUBLIC_URL+'/assets/AppStore.png'} 
                className=" w-1/2 md:w-1/4 lg:w-1/2"
              />
            </div>
            <div className=" hidden lg:flex flex-row space-x-4 pt-8 pl-8 md:pl-0 justify-center lg:justify-start">
              <a 
                className=" p-2 bg-[#36B421] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://wa.me/2348144966384?text=Hello"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp className=" text-white text-sm lg:text-lg" />
              </a>
              <a 
                className=" p-2 bg-[#4C65A8] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.facebook.com/profile.php?id=61552070583039"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className=" text-white text-sm lg:text-lg" />
              </a>
              {/* <div className=" p-2 bg-[#41A1F5] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
                <FaTwitter className=" text-white text-sm lg:text-lg" />
              </div> */}
              <a 
                className=" p-2 bg-[#B92D9A] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.instagram.com/paseero_ng"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className=" text-white text-sm lg:text-lg" />
              </a>
              <a 
                className=" p-2 bg-[#C71021] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.youtube.com/channel/UC3xuzgg84eqB1EvPShG7FtA"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className=" text-white text-sm lg:text-lg" />
              </a>
            </div>
          </div>
          <div className=" lg:w-1/3 flex flex-row space-x-12 lg:space-x-16 pt-12 lg:pt-0 text-left justify-center lg:justify-start">
            <div className=" ">
              <div className=" text-white lg:text-xl font-medium tracking-wide">Useful Links</div>
              <div className=" space-y-2 mt-4">
                {usefulLinks.map(row => (
                  <div key={row.id}>
                    <Link
                      to={row.to}
                      className=" text-white text-sm lg:text-lg font-light tracking-wide cursor-pointer hover:text-gray-400"
                    >
                      {row.name}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className=" ">
              <div className=" text-white lg:text-xl font-medium tracking-wide">Quick Search</div>
              <div className=" space-y-2 mt-4">
                {quickSearch.map(row => (
                  auth === null && (row.to === '/my-account?tabs=dashboard' || row.to === '/add-listing') ? (
                    <div
                      key={row.id}
                      className=" text-white text-sm lg:text-lg font-light tracking-wide cursor-pointer hover:text-gray-400"
                      onClick={ () => setShowModal(true)}
                    >
                      {row.name}
                    </div>
                  ): (
                    <div key={row.id}>
                      <Link                        
                        to={row.to}
                        className=" text-white text-sm lg:text-lg font-light tracking-wide cursor-pointer hover:text-gray-400"
                      >
                        {row.name}
                      </Link>
                    </div>
                  )
                ))}
              </div>
            </div>
          </div>
          <div className=" lg:w-1/3 justify-center lg:justify-start lg:flex flex-col text-center lg:text-start py-12 lg:pt-0">
            <div className=" flex lg:hidden flex-row space-x-4 md:pl-0 justify-center lg:justify-start pb-4">
              <a 
                className=" p-2 bg-[#36B421] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://wa.me/2348144966384?text=Hello"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp className=" text-white text-sm lg:text-lg" />
              </a>
              <a 
                className=" p-2 bg-[#4C65A8] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.facebook.com/profile.php?id=61552070583039"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className=" text-white text-sm lg:text-lg" />
              </a>
              {/* <div className=" p-2 bg-[#41A1F5] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
                <FaTwitter className=" text-white text-sm lg:text-lg" />
              </div> */}
              <a 
                className=" p-2 bg-[#B92D9A] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.instagram.com/paseero_ng"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram className=" text-white text-sm lg:text-lg" />
              </a>
              <a 
                className=" p-2 bg-[#C71021] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
                href="https://www.youtube.com/channel/UC3xuzgg84eqB1EvPShG7FtA"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className=" text-white text-sm lg:text-lg" />
              </a>
            </div>
            <div className=" hidden lg:block text-white lg:text-xl font-medium tracking-wide">Soon Available</div>            
            <div className=" hidden lg:flex flex-row items-center justify-center space-x-1 mt-4 px-12 lg:px-0">
              <img  
                alt="img-googleplay"
                src={process.env.PUBLIC_URL+'/assets/GooglePlay.png'} 
                className=" w-1/2 md:w-1/4 lg:w-1/2"
              />
              <img  
                alt="img-appstore"
                src={process.env.PUBLIC_URL+'/assets/AppStore.png'} 
                className=" w-1/2 md:w-1/4 lg:w-1/2"
              />
            </div>
            <div className=" text-white lg:text-xl font-medium tracking-wide mt-4">Join our newsletter</div>
            <div className="  space-y-4 mt-4">
              <div className=" text-gray-300 text-xs lg:text-base font-light tracking-wide">We write rarely, but only the best content.</div>
              <div className=" items-center flex justify-center">
                <input 
                  className=" h-12 px-4 w-52 lg:w-96 bg-white placeholder-grey border border-white rounded-l focus:outline-none" 
                  placeholder="Email address here.."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button 
                  className=" h-12 w-24 font-medium bg-[#B38C32] text-white rounded-r border border-[#B38C32] hover:bg-white hover:cursor-pointer hover:text-[#B38C32] hover:border-l-[#B38C32] hover:border-white duration-300"
                  onClick={handleSubscribe}
                >
                  Join
                </button>
              </div>
              <div className=" text-gray-300 text-xs lg:text-base font-light tracking-wide">
                We'll never share your details. See our&nbsp; 
                <Link 
                  to={'/privacy-police'}
                  className=" hover:underline"
                >
                 Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center  bg-[#414758] pb-24 lg:pb-0 ">
        <div className=" flex flex-col lg:flex-row items-center  lg:justify-between py-3 w-[1300px]">
          <div className=" text-[#D9DADD] font-light text-xs lg:text-base">© 2023 Paseero All rights reserved.</div>
          {/* <div className=" flex flex-row pt-2 lg:pt-0 space-x-4">
            <a 
              className=" p-2 bg-[#36B421] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300"
              href="https://wa.me/2348144966384?text=Hello"
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsapp className=" text-white text-lg" />
            </a>
            <div className=" p-2 bg-[#4C65A8] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
              <FaFacebook className=" text-white text-lg" />
            </div>
            <div className=" p-2 bg-[#41A1F5] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
              <FaTwitter className=" text-white text-lg" />
            </div>
            <div className=" p-2 bg-[#B92D9A] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
              <FaInstagram className=" text-white text-lg" />
            </div>
            <div className=" p-2 bg-[#C71021] rounded hover:cursor-pointer hover:bg-opacity-60 duration-300">
              <FaYoutube className=" text-white text-lg" />
            </div>
          </div> */}
        </div>
      </div>

      <div className=" bg-white border border-white py-4 flex items-center  fixed bottom-0 lg:hidden w-full z-10">
        <div className=" flex w-1/2 justify-around ">
          <Link
            to={'/'}
            className=" flex justify-center flex-col items-center space-y-2"
            >
            <FaHome className=" text-xl"/>
            <div className=" text-xs lg:text-sm font-medium">Home</div>
          </Link>
          <Link 
            to={'/market-place'}
            className=" flex justify-center flex-col items-center space-y-2"
            >
            <FaSearch className=" text-xl"/>
            <div className=" text-xs lg:text-sm font-medium">Search</div>
          </Link>
        </div>
        <div className="">
          <div className=" flex justify-center ">
            <div 
              className=" bg-[#B38C32] p-4 lg:p-6 rounded-full border-4 border-white -mt-12"
              onClick={() => {
                if (auth === null) {
                  setShowModal(true)
                } else {
                  if (user?.check_bank_information === 1) {
                    setShowModalBank(true)
                  } else {
                    if (user?.membership.length > 0) {
                      handlePrivateRoute('/add-listing')
                    }else{
                      Swal.fire({
                        icon: 'warning',
                        title: 'You have no active ad plan(s)',
                        text: `Purchase an ad plan to start posting ads.`,
                        showCancelButton: true,
                        confirmButtonText: 'Get ad plan',
                        confirmButtonColor: '#B38C32',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          // navigate('/ad-pricings')
                          navigate('/my-account?tabs=membership')
                        }
                      })
                    }
                  }
                }
              }}
            >
              <FaPlus className=" lg:text-xl text-white" />
            </div>
          </div>
          <div className=" w-20 text-xs lg:text-sm font-medium mt-4 text-center">Post Ad</div>
        </div>
        <div className=" flex w-1/2 justify-around ">
          <div 
            className=" flex justify-center flex-col items-center space-y-2"
            onClick={() => handlePrivateRoute('/my-account?tabs=dashboard')}
            >
            <FaUsers className=" text-xl"/>
            <div className=" text-xs lg:text-sm font-medium">My Account</div>
          </div>
          <div 
            className=" flex justify-center flex-col items-center space-y-2"
            onClick={() => dispatch(setShowNav(!showNav))}
          >
            <FaTasks className=" text-xl"/>
            <div className=" text-xs lg:text-sm font-medium">Menu</div>
          </div>
        </div>
      </div>

      {auth !== null ? (
        <>
          <div className={` ${showChatList ? 'bottom-0' : '-bottom-64'} fixed hidden lg:block right-40 w-64 transition-all cursor-pointer duration-500 z-10`} >
            <div 
              className=" absolute -top-2 -right-2 p-1 bg-red-200 rounded-full cursor-pointer"
              onClick={() => setShowModalNotif(true)}
            >
              <FaBell className=" text-red-600 text-sm" />
            </div>
            <div 
              className=" bg-black p-3 px-6 rounded-t text-white flex text-sm items-center"
              onClick={() => setShowChatList(!showChatList)}
            >
              <FaCircle className=" text-green-600 text-xs mr-2" />
              <span>{user?.full_name}</span>
            </div>
            <div className=" bg-white border overflow-y-auto h-64">
              {roomList?.map(row => (
                <div 
                  key={row.id} 
                  className=" flex flex-row items-center space-x-2 p-2 border-b hover:bg-gray-100 duration-100 cursor-pointer"
                  onClick={() => {
                    setMsgUser(row)
                    setTimeout(() => {
                      setShowChatList(false)
                      setShowModalMsg(true)
                    }, 100);
                  }}
                >
                  <img 
                    alt="avatar-user"
                    src={row?.targeted_user_photo !== '' ? row?.targeted_user_photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                    className=" w-10 h-10 rounded-full"
                    />
                  <div>
                    <div className=" font-medium line-clamp-1 text-sm">{row?.targeted_user_full_name}</div>
                    <div className=" text-xs text-gray-500">{row?.is_online === 1 ? 'Online' : row?.last_online}</div>
                  </div>
                </div>
              ))}            
            </div>
          </div>
        </>
      ): null}
      
      {showModalBank ? (
        <ModalBankAccount
          {...stateBank}
          listBank={listBank}
          onChange={handleChangeBank}
          onShowModal={setShowModalBank}
          onSubmit={handleSubmitBank}
          isLoading={isLoadingBank}
          onHandleLink={() => {
            if (user?.membership?.length > 0) {
              dispatch(setUser({...user, adTemp: null}))
              setTimeout(() => {
                navigate('/add-listing')
              }, 100);
            }else{
              Swal.fire({
                icon: 'warning',
                title: 'You have no active ad plan(s)',
                text: `Purchase an ad plan to start posting ads.`,
                showCancelButton: true,
                confirmButtonText: 'Get ad plan',
                confirmButtonColor: '#B38C32',
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate('/ad-pricings')
                  navigate('/my-account?tabs=membership')
                }
              })
            }
          }}
        />
      ): null}

      {showModal ? (
        <ModalLoginRegister
          type={1}
          onShowModal={() => setShowModal(false)}
        />
      ) : null} 

      {showModalNotif ? (
        <ModalNotif
          onShowModal={() => setShowModalNotif(false)}
        />
      ) : null} 

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null} 
    </footer>
  )
}

export default Footer