import React from 'react'
import { HiChevronRight, HiCheckCircle } from 'react-icons/hi'
import { LiaTimesCircleSolid } from "react-icons/lia"
 
const ModalCategory = (props) => {
  const {
    listCategory, 
    listSubCategory, 
    onCategorySelect,
    onSubCategorySelect,
    onClearSelect,
    onShowModal
  } = props
  
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[700px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <div className=" p-4 text-center lg:text-xl font-semibold border-b ">Category</div>
            <div className=" m-4 border grid grid-cols-2 ">
              <div className=" h-96 overflow-y-auto">
                {listCategory.map((row, index) => (
                  <div 
                    key={row.id} 
                    className=" flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                    onClick={() => onCategorySelect(index, !row.isActive)}
                  >
                    <div className=" text-sm lg:text-base font-medium truncate">{row.title}</div>
                    {row.isActive ? (
                      <HiCheckCircle className=" text-2xl text-[#366C38]" />
                    ):(
                      <HiChevronRight className=" text-2xl" />
                    )}
                  </div>
                ))}
              </div>
              <div className=" h-96 overflow-y-auto">
                {listSubCategory.map((row, index) => (
                  <div 
                    key={row.id} 
                    className=" flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                    onClick={() => onSubCategorySelect(index, !row.isActive, 'listSubCategory')}
                  >
                    <div className=" text-sm lg:text-base font-medium truncate">{row.title}</div>
                    {row.isActive ? <HiCheckCircle className=" text-2xl text-[#366C38]" /> : null}
                  </div>
                ))}
              </div>
              {/* <div className=" h-96 overflow-y-auto">
                {listCategory.map(row => (
                  <div 
                    key={row.id} 
                    className=" flex flex-row justify-between items-center p-2 border cursor-pointer hover:bg-gray-100"
                  >
                    <div className=" font-medium truncate">{row.title}</div>
                  </div>
                ))}
              </div> */}
            </div>
            <div className=" p-4 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={onClearSelect}
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none hover:bg-gray-50 duration-300"
              >
                Clear All
              </button>
              <button 
                onClick={() => onShowModal(false)} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                Continue
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalCategory