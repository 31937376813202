import React from 'react'
import { TbMoneybag } from 'react-icons/tb'
import { LiaTimesCircleSolid } from 'react-icons/lia'
 
const ModalBuyCredit = (props) => {
  const {
    // listTopUp,
    // onSelectCP,
    isType,
    depositAmount,
    currentPrice,
    neededPrice,
    payPrice,
    // feePrice,
    onChange,
    onBuyNow,
    onShowModalBC
  } = props
  
  return (
    <div
      onClick={() => onShowModalBC(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[700px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-3xl right-1 top-1 cursor-pointer "
              onClick={() => onShowModalBC(false)}
            />
            <div className="border-b space-y-2 p-4 justify-center flex flex-col items-center">
              <TbMoneybag className=" text-2xl lg:text-5xl text-[#B38C32] font-semibold" />
              <div className=" text-center lg:text-3xl font-semibold ">Buy Credit</div>
              <div className=" text-center text-sm lg:text-base font-light text-gray-400">Top-up your account and save money with our credit system.</div>
            </div>
            <div className=" m-4 lg:mx-8  space-y-4">
              {isType === 2 ?
                <div className=" text-sm lg:text-xl font-medium text-center">Deposit Amount Required {depositAmount}</div>
              : null}
              <div className=" flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 py-4" >               
                <div className="flex flex-row lg:flex-col items-center lg:items-start justify-between w-full lg:w-1/2">
                  <div className="text-gray-400 w-1/2 lg:w-full">You Get</div>
                  <div className=" flex items-center justify-end">
                    <div className=" p-3 text-sm lg:text-base w-12 text-center text-white font-semibold bg-[#FACD33] border border-[#FACD33] rounded-l">N</div>
                    <input 
                      type={'number'} 
                      // min={1}
                      max={999999999999}
                      className=" w-1/2 lg:w-full text-sm lg:text-base p-3 px-4 bg-gray-50 border rounded-r focus:outline-none" 
                      name="payPrice"
                      value={payPrice}
                      onChange={(e) => {
                        let val = e.target.value
                        let str = val.replace(/e/, '')                        
                        // console.log(val);
                        // if (parseInt(str) < 999999999999) {
                          onChange(str)
                        // }
                      }}
                      onClick={(e) => e.target.select()}
                    />
                  </div>
                </div>
                {isType === 2 ? (
                  <>
                    <div>
                      <div className="text-gray-400">Current Credit</div>
                      <div className=" lg:text-2xl font-semibold">₦ {currentPrice}</div>
                    </div>
                    <div>
                      <div className="text-gray-400">You Needed</div>
                      <div className=" lg:text-2xl font-semibold">₦ {neededPrice}</div>
                    </div>
                  </>
                ): null}
                {/* <div className=" flex flex-row lg:flex-col justify-between items-center w-full md:w-auto">
                  <div className="text-gray-400">Transfer Fee</div>
                  <div className=" lg:text-2xl font-semibold">₦ {parseInt(feePrice) > 0 ? parseInt(feePrice) : isNaN(parseInt(feePrice)) ? 0 : 0  }</div>
                </div>
                <div className=" flex flex-row lg:flex-col justify-between items-center w-full md:w-auto">
                  <div className="text-gray-400">You Pay</div>
                  <div className=" lg:text-2xl font-semibold">₦ {parseInt(payPrice)-parseInt(feePrice) > 0 ? parseInt(payPrice)+parseInt(feePrice) : isNaN(parseInt(payPrice)+parseInt(feePrice)) ? 0 : 0  }</div>
                </div> */}
              </div>
              {/* {listTopUp?.map(row => (
                <div 
                  key={row?.id}
                  className=" flex flex-row items-center justify-between border-b pb-4"
                >               
                  <div>
                    <div className="text-gray-400">You Pay</div>
                    <div className=" text-2xl font-semibold">{row?.price_text}</div>
                  </div>
                  <div>
                    <div className="text-gray-400">You Get</div>
                    <div className=" text-2xl font-semibold">{row?.credit_text}</div>
                  </div>
                  <div 
                    className=" p-2 px-4 rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                    onClick={() =>  onSelectCP(row)}
                  >
                    Buy Now
                  </div>
                </div>
              ))} */}              
            </div>
            <div className=" p-4 lg:px-8 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={() => onShowModalBC(false)}
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none hover:bg-gray-50 duration-300"
              >
                Close
              </button>
              <div 
                className=" p-2 px-4 text-sm lg:text-base rounded text-white bg-[#B38C32] border border-[#B38C32] hover:text-[#B38C32] hover:bg-white focus:outline-none duration-300 cursor-pointer"
                onClick={onBuyNow}
              >
                Buy Now
              </div>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalBuyCredit