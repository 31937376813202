import React from "react";
import { 
  BrowserRouter, 
  Routes, 
  Route, 
  Navigate 
} from "react-router-dom";
import {  store, persistor } from './redux/store'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import {
  // Layout, 
  Home, 
  AboutUs, 
  HowItWorks, 
  NoPage, 
  Categories,
  AdPricings,
  MarketPlace,
  ContactUs,
  Faq,
  PrivacyPolicy,
  TermsConditions,
  CreditSystem,
  Blog,
  MyAccount,
  AddListing,
  AdDetail,
  AuthorDetail,
  Callback
} from "./pages";
// import { GlobalDebug } from "./utils/useRemoveConsole";

function App() {

  // useEffect(() => {
  //   // (process.env.NODE_ENV === "production" ||
  //   //  process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false);
  // }, []);

  // console.log("I am just another dummy console log, suppose to be suppressed 🙂");

  function ProtectedRoute({ children }) {
    const auth = useSelector(state => state.auth.data)  
    if (auth === null) {
      return <Navigate to="/" replace />
    }
    return children
  }

  return (
    <Provider store={store}>    
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route 
              path="/"
              // element={<Layout />}
            >
              <Route index element={<Home />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="how-it-works" element={<HowItWorks />} />
              <Route path="categories" element={<Categories />} />
              <Route path="ad-pricings" element={<AdPricings />} />
              <Route path="market-place" element={<MarketPlace />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="faq" element={<Faq />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-conditions" element={<TermsConditions />} />
              <Route path="credit-system" element={<CreditSystem />} />
              <Route path="blog" element={<Blog />} />
              <Route path="blog/:id/:title" element={<Blog />} />
              <Route path="listing/:id/:title" element={<AdDetail />} />
              <Route path="author/:userid" element={<AuthorDetail />} />

              <Route
                path="my-account"
                element={<ProtectedRoute> <MyAccount /> </ProtectedRoute>}
              />
              <Route
                path="add-listing"
                element={<ProtectedRoute> <AddListing /> </ProtectedRoute>}
              />
              <Route
                path="add-listing/:id"
                element={<ProtectedRoute> <AddListing /> </ProtectedRoute>}
              />
              <Route
                path="callback"
                element={<ProtectedRoute> <Callback /> </ProtectedRoute>}
              />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
