import React from 'react'
// import { Outlet } from "react-router-dom"
import { Header, Footer} from "../components"

const Layout = ({ children }) => {
  return (
    <div className=" font-poppins bg-[#FAFAFB]">      
      <Header />
        {/* <Outlet /> */}
        {children}
      <Footer />
    </div>
  )
}

export default Layout