import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import axios from "axios"

import { setUser } from "../redux/authSlice"
import {ListMyAdsItem, LoadingThreeDots, NothingFoundItem} from "./"
import Pagination from "./Pagination"

const MyAdsContent = (props) => {
  const { handleStatic, handleUpgrades, handleMembership } = props
  const navigate = useNavigate()

  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)

  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [listMyAds, setListMyAds] = useState([])
  const [listFilter, setListFilter] = useState([])
  const [typeSelected, setTypeSelected] = useState('')
  const [filterSelected, setFilterSelected] = useState('1')
  const [pageMyAds, setPageMyAds] = useState(null)
  const limit = 10

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYADS') {
            setListFilter(results.option)
            setListMyAds(results.data)
            setPageMyAds(metadata)
            setLoading(false)
            setLoadingMore(false)
          }else{
            handleStatic(results)
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    document.title = 'My Ads | Paseero';
    getApi('MYADS', '/api/ad/my_ads?filter='+filterSelected+'&ads_type='+typeSelected+'&per_page='+limit+'&page='+1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, typeSelected])


  const handleStatistic = (id) => {
    getApi('ADCHART', '/api/ad/chart/'+id)
  }

  const handleDelete = (index, id) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/delete';    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      // 'Content-Type': 'multipart/form-data'
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "Confirm to delete this ad?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, accept',
      confirmButtonColor: '#B38C32',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(apiUrl, { id: id }, { headers })
          .then(response => {
            // console.log({response});
            if (response.status === 200) {              
              // const { results, messages } = response.data.response
              Swal.fire(
                'Deleted!',
                'Your ad has been deleted.',
                'success'
              ) 
              let arr = [...listMyAds]
              arr.splice(index, 1);
              setListMyAds(arr)   
              dispatch(setUser({...user, ads_count: user?.ads_count - 1}))            
            }else{
              // Do something else
            } 
          })
          .catch(error => {
            console.error('Error making POST request:', error);
            const { messages } = error.response.data.response
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          });
      }
    })
  }

  const handleSwitch = (index, id, status) => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/activate/'+id;    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .post(apiUrl, { is_active: status === 1 ? 0 : 1 }, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {              
          const { messages, title } = response.data.response
          Swal.fire(
            title,
            messages,
            'success'
          ) 
          let list = [...listMyAds]
          list[index] = {
            ...list[index] = {
              ...list[index],
              is_active: status === 1 ? 0 : 1,
            }
          }
          setListMyAds(list)          
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
      });
  }

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageMyAds?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('MYADS', '/api/ad/my_ads?filter='+filterSelected+'&ads_type='+typeSelected+'&per_page='+limit+'&page='+page)
  }

  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">My Ads</div>
      </div>

      {isLoading? (
        <div className=" flex justify-center mt-8">
          <LoadingThreeDots color={"#B38C32"} />
        </div>
      ): (
        <div className=" flex-col space-y-3 lg:space-y-0 lg:flex-row flex lg:space-x-4 items-center mt-8 w-full">          
          <div className=" flex flex-col space-y-1 w-full lg:w-auto">
            <div className=" font-medium">Filter by Ad Type</div>
            <select 
              className=" p-2 lg:w-60 bg-gray-50 border rounded focus:outline-none"
              value={typeSelected}
              onChange={(e) => setTypeSelected(e.target.value)}
            >
              <option value={''}>All</option>
              <option value={'0'}>Classified (Buy Now)</option>
              <option value={'1'}>Auction</option>                
            </select>
          </div>
          <div className=" flex flex-col space-y-1 w-full">
            <div className=" font-medium">Filter by Ad Status</div>
            <select 
              className=" p-2 w-full lg:w-60 bg-gray-50 border rounded focus:outline-none"
              value={filterSelected}
              onChange={(e) => setFilterSelected(e.target.value)}
            >
              {listFilter?.map((row, index) => (
                <option 
                  key={index} 
                  value={row.value}
                >
                  {row.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div className=" grid lg:grid-cols-2 gap-4 mt-4 lg:pr-8  overflow-y-auto">
        {!isLoading && listMyAds?.length === 0 ? (
          <NothingFoundItem />
        ): null}
        {!isLoading && listMyAds?.map((row, index) => (
          <ListMyAdsItem
            {...row}
            key={row.id}
            filterSelected={filterSelected}
            onStatistic={() => handleStatistic(row?.id)}
            onEdit={() => {
              Swal.fire({
                title: 'Edit ad?',
                text: "Note that Paseero may review your ad before it goes live.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, accept',
                confirmButtonColor: '#B38C32',
                cancelButtonText: 'No, cancel',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                  dispatch(setUser({...user, adTemp: null}))
                  setTimeout(() => {
                    navigate('/add-listing/'+row?.id)
                  }, 100);
                }
              })
              
            }}
            onUpgrades={() => handleUpgrades(row.ads_upgrades, row?.id)}
            onDelete={() => handleDelete(index, row?.id)}
            onSwitch={() => handleSwitch(index, row?.id, row.is_active)}
            onRenewal={() => handleMembership(row?.id, row?.qty)}
          />
        ))}
      </div>

      <Pagination
        isLoading={isLoadingMore}
        listCount={listMyAds?.length}
        page={pageMyAds}
        onClick={handlePagination}
      />

    </div>
  )
}

export default MyAdsContent