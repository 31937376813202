import React, { useEffect, useState } from 'react'
import axios from "axios";
import Swal from "sweetalert2"

import Layout from "./Layout"
import { 
  BalanceInvoiceContent, 
  CommentContent, 
  DashboardContent, 
  FriendsContent, 
  MembershipContent, 
  MessagesContent, 
  ModalAcceptSales, 
  ModalBankAccount, 
  ModalBuyCredit, 
  ModalBuyNow, 
  ModalCreditPurchase, 
  ModalDispute, 
  ModalMembership, 
  ModalMessage, 
  ModalPayMembership, 
  ModalPayUpgrade, 
  ModalReview, 
  ModalStatistic, 
  ModalUpgrades, 
  MyAdsContent, 
  MyOffersContent, 
  SettingsContent, 
  SidebarLayout, 
  TransactionsContent, 
  WishlistContent
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { setUser, clearUser } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

const MyAccount = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
    
  const [tabActive, setTabActive] = useState('dashboard')
  // const [isLoading, setLoading] = useState(false)
  const [isLoadingPay, setLoadingPay] = useState(false)
  const [isLoadingBank, setLoadingBank] = useState(false)
  const [isLoadingBuyNow, setLoadingBuyNow] = useState(false)
  const [isLoadingSubmit, setLoadingSubmit] = useState(false)
  const [isLoadingReview, setLoadingReview] = useState(false)
  const [isLoadingPayUpgrade, setLoadingPayUpgrade] = useState(false)
  const [isLoadMenu, setLoadMenu] = useState(true)
  const [showModalBC, setShowModalBC] = useState(false)
  const [showModalCP, setShowModalCP] = useState(false)
  const [showModalBank, setShowModalBank] = useState(false)
  const [showModalMsg, setShowModalMsg] = useState(false)
  const [showModalPay, setShowModalPay] = useState(false)
  const [showModalAccSales, setShowModalAccSales] = useState(false)
  const [showModalBuyNow, setShowModalBuyNow] = useState(false)
  const [showModalReview, setShowModalReview] = useState(false)
  const [showModalStatistic, setShowModalStatistic] = useState(false)
  const [showModalUpgrades, setShowModalUpgrades] = useState(false)
  const [showModalPayUpgrade, setShowModalPayUpgrade] = useState(false)
  const [showModalDispute, setShowModalDispute] = useState(false)
  const [showModalMembership, setShowModalMembership] = useState(false)
  const [isRenewal, setRenewal] = useState(false)
  // const [listTopUp, setListTopUp] = useState([])
  // const [selectCP, setSelectCP] = useState(null)
  const [stateBank, setStateBank] = useState({
    accountName: '',
    businessName: '',
    iban: '',
    swiftCode: '',
    country: 1,
    phoneNumber: ''
  })  
  const [membershipSelected, setMembershipSelected] = useState(null)
  const [payPrice, setPayPrice] = useState('0')
  const [feePrice, setFeePrice] = useState('0')
  const [myOffersSelected, setMyOffersSelected] = useState(null)
  const [msgUser, setMsgUser] = useState(null)
  const [stateDelivered, setStateDelivered] = useState({
    id: '',
    notes: '',
    upload: '',
  })
  const [stateReview, setStateReview] = useState({
    id: '',
    purchaseId: '',
    rating: 4,
    comment: '',
  })
  const [stateStatic, setStateStatic] = useState(null)
  const [stateUpgrades, setStateUpgrades] = useState([])
  const [idAd, setIdAd] = useState(null)
  const [stockOld, setStockOld] = useState(null)
  const [stockNew, setStockNew] = useState(1)
  const [sumTotal, setSumTotal] = useState(0)
  const [listBank, setListBank] = useState([])
  const [stateDispute, setStateDispute] = useState({
    id: '',
    reason: '',
    upload: '',
  })
  const [listMembership, setListMembership] = useState(user?.membership)
  const [membershipPlanId, setMembershipPlanId] = useState(user?.membership.length > 0 ? user?.membership[0].membership_plan : null)
  const [flagDispute, setFlagDispute] = useState(null)
  const [paymentType, setPaymentType] = useState(2) // 1: Paystack, 2: Visa, 3:Credit
  // const [bankTf, setBankTf] = useState(0)
  // const [menuBadges, setMenuBadges] = useState(null)
  const [mySalesUpdate, setMySalesUpdate] = useState({
    index: '',
    data: null,
  })

  useEffect(() => {
    window.scrollTo(0, 0)

    let urlSearchString = window.location.search;
    let params = new URLSearchParams(urlSearchString);
    setTabActive(params.get('tabs'))

    if (user?.check_bank_information === 1) {
      getApi('BANK', '/api/master/banks')
      // setShowModalBank(true)
    }

    const fetchAll = () => {
      Promise.all([
        // getApi('TOPUP', '/api/category/topup'),
        getApi('MENU', '/api/auth/menu'),
        // getApi('MEMBERSHIP', '/api/auth/membership')
      ])
      .then(() => {
        // setLoading(false);
      })
      .catch((error) => {
        console.error('Error promise data:', error);
        // setLoading(false);
      });
    }
    fetchAll();
    // getApi('MENU', '/api/auth/menu')
    // getApi('MEMBERSHIP', '/api/auth/membership')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results } = response.data.response
          // if (type === 'TOPUP') {
          //   setListTopUp(results)
          // }
          if (type === 'MENU') {
            let badges = {
              // ...user,
              bid_count: results?.bid_count,
              ads_count: results?.ads_count,
              wishlist_count: results?.wishlist_count,
              friend_count: results?.friend_count,
              credit: results?.credit,
              credit_text: results?.credit_text,
              is_following: results?.is_following,
              last_online: results?.last_online
            }

            let userNew = {
              ...user,
              ...badges,
              // membership: listMembership
            }
            dispatch(clearUser())
            dispatch(setUser(userNew))
            setLoadMenu(false)
            // setMenuBadges(badges)
          }else if(type === 'MEMBERSHIP'){
            let userNew = {
              ...user,
              membership: results.membership
            }
            dispatch(clearUser())
            dispatch(setUser(userNew))
            setListMembership(results.membership)
          }else{
            setListBank(results)
            // setStateBank(prevState => {
            //   return {
            //     ...prevState,
            //     swiftCode: results[0].code
            //   }
            // })
          }
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  // useEffect(() => {
  //   if (menuBadges !== null && listMembership?.length) {
  //     let userNew = {
  //       ...user,
  //       ...menuBadges,
  //       membership: listMembership
  //     }
  //     dispatch(setUser(userNew))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [menuBadges, listMembership])

  useEffect(() => {
    if (!isLoadMenu) {
      getApi('MEMBERSHIP', '/api/auth/membership')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMenu])    

  // const handleSelectCP = (value) => {
  //   setSelectCP(value)
  //   setTimeout(() => {
  //     setShowModalCP(true)
  //   }, 100);
  // }

  const handleBuyNow = () => {
    // if (parseInt(payPrice) < 100) {
    //   return Swal.fire({
    //     icon: 'warning',
    //     title: 'Warning',
    //     text: 'Pay Amount minimal ₦100!',
    //     confirmButtonColor: '#B38C32'
    //   })
    // }
    setShowModalCP(true)
  }

  const handleTransactionPay = () => {
    setLoadingPay(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/transaction/pay';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      // amount: selectCP?.price,

      //OLD
      // amount: parseInt(payPrice)-parseInt(feePrice),
      
      //NEW
      amount: parseInt(payPrice),
      // transfer_fee: parseInt(feePrice),
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            window.location.replace(results.authorization_url.url)
          }, 500);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingPay(false)
      });
  }

  const handleChangeBank = (e) => {
    const { name, value } = e.target
    setStateBank(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    })
  }

  const handleSubmitBank = () => {
    setLoadingBank(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/bank-create';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      account_name: stateBank?.accountName,
      business_name: stateBank?.businessName,
      iban: stateBank?.iban,
      swift_code_bic: stateBank?.swiftCode,
      country: stateBank?.country,
      // phone_number: stateBank?.phoneNumber,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { messages, results } = response.data.response
          setTimeout(() => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
          }, 500);
          let userNew = {
            ...user,
            bank: results.bank
          }
          dispatch(clearUser())
          dispatch(setUser(userNew))
          setLoadingBank(false)
          setShowModalBank(false)
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingBank(false)
      });
  }

  const handleMembershipSelected = (item) => {
    setMembershipSelected(item)
    setTimeout(() => {
      setShowModalPay(true)
    }, 100);
  } 

  const handleMembershipPay = (method) => {
    setLoadingPay(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/membership/pay';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      membership_plan: membershipSelected?.id,
      payment_method: method
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        console.log({response});
        if (response.status === 200) {
          setTimeout(() => {
            const { results } = response.data.response
            window.location.replace(results.url)
          }, 500);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response       
        if (error.response.data.code === 401) {
          Swal.fire({
            title: "Insufficient Credit",
            text: messages,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#B38C32",
            confirmButtonText: "Buy credit"
          }).then((result) => {
            if (result.isConfirmed) {
              setTabActive('orders')
              navigate('/my-account?tabs=orders')
              setShowModalPay(false)
            }
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: messages,
            confirmButtonColor: '#DC2625'
          })
        }        
        setLoadingPay(false)
      });
  }

  const handlePayNow = (item) => {
    // console.log({item});
    setMyOffersSelected(item)
    setTimeout(() => {
      setShowModalBuyNow(true)
    }, 100);
  }

  const handlePayMyOffers = (type) => {    
    setLoadingBuyNow(true)
    
    const apiUrl = process.env.REACT_APP_API_URL + '/api/transaction/buy_now';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: myOffersSelected?.id,
      payment_method: type,
      is_wna: 1, // Default value for pay now my offers
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          console.log(results);
          window.location.replace(results.authorization_url.url)
          // setLoadingBuyNow(false)
        } else {
          // Do something else
        }
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: messages,
          confirmButtonColor: '#B38C32'
        })
        setLoadingBuyNow(false)
      });
  }

  const handleDelivered = (e) => {
    const { name, value, files } = e.target
    setStateDelivered(prevState => { 
      return {
        ...prevState, 
        [name]: name === 'upload' ? { file: files[0] } : value
      }
    })
  }
  
  const handleSubmitDelivered = () => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/transaction/my_sales';    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Type': 'multipart/form-data'
    };

    const bodyForm = new FormData()   

    bodyForm.append('id', stateDelivered.id);      
    bodyForm.append('item_delivery_status', 1); // For Delivered
    bodyForm.append('seller_note', stateDelivered.notes);      
    if (stateDelivered.upload !== '') {
      bodyForm.append('delivery_picture', stateDelivered.upload.file);      
    }

    Swal.fire({
      title: 'I confirm Item Delivery?',
      text: "Confirm to notify Paseero and the buyer of the delivery.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, accept',
      confirmButtonColor: '#B38C32',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingSubmit(true)
        axios
          .post(apiUrl, bodyForm, { headers })
          .then(response => {
            console.log({response});
            if (response.status === 200) {              
              const { results } = response.data.response
              Swal.fire({
                icon: 'success',
                title: 'Item Delivery Confirmation Sent!',
                text: 'Payment will be released once the buyer confirms item receipt, and 3 days at most.',
                confirmButtonColor: '#B38C32'
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload()
                  setMySalesUpdate(prevState => {
                    return {
                      ...prevState,
                      data: results
                    }
                  })
                }
              })
              setShowModalAccSales(false)
              setLoadingSubmit(false)              
            }else{
              // Do something else
            } 
          })
          .catch(error => {
            console.error('Error making POST request:', error);
            const { messages } = error.response.data.response
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
            setLoadingSubmit(false)
          });
      }
    })
  }

  const handleReview = (e) => {
    const { name, value } = e.target
    setStateReview(prevState => { 
      return {
        ...prevState, 
        [name]: value
      }
    })
  }

  const handleSubmitReview = () => {
    setLoadingReview(true)
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/comment';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      ads_id: stateReview?.id,
      user_credit_history_id: stateReview?.purchaseId,
      rating: stateReview?.rating,
      comment: stateReview?.comment,
    };
    // console.log({bodyParams});
    // return false
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          // const { messages, results } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'Ad Successfully Reviewed!',
            text: 'Your ad review is now online.',
            confirmButtonColor: '#B38C32'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
          // setTimeout(() => {
          //   setStateReview({
          //     id: '',
          //     purchaseId: '',
          //     rating: 4,
          //     comment: ''
          //   })
          //   setLoadingReview(false)
          //   setShowModalReview(false)
          // }, 500);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: messages,
          confirmButtonColor: '#DC2625'
        })
        setLoadingReview(false)
      });
  }

  const handleStatic = (v)  => {
    setStateStatic({
      title: v.title,
      data: {
        labels: v.datess,
        datasets: [
          {
            label: 'Views',
            data: v.visits,
            borderColor: '#B38C32',
            backgroundColor: '#B38C32',
            lineTension: 0.4
          }
        ],
      }
    })
    setTimeout(() => {
      setShowModalStatistic(true)
    }, 100);
  }

  const handleUpgrades = (list, id) => {
    // console.log({v});
    setStateUpgrades(list.map(row => {
      return {
        ...row,
        isActive: false
      }
    }))
    setIdAd(id)
    setTimeout(() => {
      setShowModalUpgrades(true)
    }, 100);
  }

  const handleSelectUpgrades = (index, status) => {
    let list = [...stateUpgrades]
    list[index] = {
      ...list[index] = {
          ...list[index],
          isActive: status,
        }
      }
    setStateUpgrades(list)
  }

  useEffect(() => {
    let total = stateUpgrades.filter(f => f.isActive === true).reduce( ( sum, { price } ) => parseInt(sum) + parseInt(price) , 0)
    setSumTotal(total)
  }, [stateUpgrades])

  useEffect(() => {
    if (payPrice > 0) {
      let fee = calculateTransactionFee(payPrice)
      setFeePrice(fee)
    }    
  }, [payPrice])
  
  function calculateTransactionFee(v) {
    let tf = 0;
    v = parseInt(v);
  
    if (v < 2500) {
      tf = 0.015 * v;
      if (tf > 2000) {
        tf = 2000;
      }
    } else {
      tf = 0.015 * v + 100;
      if (tf > 2000) {
        tf = 2000;
      }
    }
  
    if (v <= 5000) {
      tf += 10;
    } else if (v >= 5001 && v <= 50000) {
      tf += 25;
    } else {
      tf += 50;
    }
  
    return parseInt(tf);
  }

  // useEffect(() => {
  //   if (showModalBuyNow) {
  //     // (qty * price) + shipping => calculate()
  //     let total = calculateTransactionFee(myOffersSelected?.qty * parseInt(myOffersSelected?.buynow_price) + parseInt(myOffersSelected?.shipping_price))
  //     setBankTf(total)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showModalBuyNow])  

  const handlePayUpgrade = (paymentMethod) => {
    if (isLoadingPayUpgrade) return false 

    let getUpgrade = stateUpgrades.filter(f => f.isActive)
    setLoadingPayUpgrade(true)
    
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/upgrade/'+idAd;
    const headers = {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };

    const bodyForm = new FormData()
    for (let i = 0; i < getUpgrade.length; i++) {
      bodyForm.append(`upgrades[${i}]`, getUpgrade[i].id);      
    }
    bodyForm.append('payment_method', paymentMethod)

    axios
      .post(apiUrl, bodyForm, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {
          const { results } = response.data.response
          if (paymentMethod === 1) {
            setTimeout(() => {
              window.location.replace(results.authorization_url.url)
            }, 500);
          }else{
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Payment upgrades succesful',
              confirmButtonColor: '#B38C32'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload()
              }
            })
          }
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        const { messages } = error.response.data.response
        if (error.response.data.code === 401) {
          Swal.fire({
            title: "Insufficient Credit",
            text: messages,
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: messages,
            confirmButtonColor: '#DC2625'
          })
        }
        setLoadingPayUpgrade(false)
      });
  } 

  const handleDispute = (e) => {
    const { name, value, files } = e.target
    setStateDispute(prevState => { 
      return {
        ...prevState, 
        [name]: name === 'upload' ? { file: files[0] } : value
      }
    })
  }

  const handleSubmitDispute = () => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/transaction/dispute';    
    const headers = {
      Authorization: `Bearer ${auth.access_token}`,
      'Content-Type': 'multipart/form-data'
    };

    const bodyForm = new FormData()   

    bodyForm.append('id', stateDispute.id);
    bodyForm.append('reason', stateDispute.reason);      
    if (stateDispute.upload !== '') {
      bodyForm.append('image', stateDispute.upload.file);      
    }

    Swal.fire({
      title: 'Dispute Purchased Item?',
      text: "Confirm to dispute item.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, accept',
      confirmButtonColor: '#B38C32',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingSubmit(true)

        axios
          .post(apiUrl, bodyForm, { headers })
          .then(response => {
            console.log({response});
            if (response.status === 200) {              
              // const { results, messages } = response.data.response
              setFlagDispute(stateDispute.id)
              Swal.fire({
                icon: 'success',
                title: 'Dispute Successfully Submitted',
                text: 'Paseero will resolve the dispute claim.',
                confirmButtonColor: '#B38C32'
              }).then((result) => {
                if (result.isConfirmed) {
                  // window.location.reload()
                  setFlagDispute(null)
                }
              })
              setShowModalDispute(false)
              setLoadingSubmit(false)              
            }else{
              // Do something else
            } 
          })
          .catch(error => {
            console.error('Error making POST request:', error);
            const { messages } = error.response.data.response
            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: messages,
              confirmButtonColor: '#B38C32'
            })
            setLoadingSubmit(false)
          });
      }
    })
  }
  
  const handleRenewal = () => {    
    const apiUrl = process.env.REACT_APP_API_URL+'/api/ad/renewal/'+idAd;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      membership_plan_id: membershipPlanId,
      stock: stockNew
    };
    Swal.fire({
      title: 'Renew Ad?',
      text: "Confirm to renew this ad.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, accept',
      confirmButtonColor: '#B38C32',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setRenewal(true)
        axios
          .post(apiUrl, bodyParams, { headers })
          .then(response => {
            // console.log({response});
            if (response.status === 200) {
              // const { results } = response.data.response
              Swal.fire({
                icon: 'success',
                title: 'Ad Successfully Renewed',
                text: 'The renewed ad is now online.',
                confirmButtonColor: '#B38C32'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload()
                }
              })
            }else{
              // Do something else
            } 
          })
          .catch(error => {
            console.error('Error making POST request:', error);
            const { messages } = error.response.data.response
            Swal.fire({
              icon: 'error',
              title: 'Insufficient Ad Plan Quota',
              text: messages,
              confirmButtonColor: '#DC2625'
            })
            setRenewal(false)
          });
      }
    })
  }

  return (
    <Layout>
      <SidebarLayout
        tabActive={tabActive}
        setTabActive={setTabActive}
        onShowModalBank={() => setShowModalBank(true)}
        listMembership={listMembership}
      >
        {tabActive === 'dashboard' ? (
            <DashboardContent
              setTabActive={setTabActive}
            />
          ) : tabActive === 'offers' ? (
            <MyOffersContent
              onPayNow={handlePayNow} 
            />
          ) : tabActive === 'listings' ? (
            <MyAdsContent 
              handleStatic={handleStatic}
              handleUpgrades={handleUpgrades}
              handleMembership={(id, stock) => {
                setIdAd(id)
                setStockOld(stock)
                setTimeout(() => {
                  setShowModalMembership(true)
                }, 100);
              }}
            />
          ) : tabActive === 'wishlist' ? (
            <WishlistContent 
              setMsgUser={setMsgUser}
              setShowModalMsg={setShowModalMsg}
            />
          ) : tabActive === 'messages' ? (
            <MessagesContent 
              setMsgUser={setMsgUser}
              setShowModalMsg={setShowModalMsg}
            />
          ) : tabActive === 'comments' ? (
            <CommentContent />
          ) : tabActive === 'friends' ? (
            <FriendsContent />
          ) : tabActive === 'membership' ? (
            <MembershipContent 
              onSelect={handleMembershipSelected}
              onShowModalPay={setShowModalPay}
            />
          ) : tabActive === 'orders' ? (
            <BalanceInvoiceContent
              onShowModalBC={() => {
                // if (user?.check_bank_information === 1) {
                //   setShowModalBank(true)
                // }else{
                  setShowModalBC(true)
                // }
              }}
              onShowModalBank={() => setShowModalBank(true)}
            />
          ) : tabActive === 'transactions' ? (
            <TransactionsContent
              handleDelivered={handleDelivered}
              handleReview={(v) => setStateReview(prevState => { 
                return {
                  ...prevState, 
                  id: v.ad.id, 
                  purchaseId: v.id,
                  rating: v.rating.rating === 0 ? 1 : v.rating.rating,
                  comment: v.rating.comment,
                }})}
              handleDispute={handleDispute}
              setShowModalAccSales={setShowModalAccSales}
              setShowModalReview={setShowModalReview}
              setShowModalDispute={setShowModalDispute}
              setMsgUser={setMsgUser}
              setShowModalMsg={setShowModalMsg}
              flagDispute={flagDispute}
              onShowModalBank={() => setShowModalBank(true)}
              mySalesUpdate={mySalesUpdate}
              setMySalesUpdate={setMySalesUpdate}
            />
          ) : <SettingsContent />
        }
      </SidebarLayout>

      <hr className=" my-12" />

      {showModalBank ? (
        <ModalBankAccount
          {...stateBank}
          listBank={listBank}
          onChange={handleChangeBank}
          onShowModal={setShowModalBank}
          onSubmit={handleSubmitBank}
          isLoading={isLoadingBank}
          onHandleLink={() => {
            if (user?.membership.length > 0) {
              dispatch(setUser({...user, adTemp: null}))
              setTimeout(() => {
                navigate('/add-listing')
              }, 100);
            }else{
              Swal.fire({
                icon: 'warning',
                title: 'You have no active ad plan(s)',
                text: `Purchase an ad plan to start posting ads.`,
                showCancelButton: true,
                confirmButtonText: 'Get ad plan',
                confirmButtonColor: '#B38C32',
              }).then((result) => {
                if (result.isConfirmed) {
                  // navigate('/ad-pricings')
                  navigate('/my-account?tabs=membership')
                }
              })
            }
          }}
        />
      ): null}

      {showModalPay ? (
        <ModalPayMembership
          selected={membershipSelected}
          onMembershipPay={handleMembershipPay}
          onShowModal={setShowModalPay}
          isLoading={isLoadingPay}
        />
      ): null}

      {showModalBuyNow ? (
        <ModalBuyNow
          selected={{
            // price_text: myOffersSelected?.actual_price_text,
            // price_text: '₦'+((myOffersSelected?.qty * (parseInt(myOffersSelected?.actual_price)-myOffersSelected?.deposit_amount)) + parseInt(myOffersSelected?.shipping_price) + (paymentType === 3 ? 0 : parseInt(myOffersSelected?.bank_transfer_fee))),
            price_text: '₦'+((myOffersSelected?.qty * (parseInt(myOffersSelected?.actual_price)-myOffersSelected?.deposit_amount)) + parseInt(myOffersSelected?.shipping_price)),
            title: myOffersSelected?.title,
            qty: myOffersSelected?.qty,
            total: myOffersSelected?.qty * (parseInt(myOffersSelected?.actual_price)-myOffersSelected?.deposit_amount),
            shipping: parseInt(myOffersSelected?.shipping_price),
            shipping_status: parseInt(myOffersSelected?.shipping_status),
            bank_tf: paymentType === 3 ? 0 : parseInt(myOffersSelected?.bank_transfer_fee),
            sub_total: (myOffersSelected?.qty * (parseInt(myOffersSelected?.actual_price)-myOffersSelected?.deposit_amount)) + parseInt(myOffersSelected?.shipping_price),
          }}
          paymentType={paymentType}
          onSelectPayment={setPaymentType}
          isLoading={isLoadingBuyNow}
          onBuyNow={handlePayMyOffers}
          onShowModal={() => setShowModalBuyNow(false)}
        />
      ): null}

      {showModalMsg ? (
        <ModalMessage
          msgUser={msgUser}
          setMsgUser={setMsgUser}
          onShowModal={() => setShowModalMsg(false)}
        />
      ) : null} 

      {showModalAccSales ? (
        <ModalAcceptSales
          {...stateDelivered}
          onChange={handleDelivered}
          onSubmit={handleSubmitDelivered}
          isLoading={isLoadingSubmit}
          onShowModal={() => setShowModalAccSales(false)}
        />
      ) : null} 

      {showModalReview ? (
        <ModalReview
          {...stateReview}
          isLoading={isLoadingReview}
          onChange={handleReview}
          onSubmit={handleSubmitReview}
          onShowModal={() => setShowModalReview(false)}
        />
      ) : null} 

      {showModalStatistic ? (
        <ModalStatistic
          {...stateStatic}
          onShowModal={() => setShowModalStatistic(false)}
        />
      ) : null} 

      {showModalUpgrades ? (
        <ModalUpgrades
          data={stateUpgrades}
          sumTotal={sumTotal}
          onSelect={handleSelectUpgrades}
          onConfirm={() => {
            let list = stateUpgrades.filter(f => f.isActive)
            // if (sumTotal > 0) {
            if (list.length) {
              setShowModalPayUpgrade(true)
            }else{
              return Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Please select upgrade!',
                confirmButtonColor: '#B38C32'
              })
            }
          }}
          onShowModal={() => setShowModalUpgrades(false)}
        />
      ) : null} 

      {showModalPayUpgrade ? (
        <ModalPayUpgrade
          selected={{title: 'Pay Upgrade', price_text: sumTotal}}
          onPay={handlePayUpgrade}
          onShowModal={setShowModalPayUpgrade}
          isLoading={isLoadingPayUpgrade}
        />
      ): null}

      {showModalDispute ? (
        <ModalDispute
          {...stateDispute}
          onChange={handleDispute}
          onSubmit={handleSubmitDispute}
          isLoading={isLoadingSubmit}
          onShowModal={() => setShowModalDispute(false)}
        />
      ) : null}

      {showModalMembership ? (
        <ModalMembership
          listMembership={listMembership}
          stockOld={stockOld}
          stockNew={stockNew}
          setStockNew={setStockNew}
          membershipPlanId={membershipPlanId}
          setMembershipPlanId={setMembershipPlanId}
          isRenewal={isRenewal}
          onModalBC={() => {
            // setShowModalMembership(false)
            // setShowModalBC(true)
            setTabActive('membership')
            navigate('/my-account?tabs=membership')
            setShowModalMembership(false)
          }}
          onConfirm={() => {
            if (membershipPlanId !== null) {
              !isRenewal && handleRenewal()
            }else{
              return Swal.fire({
                icon: 'warning',
                title: 'Select Ad Plan!',
                text: 'Select ad plan to use for renewal.',
                confirmButtonColor: '#B38C32'
              })
            }
          }}
          onShowModal={() => setShowModalMembership(false)}
        />
      ) : null} 

      {showModalBC ? (
        <ModalBuyCredit
          // listTopUp={listTopUp}
          // onSelectCP={handleSelectCP}
          isType={1} // For my account
          payPrice={payPrice}
          feePrice={feePrice}
          onChange={setPayPrice}
          onBuyNow={handleBuyNow}
          onShowModalBC={setShowModalBC}
        />
      ): null}

      {showModalCP ? (
        <ModalCreditPurchase
          // selectCP={selectCP}
          // payPrice={parseInt(payPrice)+parseInt(feePrice)}
          payPrice={parseInt(payPrice)}
          onTransactionPay={handleTransactionPay}
          onShowModalCP={setShowModalCP}
          isLoading={isLoadingPay}
        />
      ): null}
      
    </Layout>
  )
}

export default MyAccount