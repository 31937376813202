import React from 'react'
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
 
const ModalStatistic = (props) => {
  const {
    title,
    data,
    onShowModal
  } = props  

  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[300px] lg:w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <div className=" p-4 text-center border-b ">
              <div className=" lg:text-xl font-semibold">{title}</div>
              <div className=" text-sm lg:text-base text-gray-400">Page views for the last 14 days.</div>
            </div>
            <div className=" m-4 ">              
              <Line data={data} />
            </div>            
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={() => onShowModal(false)} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                Close
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalStatistic