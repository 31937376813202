import React from 'react'
import { ThreeDots } from "react-loader-spinner"

const LoadingThreeDots = ({size, color}) => {
  return (
    <ThreeDots 
      height={size || '30'}
      width={size || '30'}
      radius="9"
      color={color || 'black'}
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  )
}

export default LoadingThreeDots