import React from 'react'
import { LiaTimesCircleSolid } from "react-icons/lia"
 
const ModalLocation = (props) => {
  const {
    fulladdress,
    city,
    local,
    // longitude,
    // latitude,
    listCity,
    listLocal,
    isType,
    onChange,
    onShowModal
  } = props
  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            <div className=" p-4 text-center lg:text-xl font-semibold border-b ">My Location</div>
            <div className=" m-4 ">
              {isType === 'create' ? (
                <div className=" space-y-2">
                  <div className=" flex flex-col lg:flex-row space-y-1 lg:space-y-0 justify-between lg:items-center">
                    <label className=" font-medium">Full Address <span className=" text-red-500 hidden">*</span></label>
                    {/* {fulladdress.length < 26 ? (
                      <label className=" text-xs">
                        <span className=" font-semibold">{25 - fulladdress.length}</span> Maximum characters remaining
                      </label>
                    ): null} */}
                  </div>
                  <input 
                    className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none"
                    placeholder="eg. District, Street, House Number"
                    name="fulladdress"
                    value={fulladdress}
                    onChange={onChange}
                    maxLength={25}
                  />
                </div>
              ): null}
              <div className=" space-y-2 mt-4">
                <label className=" font-medium">Country</label>
                <select 
                  className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none" 
                  defaultValue={1}
                >
                  <option value={1} >Nigeria</option>
                </select>
              </div>
              <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">                
                <div className=" space-y-2">
                  <label className=" font-medium">State</label>
                  <select 
                    className=" w-full p-2 px-4 border rounded focus:outline-none" 
                    name="city"
                    value={city}
                    onChange={onChange}
                  >
                    <option disabled value=''>Select</option>
                    {listCity.map(row => (
                      <option 
                        key={row.id} 
                        value={row.id}
                      >
                        {row.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" space-y-2">
                  <label className=" font-medium">Local Government</label>
                  <select 
                    className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none" 
                    name="local"
                    value={local}
                    onChange={onChange}
                  >
                    <option 
                      disabled={isType === 'create' ? true : false}
                      value=''
                    >
                      {isType === 'filter' ? 'Select All' : 'Select'}
                    </option>
                    {listLocal.map(row => (
                      <option 
                        key={row.id} 
                        value={row.id}
                      >
                        {row.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className=" space-y-2">
                  <label className=" font-medium">Longitude</label>
                  <input 
                    className=" w-full p-2 px-4 border rounded focus:outline-none" 
                    name="longitude"
                    value={longitude}
                    onChange={onChange}
                  />
                </div>
                <div className=" space-y-2">
                  <label className=" font-medium">Latitude</label>
                  <input 
                    className=" w-full p-2 px-4 border rounded focus:outline-none" 
                    name="latitude"
                    value={latitude}
                    onChange={onChange}
                  />
                </div> */}
              </div>

            </div>            
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={() => onShowModal(false)} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                Continue
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalLocation