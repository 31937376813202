import axios from "axios"
import React, { useEffect, useState } from 'react'
import { 
  BiBlock,
  BiCircle, 
} from 'react-icons/bi'
import { 
  PiUserMinusBold, 
  PiUserPlusBold 
} from 'react-icons/pi'
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LoadingThreeDots from "./LoadingThreeDots"
import NothingFoundItem from "./NothingFoundItem"
import Pagination from "./Pagination"

const FriendsContent = () => {
  const auth = useSelector(state => state.auth.data)

  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [isRefresh, setRefresh] = useState(false)
  const [filterSelected, setFilterSelected] = useState('all')
  const [listMyFrined, setListMyFrined] = useState([])
  const [pageMyFriend, setPageMyFriend] = useState(null)
  const limit = 15

  useEffect(() => {
    document.title = "My Friends | Paseero"
    setLoading(true)
    getApi('MYFRIEND', '/api/auth/user/myfriend?type='+filterSelected+'&per_page='+limit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected, isRefresh])

  const getApi = (type, endPoint) => {
    const apiUrl = process.env.REACT_APP_API_URL+endPoint;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    axios
      .get(apiUrl, { headers })
      .then(response => {
        // console.log(type, response);
        if (response.status === 200) {
          const { results, metadata } = response.data.response
          if (type === 'MYFRIEND') {
            setListMyFrined(results?.data?.data)
            setPageMyFriend(metadata)
            setLoadingMore(false)
          }else{
            setRefresh(!isRefresh)
          }
          setLoading(false)
        }else{
          // Do something else
        }        
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleFollow = (id) => {
    getApi('FOLLOW', '/api/auth/user/following?user_id='+id)
  }

  const handleBlocked = (id) => {
    getApi('BLOCKED', '/api/auth/user/block?user_id='+id)
  }

  const handlePagination = (type) => {
    setLoadingMore(true)
    let page = parseInt(pageMyFriend?.selectedPage)
    if (type === 'prev') {
      page = (page-1)
    }else if (type === 'next') {
      page = (page+1)
    }else{
      page = (type)
    }
    getApi('MYFRIEND', '/api/auth/user/myfriend?type='+filterSelected+'&per_page='+limit+'&page='+page)
  }
  
  return (
    <div className=" lg:w-[70%] p-4 lg:p-12">
      <div>
        <div className=" text-xl lg:text-4xl font-semibold">My Friends</div>
      </div>
      <select 
        className=" p-2 w-full lg:w-1/3 bg-gray-50 border rounded focus:outline-none mt-4 lg:mt-8"
        name="filterSelected"
        value={filterSelected}
        onChange={(e) => setFilterSelected(e.target.value)}
      >
        <option value='all'>All</option>
        <option value='friend'>My Friends</option>
        <option value='follower'>Followers</option>
        <option value='followed'>Followed</option>
        <option value='blocked'>Blocked</option>
      </select>

      {isLoading? (
        <div className=" flex justify-center mt-8">
          <LoadingThreeDots color={"#B38C32"} />
        </div>
      ): null }

      <div className=" flex flex-col space-y-2 mt-4 lg:pr-8 ">
        {!isLoading && listMyFrined?.length === 0 ? (
          <NothingFoundItem />
        ): null}

        {!isLoading && listMyFrined?.map(row => (
          <div
            key={row.id} 
            className=" border flex flex-row rounded p-3 justify-between items-center"
          >
            <div className=" flex items-start space-x-2 lg:space-x-4 ">
              <Link 
                to={`/author/${row?.id}`}
                className=" font-medium hover:underline w-10 lg:w-auto"
              >
                <img 
                  alt={'img-avatar'}
                  src={row?.photo !== '' ? row?.photo : process.env.PUBLIC_URL+'/assets/Default.png'}
                  className=" w-8 lg:h-12 h-8 lg:w-12 rounded-full object-cover"
                />
              </Link>
              <div>                
                <Link 
                  to={`/author/${row?.id}`}
                  className=" text-sm lg:text-base font-medium hover:underline line-clamp-1"
                >
                  {row?.full_name}
                </Link>
                <div className=" font-light text-xs lg:text-sm text-gray-500">{row?.is_online === 1 ? 'Online' : row?.last_online}</div>                
              </div>
            </div>

            <div className=" flex justify-between items-center space-x-2">
              {filterSelected === 'blocked' ? (
                <div 
                className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                  onClick={() => handleBlocked(row?.id)}
                >
                  <BiCircle className=" lg:text-lg lg:mr-1"/>
                  <span className=" hidden lg:block">Unblock User</span>
                </div>
              ): (
                <div 
                  className="p-2 px-3 flex items-center rounded border border-gray-300 focus:outline-none hover:bg-gray-300 hover:text-white cursor-pointer duration-300"
                  onClick={() => handleFollow(row?.id)}
                >
                  {filterSelected === 'friend' ?
                    <PiUserMinusBold className=" lg:text-lg lg:mr-1"/> 
                    : filterSelected === 'follower' ?
                    <PiUserPlusBold className=" lg:text-lg lg:mr-1"/> 
                    : <PiUserMinusBold className=" lg:text-lg lg:mr-1"/> 
                  }
                  <span className=" hidden lg:block">
                    {filterSelected === 'friend' ?
                      'Unfrined'
                      : filterSelected === 'follower'?
                        'Follow'
                        : 'Unfollow'}
                  </span>
                </div>
              )}
             
              {filterSelected !== 'blocked' ? (
                <div 
                  className="p-2 px-3 flex items-center rounded border bg-red-600 text-white border-red-600 focus:outline-none hover:text-red-600 hover:bg-white cursor-pointer duration-300"
                  onClick={() => handleBlocked(row?.id)}
                >
                  <BiBlock className=" lg:text-lg lg:mr-1"/>
                  {/* <BiCircle className=" lg:text-lg mr-1"/> */}
                  <span className=" hidden lg:block">Block User</span>
                </div>
              ): null}
            </div>
          </div>
        ))}

      </div>

      <Pagination
        isLoading={isLoadingMore}
        listCount={listMyFrined?.length}
        page={pageMyFriend}
        onClick={handlePagination}
      />

    </div>
  )
}

export default FriendsContent