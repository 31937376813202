import React from 'react'
import AverageRating from "./AverageRating"
import LoadingThreeDots from "./LoadingThreeDots"
 
const ModalReview = (props) => {
  const {
    isLoading,
    rating,
    comment,
    onChange,
    onSubmit,
    onShowModal,
  } = props
  return (
    <div
      onClick={onShowModal}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col">
            <div className=" p-4 text-center lg:text-xl font-semibold border-b ">Your Review</div>
            <div className=" m-4 ">
              <div className=" space-y-2">
                <label className=" text-sm lg:text-base font-medium">Rating </label>
                <div className=" flex items-center space-x-2">
                  <AverageRating 
                    rate={rating} 
                    size={'text-xl lg:text-4xl'}
                    onClick={(v) => {
                      let e = { target: {
                        name: 'rating',
                        value: v
                      }}
                      onChange(e)
                    }}
                  />
                </div>
              </div>
              <div className=" space-y-2 mt-4">
                <label className=" text-sm lg:text-base font-medium">Comment </label>
                <textarea
                  className=" w-full text-xs lg:text-sm p-3 border rounded focus:outline-none"
                  placeholder="Type comment..."
                  name="comment"
                  value={comment}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>            
            <div className=" p-3 border-t flex items-center justify-end space-x-3">
              <button 
                onClick={onShowModal} 
                className=" p-2 px-4 text-sm lg:text-base rounded border focus:outline-none  hover:text-white hover:bg-gray-300 duration-300"
              >
                Cancel
              </button>
              <button 
                onClick={onSubmit} 
                className=" p-2 px-4 text-sm lg:text-base w-28 flex justify-center rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                {isLoading ? (
                  <LoadingThreeDots size={'20'} />
                ): <span>Submit</span>}
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalReview