import axios from "axios"
import React, { useState } from 'react'
import { LiaTimesCircleSolid } from "react-icons/lia"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { setUser } from "../redux/authSlice"
import LoadingThreeDots from "./LoadingThreeDots"
 
const ModalBankAccount = (props) => {
  const {
    accountName,
    // businessName,
    iban,
    swiftCode,
    // phoneNumber,
    listBank,
    onChange,
    onShowModal,
    onSubmit,
    isLoading,
    onHandleLink
  } = props

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.data)
  const user = useSelector(state => state.auth.user)
  const [isLoadingReject, setLoadingReject] = useState(false)

  const handleReject = () => {
    setLoadingReject(true)
    
    const apiUrl = process.env.REACT_APP_API_URL+'/api/auth/reject_bank_information';
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.access_token}`,
    };
    const bodyParams = {
      reject: 1,
    };
    axios
      .post(apiUrl, bodyParams, { headers })
      .then(response => {
        // console.log({response});
        if (response.status === 200) {          
          const { messages } = response.data.response
          Swal.fire({
            icon: 'success',
            title: 'You cannot receive online orders',
            text: messages,
            confirmButtonColor: '#B38C32'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(setUser({...user, check_bank_information: 0}))
              setTimeout(() => {
                setLoadingReject(false)
                onShowModal(false)
                onHandleLink()
              }, 1000);
            }
          })
          // dispatch(setUser({...user, check_bank_information: 0}))
          // setTimeout(() => {
          //   setLoadingReject(false)
          //   onShowModal(false)
          // }, 1000);
        }else{
          // Do something else
        } 
      })
      .catch(error => {
        console.error('Error making POST request:', error);
        setLoadingReject(false)
      });    
  }

  return (
    <div
      onClick={() => onShowModal(false)}
      className={` top-0 fixed bg-black/50 backdrop-opacity-95 w-full h-full z-30 transition-all flex justify-center items-center `}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        className="relative w-[600px] my-6 mx-4 lg:mx-auto"
      >
        <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" flex flex-col relative">
            <LiaTimesCircleSolid 
              className=" absolute text-2xl lg:text-4xl right-2 top-2 cursor-pointer"
              onClick={() => onShowModal(false)}
            />
            {/* <div className=" p-4 text-center lg:text-xl font-semibold border-b ">Bank Account</div> */}            
            <div className=" p-4 text-center border-b ">
              <div className="  lg:text-xl font-semibold">Bank Account</div>
              <div className=" text-sm lg:text-base text-gray-400">Paseero will use this info to send your payments for online sales, refunds, and cashouts.</div>
            </div>
            <div className=" m-4 ">
              <div className=" space-y-2">
                <label className=" font-medium">Account Name</label>
                <input 
                  className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none"
                  name="accountName"
                  value={accountName}
                  onChange={onChange}
                />
              </div>
              {/* <div className=" space-y-2 mt-4">
                <div className=" flex flex-col space-y-1 lg:space-y-0 lg:flex-row items-start lg:items-center justify-between">
                  <label className=" font-medium">Business Name</label>
                  {businessName.length < 51 ? (
                    <label className=" text-xs">
                      <span className=" font-semibold">{50 - businessName.length}</span> Minimum characters remaining
                    </label>
                  ): null}
                </div>
                <textarea 
                  className=" p-2 px-4 w-full text-sm lg:text-base rounded border focus:outline-none"  
                  placeholder="Business name" 
                  rows={5}
                  name="businessName"
                  value={businessName}
                  onChange={onChange}
                />
              </div> */}
              <div className=" grid lg:grid-cols-2 gap-4 mt-4">                
                <div className=" space-y-2">
                  <label className=" font-medium">Account Number</label>
                  <input 
                    className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none"
                    placeholder="Account number"
                    name="iban"
                    value={iban}
                    onChange={onChange}
                  />
                </div>
                <div className=" space-y-2">
                  <label className=" font-medium">Bank Name</label>
                  {/* <input 
                    className=" w-full p-2 px-4 border rounded focus:outline-none"
                    placeholder="Bank name"
                    name="swiftCode"
                    value={swiftCode}
                    onChange={onChange}
                  /> */}
                  <select 
                    className=" p-2 w-full text-sm lg:text-base rounded border focus:outline-none"
                    name="swiftCode"
                    value={swiftCode}
                    onChange={onChange}
                  >
                    <option disabled value={''}>Select</option>
                    {listBank?.map(row => (
                      <option
                        key={row.id}
                        value={row.code}
                      >
                        {row.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" space-y-2">
                  <label className=" font-medium">Country</label>
                  <select 
                    className=" w-full text-sm lg:text-base p-2 px-4 border rounded focus:outline-none" 
                    defaultValue={1}
                  >
                    <option value={1} >Nigeria</option>
                  </select>
                </div>
                {/* <div className=" space-y-2">
                  <label className=" font-medium">Phone Number</label>
                  <div className=" items-center flex">
                    <select 
                      className=" p-2 w-14 rounded rounded-r-none border focus:outline-none"
                      name="code"
                      value={code}
                      onChange={handleChange}
                    >
                      <option>🇳🇮 Nicaragua <span className=" text-gray-300">(+505)</span></option>
                      <option>🇳🇪 Niger <span className=" text-gray-300">(+227)</span></option>
                      <option>🇳🇬 Nigeria <span className=" text-gray-300">(+324)</span></option>
                      <option>🇳🇺 Niue <span className=" text-gray-300">(+683)</span></option>
                      <option>🇳🇫 Norfolk Island <span className=" text-gray-300">(+686723)</span></option>
                    </select>
                    <input 
                      className=" w-full p-2 px-4 border rounded-l-none border-l-0 rounded focus:outline-none"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={onChange}
                    />
                  </div>
                </div> */}
              </div>
             

            </div>            
            <div className=" p-3 border-t flex items-center justify-between space-x-3">
              <button
                onClick={handleReject}
                className=" p-2 px-4 w-52 flex space-x-2 justify-center rounded border focus:outline-none bg-gray-400 text-white border-gray-400 hover:bg-white hover:text-gray-400 duration-300"
              >
                {isLoadingReject ? (
                  <LoadingThreeDots size={'20'} />
                ): <span>Skip Bank Account</span>}
              </button>
              <button 
                onClick={onSubmit}
                className=" p-2 px-4 w-32 flex space-x-2 justify-center rounded border focus:outline-none bg-[#F8BA09] text-white border-[#F8BA09] hover:bg-white hover:text-[#F8BA09] duration-300"
              >
                {isLoading ? (
                  <LoadingThreeDots size={'20'} />
                ): <span>Submit</span>}
              </button>
            </div>
                             
          </div>
        </div>
      </div>
    </div> 
  )
}

export default ModalBankAccount